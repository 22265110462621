import { takeLatest, call, put, all } from 'redux-saga/effects';
import { awsActions } from '../slices/awsSlice';
import { uploadFiles } from '../../services/s3Service';

function* signedLinkSaga(action) {
  try {
    const response = yield call(uploadFiles, action.payload);
    yield put(awsActions.requestSuccess(response));
  } catch (error) {
    yield put(awsActions.requestFailure(error));
  }
}

function* awsSaga() {
  yield all([takeLatest(awsActions.signedUrlRequest.type, signedLinkSaga)]);
}

export default awsSaga;
