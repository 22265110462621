import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { refreshToken } from './userService';
import {
  clearSettings,
  clearUser,
  getAccessToken,
  isRememberUser,
  updateTokens,
} from './utilService';
const baseURL = process.env.REACT_APP_BACKEND_URL;

const authorizedAxios = axios.create();

authorizedAxios.interceptors.request.use(
  async (config) => {
    const currentDate = new Date();
    const decodedToken = jwtDecode(getAccessToken());
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      if (!isRememberUser()) {
        clearUser();
        return config;
      }
      const data = await refreshToken();
      console.log('refresh token result: ', {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      });
      updateTokens({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      });
      config.headers['access_token'] = 'Bearer ' + data.access_token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const postCall = async (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(baseURL + url, data)
      .then((data) => {
        resolve({
          ...data.data,
          ...(data.headers.access_token && {
            access_token: data.headers.access_token,
          }),
          ...(data.headers.refresh_token && {
            refresh_token: data.headers.refresh_token,
          }),
        });
      })
      .catch((err) => reject(err));
  });
};

export const getCall = async (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseURL + url)
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => reject(err));
  });
};

export const authorizedPostCall = async (url, data) => {
  return new Promise((resolve, reject) => {
    authorizedAxios
      .post(baseURL + url, data, {
        headers: {
          access_token: 'Bearer ' + getAccessToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearUser();
          clearSettings();
        }
        reject(err);
      });
  });
};

export const authorizedPutCall = async (url, data) => {
  return new Promise((resolve, reject) => {
    authorizedAxios
      .put(baseURL + url, data, {
        headers: {
          access_token: 'Bearer ' + getAccessToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearUser();
          clearSettings();
        }
        reject(err);
      });
  });
};

export const authorizedDeleteCall = async (url) => {
  return new Promise((resolve, reject) => {
    authorizedAxios
      .delete(baseURL + url, {
        headers: {
          access_token: 'Bearer ' + getAccessToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearUser();
          clearSettings();
        }
        reject(err);
      });
  });
};

export const authorizedGetCall = async (url) => {
  return new Promise((resolve, reject) => {
    authorizedAxios
      .get(baseURL + url, {
        headers: {
          access_token: 'Bearer ' + getAccessToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearUser();
          clearSettings();
        }
        reject(err);
      });
  });
};
