import React, { useState, useEffect } from 'react';
import './CreateTender.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomDatePicker from '../../common/date-picker/DatePicker';
import Dropzone from '../../common/drop-zone/DropZone';
import { tenderActions } from '../../../store/slices/tenderSlice';
import { uploadFiles } from '../../../services/s3Service';
import { getCall } from '../../../services/APIsService';
import { ROLES } from '../../../constants';
import {
  DRAFT_DONE_MODAL_MSG,
  DRAFT_DONE_MODAL_TITLE,
  DRAFT_MODAL_MSG,
  DRAFT_MODAL_TITLE,
  FARM_DISTANCE_ENUM,
  IMAGES_ASSESTS_PATH,
  PAYMENT_MODE,
  PAYMENT_OPTIONS,
  PICKUP_RADIUS_ENUM,
  TENDER_STATUSES,
} from '../../../constants';
import SimpleModal from '../../modals/simple-modal/SimpleModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ReactGoogleAutocomplete from 'react-google-autocomplete';

function CreateTender() {
  const [showModal, setModal] = useState(false);
  const [showDoneModal, setDoneModal] = useState(false);
  const tender = useSelector((state) => state.tender.tender);
  const [files, setFiles] = useState(tender?.files ? tender.files : []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [payDirect, setPayDirect] = useState(true);
  const user = useSelector((state) => state.user);

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      ...tender,
      duration: [
        tender?.duration[0] ? new Date(tender?.duration[0]) : new Date(),
        tender?.duration[1] ? new Date(tender?.duration[1]) : '',
      ],
      completion_date: tender?.completion_date
        ? new Date(tender?.completion_date)
        : '',
      transport: tender?.transport ? tender?.transport : 'Delivery',
      farm_distance: tender?.farm_distance || FARM_DISTANCE_ENUM.RANGE_0_5KM,
      pickup_radius: tender?.pickup_radius || PICKUP_RADIUS_ENUM.RANGE_0_50KM,
    },
  });

  const getTenderCategories = async () => {
    try {
      const response = await getCall('/tender-category');
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getTenderCategories();
  }, []);

  useEffect(() => {
    setSelectedOption(
      options.find((option) =>
        option._id === tender?.tender_category ? tender.tender_category : ''
      )
    );
  }, [options]);

  const onSubmit = async (data) => {
    dispatch(tenderActions.persistTender({ ...data, files }));

    navigate(`/${user.user?.role}/review-tender`);
  };

  const onSubmit1 = async (data) => {
    let newData = { ...data };
    newData.tender_category = selectedOption._id;
    dispatch(tenderActions.persistTender({ ...newData, files }));
    setStep(2);
  };

  const onSubmit2 = async (data) => {
    if (watch('transport') === 'Delivery') {
      let newData = { ...data };
      newData.pickup_radius = '';
      dispatch(tenderActions.persistTender({ ...newData, files }));
    }

    if (watch('transport') === 'Pickup') {
      let newData = { ...data };
      newData.farm_distance = '';
      dispatch(tenderActions.persistTender({ ...newData, files }));
    }
    setStep(3);
  };

  const saveDraft = async () => {
    try {
      let urls = [];
      if (files.length) {
        urls = await uploadFiles(files, 'tenders/');
      }
      let values = getValues();
      if (!values.transport) {
        delete values.transport;
      }
      dispatch(
        tenderActions.createTenderRequest({
          ...values,
          ...{ attachments: urls },
          status: TENDER_STATUSES.draft,
        })
      );
      setModal(false);
      setDoneModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (e) => {
    const selected = e.target.value;
    const selectedObject = options.find((option) => option.title === selected);
    setSelectedOption(selectedObject);
  };

  const filesChange = (files) => {
    setFiles(files);
  };

  const handleDoneDraft = (path) => {
    setDoneModal(false);
    navigate(path);
  };

  return (
    <>
      <Container fluid className='mt-5'>
        {step === 1 && (
          <img
            src='/assets/images/step-1.png'
            alt='step1'
            className='step-image'
          />
        )}
        {step === 2 && (
          <img
            src='/assets/images/step-2.png'
            alt='step2'
            className='step-image'
          />
        )}

        {step === 3 && (
          <img
            src='/assets/images/step-3.png'
            alt='step3'
            className='step-image'
          />
        )}

        <div className='form-box footer-space'>
          {step === 1 && (
            <>
              <form onSubmit={handleSubmit(onSubmit1)}>
                <Row className='mb-5'>
                  <Col sm='12'>
                    <h2>Tender Details</h2>
                    <h6 className='secondary-text-color'>
                      Please fill out the following details.
                    </h6>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col sm='12' md='6'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='mb-2 d-block'>
                        Tender Title <span className='required-color'>*</span>
                      </label>
                      <div
                        data-tooltip-content='Make up a name for your tender'
                        data-tooltip-id='title'
                      >
                        <ReactTooltip id='title' />
                        <img
                          src='/assets/images/Info.svg'
                          alt='tooltip'
                          className='xsmall-icon'
                        />
                      </div>
                    </div>
                    <input
                      placeholder='Enter your title'
                      className={`form-input w-100 ${
                        errors['title'] && 'invalid-input'
                      }`}
                      type='text'
                      {...register('title', { required: true })}
                    />
                  </Col>
                  <Col sm='12' md='6'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='mb-2 d-block'>
                        Delivery/Pickup Date{' '}
                        <span className='required-color'>*</span>
                      </label>
                      <div
                        data-tooltip-content='Provide a date that you would like the goods to be delivered by'
                        data-tooltip-id='completion_date'
                      >
                        <ReactTooltip id='completion_date' />
                        <img
                          src='/assets/images/Info.svg'
                          alt='tooltip'
                          className='xsmall-icon'
                        />
                      </div>
                    </div>
                    <CustomDatePicker
                      className={`form-input w-100 ${
                        errors['completion_date'] && 'invalid-input'
                      }`}
                      name='completion_date'
                      placeholder={'Select date'}
                      control={control}
                      setValue={setValue}
                      required={true}
                    />
                  </Col>
                </Row>

                <Row className='mb-4'>
                  {/* DropDown  */}
                  <Col sm='12' md='6'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='mb-2 d-block'>
                        Tender Category{' '}
                        <span className='required-color'>*</span>
                      </label>
                      <div
                        data-tooltip-content='Select Category of Tender you are going to post'
                        data-tooltip-id='tender_category'
                      >
                        <ReactTooltip id='tender_category' />
                        <img
                          src='/assets/images/Info.svg'
                          alt='tooltip'
                          className='xsmall-icon'
                        />
                      </div>
                    </div>
                    <select
                      className='form-input w-100 select-options'
                      value={selectedOption?.title}
                      onChange={handleSelectChange}
                      required
                    >
                      <option value=''>Select an option</option>
                      {options.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.title}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm='12' md='6'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='mb-2 d-block'>
                        Tender Duration{' '}
                        <span className='required-color'>*</span>
                      </label>
                      <div
                        data-tooltip-content='This is how long the tender will remain open for suppliers to quote on.'
                        data-tooltip-id='duration'
                      >
                        <ReactTooltip id='duration' />
                        <img
                          src='/assets/images/Info.svg'
                          alt='tooltip'
                          className='xsmall-icon'
                        />
                      </div>
                    </div>
                    <CustomDatePicker
                      className={`form-input w-100 ${
                        errors['duration'] && 'invalid-input'
                      }`}
                      name='duration'
                      placeholder={'Select date'}
                      control={control}
                      setValue={setValue}
                      range={true}
                      required={true}
                      duration={selectedOption?.duration || 1}
                    />
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col sm='12'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div>
                        <label className='mb-2 d-inline-block'>
                          Order/Product Details
                        </label>
                        <div
                          className='ms-3 d-inline-block'
                          data-tooltip-content='Any optional details you would like to include.'
                          data-tooltip-id='details'
                        >
                          <ReactTooltip id='details' />
                          <img
                            src='/assets/images/Info.svg'
                            alt='tooltip'
                            className='xsmall-icon'
                          />
                        </div>
                      </div>
                      <small className='secondary-text-color'>(Optional)</small>
                    </div>
                    <textarea
                      placeholder='Enter details'
                      className={`form-input w-100 ${
                        errors['details'] && 'invalid-input'
                      }`}
                      rows={'5'}
                      {...register('details')}
                    />
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col sm='12'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div>
                        <label className='mb-2 d-inline-block'>
                          List any preferred supplier(s){' '}
                          <span className='required-color'>*</span>
                        </label>
                      </div>
                    </div>
                    <div className='note-color'>
                      iinputs.com will contact your preferred Supplier(s) on
                      your behalf, keeping your identity anonymous
                    </div>
                    <textarea
                      placeholder='Enter preferred supplier(s)'
                      className={`form-input w-100 ${
                        errors['preferred_suppliers'] && 'invalid-input'
                      }`}
                      rows={'5'}
                      {...register('preferred_suppliers', { required: true })}
                    />
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>
                      <label className='mb-2 d-inline-block'>
                        Attach Files (Product Lists)
                      </label>
                      <div
                        className='ms-3 d-inline-block'
                        data-tooltip-content='Attach any files associated with your tender. This could include product lists for suppliers to quote on directly. Or chemical spec sheets etc.'
                        data-tooltip-id='attachments'
                      >
                        <ReactTooltip id='attachments' />
                        <img
                          src='/assets/images/Info.svg'
                          alt='tooltip'
                          className='xsmall-icon'
                        />
                      </div>
                    </div>
                    <small className='secondary-text-color'>(Optional)</small>
                  </div>
                  <Col sm='12'>
                    <Dropzone onChange={filesChange} value={files} />
                  </Col>
                </Row>
                <Row className='mb-4 d-flex justify-content-end'>
                  <button className='btn success-btn next-btn' type='submit'>
                    Next
                  </button>
                </Row>
              </form>
            </>
          )}

          {/* Step 2 */}

          {step === 2 && (
            <>
              <form onSubmit={handleSubmit(onSubmit2)}>
                <Row className='mb-5'>
                  <Col sm='12'>
                    <h2>Shipping Details</h2>
                    <h6 className='secondary-text-color'>
                      Please fill out the following details.
                    </h6>
                  </Col>
                </Row>
                {user?.user?.role !== ROLES.retailer && (
                  <Row className='mb-4'>
                    <Col sm='12'>
                      <label className='mb-2 d-block'>
                        Town closest to Farm
                        <span className='required-color'>*</span>
                      </label>
                      <Controller
                        control={control}
                        rules={{
                          required: user?.user?.role !== ROLES.retailer,
                        }}
                        name='post_code'
                        render={({ field: { onChange } }) => (
                          <ReactGoogleAutocomplete
                            placeholder='Enter Address'
                            className={`form-input w-100 ${
                              errors['post_code'] && 'invalid-input'
                            }`}
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            onPlaceSelected={(place) => {
                              onChange(place.formatted_address);
                            }}
                            defaultValue={tender?.post_code}
                            options={{
                              types: ['geocode', 'establishment'],
                              componentRestrictions: { country: 'aus' },
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                )}

                {user?.user?.role !== ROLES.retailer && (
                  <Row className='mb-4'>
                    <label className='mb-2 d-block'>
                      Transport Option <span className='required-color'>*</span>
                    </label>
                    <Col sm='12'>
                      <label>
                        <input
                          className='mx-2 primary-checkbox'
                          type='radio'
                          value='Delivery'
                          checked={watch('transport') === 'Delivery'}
                          {...(user?.user?.role === ROLES.retailer
                            ? register('transport')
                            : register('transport', { required: true }))}
                        />
                        Deliver to Farm
                      </label>

                      <label className='px-3'>
                        <input
                          className='mx-2 primary-checkbox'
                          type='radio'
                          value='Pickup'
                          checked={watch('transport') === 'Pickup'}
                          {...(user?.user?.role === ROLES.retailer
                            ? register('transport')
                            : register('transport', { required: true }))}
                        />
                        Pickup
                      </label>
                    </Col>
                  </Row>
                )}

                {user?.user?.role !== ROLES.retailer && (
                  <Row className='mb-4'>
                    <div className='d-flex'>
                      <div className='w-50 me-1'>
                        <label
                          className='mb-2 d-block'
                          style={{
                            color:
                              watch('transport') === 'Pickup'
                                ? 'grey'
                                : 'black',
                          }}
                        >
                          Farm Distance from Town Center
                          <span className='required-color'>*</span>
                        </label>

                        <select
                          className='form-select form-select '
                          aria-label='Default select example'
                          placeholder='Select Distance'
                          {...register('farm_distance')}
                          disabled={watch('transport') === 'Pickup'}
                          defaultValue={'farm_distance'}
                        >
                          <option disabled className='fw-bold'>
                            Enter Distance
                          </option>
                          {Object.values(FARM_DISTANCE_ENUM).map((distance) => {
                            return (
                              <option
                                value={distance}
                                className='optional-text'
                              >
                                {distance}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className='w-50'>
                        <label
                          className='mb-2 d-block'
                          style={{
                            color:
                              watch('transport') === 'Delivery'
                                ? 'grey'
                                : 'black',
                          }}
                        >
                          Pickup Radius from Town Centre
                          <span className='required-color'>*</span>
                        </label>
                        <select
                          className='form-select form-select'
                          aria-label='Default select example'
                          {...register('pickup_radius')}
                          disabled={watch('transport') === 'Delivery'}
                          defaultValue={PICKUP_RADIUS_ENUM.RANGE_0_50KM}
                        >
                          <option disabled className='fw-bold'>
                            Enter Radius
                          </option>
                          {Object.values(PICKUP_RADIUS_ENUM).map((distance) => {
                            return (
                              <option
                                value={distance}
                                className='optional-text'
                              >
                                {distance}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </Row>
                )}

                <Row className='mb-4'>
                  <Col sm='12'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='mb-2 d-block'>
                        {user?.user?.role !== ROLES.retailer ? 'Extra' : ''}{' '}
                        Shipping Instructions
                        {user?.user?.role === ROLES.retailer && (
                          <span className='required-color'>*</span>
                        )}
                      </label>
                      {user?.user?.role !== ROLES.retailer && (
                        <small className='secondary-text-color'>
                          (Optional)
                        </small>
                      )}
                    </div>
                    <textarea
                      placeholder='Enter details'
                      className={`form-input w-100 ${
                        errors['extra_info'] && 'invalid-input'
                      }`}
                      rows={'5'}
                      {...(user?.user?.role !== ROLES.retailer
                        ? register('extra_info')
                        : register('extra_info', { required: true }))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4 d-flex justify-content-end'>
                  <button
                    className='btn back-btn next-btn mx-2'
                    onClick={() => setStep(1)}
                  >
                    Back
                  </button>
                  <button className='btn success-btn next-btn' type='submit'>
                    Next
                  </button>
                </Row>
              </form>
            </>
          )}

          {/* step 3 */}
          {step === 3 && (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className='mb-5'>
                  <Col sm='12'>
                    <h2>Payment Details</h2>
                    <h6 className='secondary-text-color'>
                      Please fill out the following details.
                    </h6>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <label className='mb-2 d-block'>
                    Payment Option <span className='required-color'>*</span>
                  </label>
                  <Col sm='12'>
                    <label>
                      <input
                        className='mx-2 primary-checkbox'
                        type='radio'
                        value={PAYMENT_OPTIONS.DIRECT}
                        {...register('payment_option', { required: true })}
                        role='button'
                        onClick={() => setPayDirect(true)}
                      />
                      Pay Supplier Direct
                    </label>

                    {user?.user?.role !== ROLES.retailer && (
                      <label className='px-3'>
                        <input
                          className='mx-2 primary-checkbox'
                          type='radio'
                          value={PAYMENT_OPTIONS.THROUGH_AG}
                          checked={!payDirect}
                          {...register('payment_option', { required: true })}
                          role='button'
                          onClick={() => setPayDirect(false)}
                        />
                        Pay through iinputs.com (no extra cost)
                      </label>
                    )}
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <div className='payment-option mx-1'>
                    <label
                      htmlFor='cashRadio'
                      className='w-100 d-flex align-items-center justify-content-between'
                    >
                      <div className='d-flex align-items-center'>
                        <img src='/assets/icons/Cash.svg' alt='cash' />
                        <div className='fw-bold'>Cash</div>
                      </div>
                      <div>
                        <input
                          id='cashRadio'
                          className='mx-2 primary-checkbox'
                          type='radio'
                          value={PAYMENT_MODE.CASH}
                          {...register('payment_mode', { required: true })}
                        />
                      </div>
                    </label>
                  </div>

                  {payDirect && (
                    <div className='payment-option mx-1'>
                      <label
                        htmlFor='creditRadio'
                        className='w-100 d-flex align-items-center justify-content-between'
                      >
                        <div className='d-flex align-items-center'>
                          <img src='/assets/icons/Credit.svg' alt='credit' />
                          <div>
                            <div className='fw-bold'>Credit</div>
                            <div>Subject to Supplier terms and conditions</div>
                          </div>
                        </div>
                        <div>
                          <input
                            id='creditRadio'
                            className='mx-2 primary-checkbox'
                            type='radio'
                            value={PAYMENT_MODE.CREDIT}
                            {...register('payment_mode', { required: true })}
                          />
                        </div>
                      </label>
                    </div>
                  )}
                </Row>

                {payDirect && watch('payment_mode') === PAYMENT_MODE.CREDIT && (
                  <div className='mb-4 d-flex align-items-center justify-content-between'>
                    <input
                      className='mx-2 primary-checkbox align-self-start mt-1'
                      type='checkbox'
                      value=''
                      {...register('acknowledge', { required: true })}
                    />
                    <label className='acknowledge-text'>
                      I acknowledge that credit will be approved at the
                      Suppliers discretion and this tender maybe cancelled in
                      the event of an unsuccessful credit application.
                    </label>
                  </div>
                )}
                <Row className='mb-4 d-flex justify-content-end'>
                  <button
                    className='btn back-btn next-btn mx-2'
                    onClick={() => setStep(2)}
                  >
                    Back
                  </button>
                  <button
                    className='btn success-btn next-btn'
                    onClick={() => setStep(3)}
                  >
                    Review Tender
                  </button>
                </Row>
              </form>
            </>
          )}
        </div>
      </Container>
      <SimpleModal
        show={showModal}
        handleClose={() => setModal(false)}
        noClick={() => setModal(false)}
        yesClick={() => saveDraft()}
        title={DRAFT_MODAL_TITLE}
        message={DRAFT_MODAL_MSG}
        img={`${IMAGES_ASSESTS_PATH}/Draft.svg`}
        primary
      />
      <SimpleModal
        show={showDoneModal}
        handleClose={() => setDoneModal(false)}
        noClick={() =>
          handleDoneDraft(
            user?.user?.role === ROLES.farmer
              ? `/${user.user?.role}/tender-listing/Draft`
              : `/${user.user?.role}/my-tenders/Draft`
          )
        }
        yesClick={() =>
          handleDoneDraft(
            user?.user?.role === ROLES.farmer
              ? `/${user.user?.role}/tender-listing/Active`
              : `/${user.user?.role}/my-tenders/Active`
          )
        }
        title={DRAFT_DONE_MODAL_TITLE}
        message={DRAFT_DONE_MODAL_MSG}
        img={`${IMAGES_ASSESTS_PATH}/Draft.svg`}
        primary
        yesBtnText={'Go to tender listing'}
        noBtnText={'View Draft'}
      />
    </>
  );
}

export default CreateTender;
