import { createBrowserRouter } from 'react-router-dom';
import SignUp from './modules/auth/signup/SignUp';
import Login from './modules/auth/login/Login';
import ForgotPassword from './modules/auth/forgot-password/ForgotPassword';
import EmailSent from './modules/auth/email-sent/EmailSent';
import ResetPassword from './modules/auth/reset-password/ResetPassword';
import AccountSuspended from './modules/auth/account-suspended/AccountSuspended';
import LandingPage from './modules/landing-page/LandingPage';
import AboutPage from './modules/about-page/AboutPage';
import FarmerGuard from './modules/route-guards/FarmerGuard';
import TenderListingFarmer from './modules/farmer/tender-listing/TenderListing';
import SupplierGuard from './modules/route-guards/SupplierGuard';
import TenderListingSupplier from './modules/common/tender-listing/TenderListing';
import CreateTender from './modules/common/create-tender/CreateTender';
import ReviewTender from './modules/common/review-tender/ReviewTender';
import NavBar from './modules/common/navbar/Navbar';
import TenderDetail from './modules/common/tender-detail/TenderDetail';
import CreateQuote from './modules/common/quotes-creat/CreateQuote';
import ReviewQuote from './modules/common/review-quote/ReviewQuote';
import MyQuotes from './modules/common/my-quotes/MyQuotes';
import QuoteDetail from './modules/farmer/quote-detail/QuoteDetail';
import UploadInvoice from './modules/common/upload-invoice/UploadInvoice';
import BillingHistoryFarmer from './modules/farmer/billing-history/BillingHistoryFarmer';
import BillingHistorySupplier from './modules/supplier/billing-history/BillingHistorySupplier';
import BillingHistoryRetailer from './modules/retailer/billing-history/BillingHistoryRetailer';
import FundsTransfer from './modules/farmer/funds-transfer/FundsTransfer';
import QuoteInvoice from './modules/common/quote-invoice/QuoteInvoice';
import Messages from './modules/common/messages/Messages';
import Profile from './modules/common/profile/Profile';
import EditProfile from './modules/common/edit-profile/EditProfile';
import MyDisputes from './modules/common/my-disputes/MyDisputes';
import Notifications from './modules/common/notifications/Notifications';
import Settings from './modules/common/settings/Settings';
import Contact from './modules/common/contact-us/Contact';
import RetailerGuard from './modules/route-guards/RetailerGuard';

export const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        path: '/',
        element: <LandingPage />,
      },
      {
        path: '/about',
        element: <AboutPage />,
      },
      {
        path: '/signup',
        element: (
          <>
            <NavBar />
            <SignUp />
          </>
        ),
      },
      {
        path: '/login',
        element: (
          <>
            <NavBar />
            <Login />
          </>
        ),
      },
      {
        path: '/forgot-password',
        element: (
          <>
            <NavBar />
            <ForgotPassword />
          </>
        ),
      },
      {
        path: '/email-sent',
        element: (
          <>
            <NavBar />
            <EmailSent />
          </>
        ),
      },
      {
        path: '/user/reset_password/:token',
        element: (
          <>
            <NavBar />
            <ResetPassword />
          </>
        ),
      },
      {
        path: '/account-suspended',
        element: (
          <>
            <NavBar />
            <AccountSuspended />
          </>
        ),
      },
      {
        path: '/user/edit-profile/:id',
        element: (
          <>
            <NavBar />
            <EditProfile />
          </>
        ),
      },
      {
        element: (
          <>
            <FarmerGuard />
          </>
        ),
        path: '/farmer',
        children: [
          {
            path: '/farmer/tender-listing/:status',
            element: (
              <>
                <NavBar />
                <TenderListingFarmer />
              </>
            ),
          },
          {
            path: '/farmer/create-tender',
            element: (
              <>
                <NavBar />
                <CreateTender />
              </>
            ),
          },
          {
            path: '/farmer/review-tender',
            element: (
              <>
                <NavBar />
                <ReviewTender />
              </>
            ),
          },
          {
            path: '/farmer/tender-detail/:id/:tab',
            element: (
              <>
                <NavBar />
                <TenderDetail />
              </>
            ),
          },
          {
            path: '/farmer/quote-detail',
            element: (
              <>
                <NavBar />
                <QuoteDetail />
              </>
            ),
          },
          {
            path: '/farmer/quote-detail/:id',
            element: (
              <>
                <NavBar />
                <QuoteDetail />
              </>
            ),
          },
          {
            path: '/farmer/billing-history',
            element: (
              <>
                <NavBar />
                <BillingHistoryFarmer />
              </>
            ),
          },
          {
            path: '/farmer/funds-transfer',
            element: (
              <>
                <NavBar />
                <FundsTransfer />
              </>
            ),
          },
          {
            path: '/farmer/messages',
            element: (
              <>
                <NavBar />
                <Messages />
              </>
            ),
          },
          {
            path: '/farmer/profile',
            element: (
              <>
                <NavBar />
                <Profile />
              </>
            ),
          },
          {
            path: '/farmer/my-disputes',
            element: (
              <>
                <NavBar />
                <MyDisputes />
              </>
            ),
          },
          {
            path: '/farmer/contact-us',
            element: (
              <>
                <NavBar />
                <Contact />
              </>
            ),
          },
          {
            path: '/farmer/notifications',
            element: (
              <>
                <NavBar />
                <Notifications />
              </>
            ),
          },
          {
            path: '/farmer/settings',
            element: (
              <>
                <NavBar />
                <Settings />
              </>
            ),
          },
        ],
      },
      {
        element: <SupplierGuard />,
        path: '/supplier',
        children: [
          {
            path: '/supplier/tender-listing',
            element: (
              <>
                <NavBar />
                <TenderListingSupplier />
              </>
            ),
          },
          {
            path: '/supplier/tender-detail/:id/:tab',
            element: (
              <>
                <NavBar />
                <TenderDetail />
              </>
            ),
          },
          {
            path: '/supplier/create-quote/:id',
            element: (
              <>
                <NavBar />
                <CreateQuote />
              </>
            ),
          },
          {
            path: '/supplier/review-quote',
            element: (
              <>
                <NavBar />
                <ReviewQuote />
              </>
            ),
          },
          {
            path: '/supplier/my-quotes',
            element: (
              <>
                <NavBar />
                <MyQuotes />
              </>
            ),
          },
          {
            path: '/supplier/upload-invoice/:id',
            element: (
              <>
                <NavBar />
                <UploadInvoice />
              </>
            ),
          },
          {
            path: '/supplier/billing-history',
            element: (
              <>
                <NavBar />
                <BillingHistorySupplier />
              </>
            ),
          },
          {
            path: '/supplier/quote-invoice/:id',
            element: (
              <>
                <NavBar />
                <QuoteInvoice />
              </>
            ),
          },
          {
            path: '/supplier/messages',
            element: (
              <>
                <NavBar />
                <Messages />
              </>
            ),
          },
          {
            path: '/supplier/profile',
            element: (
              <>
                <NavBar />
                <Profile />
              </>
            ),
          },
          {
            path: '/supplier/my-disputes',
            element: (
              <>
                <NavBar />
                <MyDisputes />
              </>
            ),
          },
          {
            path: '/supplier/contact-us',
            element: (
              <>
                <NavBar />
                <Contact />
              </>
            ),
          },
          {
            path: '/supplier/notifications',
            element: (
              <>
                <NavBar />
                <Notifications />
              </>
            ),
          },
          {
            path: '/supplier/settings',
            element: (
              <>
                <NavBar />
                <Settings />
              </>
            ),
          },
        ],
      },
      // Updated***********************************************************************
      // Need to relocate tenders
      {
        element: <RetailerGuard />,
        path: '/retailer',
        children: [
          {
            path: '/retailer/tender-listing',
            element: (
              <>
                <NavBar />
                <TenderListingSupplier />
              </>
            ),
          },
          {
            path: '/retailer/my-tenders/:status',
            element: (
              <>
                <NavBar />
                <TenderListingFarmer />
              </>
            ),
          },
          {
            path: '/retailer/messages',
            element: (
              <>
                <NavBar />
                <Messages />
              </>
            ),
          },
          {
            path: '/retailer/notifications',
            element: (
              <>
                <NavBar />
                <Notifications />
              </>
            ),
          },
          {
            path: '/retailer/my-disputes',
            element: (
              <>
                <NavBar />
                <MyDisputes />
              </>
            ),
          },
          {
            path: '/retailer/settings',
            element: (
              <>
                <NavBar />
                <Settings />
              </>
            ),
          },
          {
            path: '/retailer/my-quotes',
            element: (
              <>
                <NavBar />
                <MyQuotes />
              </>
            ),
          },
          {
            path: '/retailer/create-tender',
            element: (
              <>
                <NavBar />
                <CreateTender />
              </>
            ),
          },
          {
            path: '/retailer/review-tender',
            element: (
              <>
                <NavBar />
                <ReviewTender />
              </>
            ),
          },
          {
            path: '/retailer/profile',
            element: (
              <>
                <NavBar />
                <Profile />
              </>
            ),
          },
          {
            path: '/retailer/tender-detail/:id/:tab',
            element: (
              <>
                <NavBar />
                <TenderDetail />
              </>
            ),
          },
          {
            path: '/retailer/contact-us',
            element: (
              <>
                <NavBar />
                <Contact />
              </>
            ),
          },
          {
            path: '/retailer/quote-detail',
            element: (
              <>
                <NavBar />
                <QuoteDetail />
              </>
            ),
          },
          {
            path: '/retailer/quote-detail/:id',
            element: (
              <>
                <NavBar />
                <QuoteDetail />
              </>
            ),
          },
          {
            path: '/retailer/create-quote/:id',
            element: (
              <>
                <NavBar />
                <CreateQuote />
              </>
            ),
          },
          {
            path: '/retailer/review-quote',
            element: (
              <>
                <NavBar />
                <ReviewQuote />
              </>
            ),
          },
          {
            path: '/retailer/upload-invoice/:id',
            element: (
              <>
                <NavBar />
                <UploadInvoice />
              </>
            ),
          },
          {
            path: '/retailer/billing-history',
            element: (
              <>
                <NavBar />
                <BillingHistoryRetailer />
              </>
            ),
          },
          {
            path: '/retailer/quote-invoice/:id',
            element: (
              <>
                <NavBar />
                <QuoteInvoice />
              </>
            ),
          },
        ],
      },
    ],
  },
]);
