import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import './Messages.css';
import { useDispatch, useSelector } from 'react-redux';
import { messagesActions } from '../../../store/slices/messagesSlice';
import SpinnerLoader from '../spinner/Spinner';
import {
  IMAGES_ASSESTS_PATH,
  TENDER_DETAIL_TABS,
  TENDER_STATUSES,
  ROLES,
  errorToast,
} from '../../../constants';
import { toast } from 'react-hot-toast';
import {
  bytesToSize,
  downloadFiles,
  generateUniqueId,
  getDayOrDate,
  getFileNameFromURL,
  getFilePreview,
  getSubString,
  getTenderID,
  getUploadedFilesPreview,
  isSameDay,
} from '../../../services/utilService';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getMyRooms,
  getRoomMessages,
  sendNewMessage,
} from '../../../services/messagesService';
import { connect } from 'socket.io-client';
import moment from 'moment';
import EmojiPicker from 'emoji-picker-react';
import useComponentVisible from '../../../hooks/useComponentVisible';
import SimpleModal from '../../modals/simple-modal/SimpleModal';
import { uploadFiles } from '../../../services/s3Service';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { tenderActions } from '../../../store/slices/tenderSlice';
import ResolveDisputeModal from '../../modals/resolve-dispute/ResolveDisputeModal';
import OneButtonModal from '../../modals/one-button-modal/OneButtonModal';
import { createtender } from '../../../services/tenderService';
import { capitalize } from 'lodash';

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

function Messages() {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const MOBILE_BREAKPOINT = 767;

  const messages = useSelector((state) => state.messages);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [resolveDisputeModal, setResolveDisputeModal] = useState(false);
  const [resolvedModal, setResolvedModal] = useState(false);

  const [rooms, setRooms] = useState();
  const [showSpinner, setSpinner] = useState(true);
  const [search, setSearch] = useState('');
  const [activeRoom, setActiveRoom] = useState();
  const [messagesList, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState([]);
  const [socket, setSocket] = useState();
  const [attachment, setAttachment] = useState();
  const [showModal, setModal] = useState(false);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const getRooms = useCallback(() => {
    getMyRooms({ search })
      .then((data) => {
        setRooms(data);
      })
      .catch((error) => console.log(error));
  }, [search]);

  useLayoutEffect(() => {
    const newSocket = connect(
      process.env.REACT_APP_BACKEND_URL.replace('/api/v1', '')
    );
    newSocket.emit('join_room', 'ALLCHATS');
    setSocket(newSocket);

    return () => {
      newSocket.off('receive_message');
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('receive_message', (data) => {
        setMessages((prev) => [...prev, ...[data]]);
        getRoomMessages(data.roomId);
      });
      socket.on('update_rooms', () => {
        getRooms();
      });
    }
  }, [socket, getRooms]);

  useEffect(() => {
    dispatch(messagesActions.myRoomsRequest());
  }, [dispatch]);

  const openChat = useCallback(
    async (room) => {
      setSpinner(true);
      setActiveRoom(room);
      const msgs = await getRoomMessages(room._id);
      setMessages(msgs);
      getRooms();
      socket.emit('join_room', room?._id);
      setSpinner(false);
    },
    [socket, getRooms]
  );

  useEffect(() => {
    if (messages.error) {
      toast.error(messages.error, errorToast);
      setSpinner(false);
    }
    if (messages.response) {
      setRooms(messages.response);
      if (location.state?.room) {
        const openedRoom = messages.response.find(
          (room) => room._id === location.state?.room
        );
        setActiveRoom(openedRoom);
        openChat(openedRoom);
      }
      setSpinner(false);
    }
    return () => {
      dispatch(messagesActions.clearErrors());
    };
  }, [
    dispatch,
    messages.error,
    messages.loading,
    messages.response,
    setSpinner,
    location.state?.room,
    openChat,
  ]);

  const getOtherUsers = (room = activeRoom) => {
    return room?.ids?.filter((id) => id?._id !== user._id);
  };

  const otherUserName = (room) => {
    const otherUser = room?.ids?.find(
      (id) => id?._id === room?.latestMessage?.sender
    );
    return (
      otherUser?.name || otherUser?.business_name || capitalize(otherUser?.role)
    );
  };

  const getRoomDiv = (room) => {
    return (
      <>
        <div
          role='button'
          onClick={() => openChat(room)}
          className={`p-3 d-flex align-items-center justify-content-between ${
            activeRoom?._id === room._id && 'active-room'
          }`}
        >
          <div className='d-flex align-items-center'>
            {getOtherUsers(room)?.map((user) => (
              <img
                key={generateUniqueId()}
                className='msg-icon border-50'
                src={
                  user?._id === room?.tenderId?.user_id
                    ? room?.tenderId?.status !== TENDER_STATUSES.active
                      ? user?.profileUrl ||
                        `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                      : `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                    : user?.profileUrl ||
                      `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                }
                alt='profile'
              />
            ))}
            <div className='ms-4'>
              <div>
                {user?._id !== room?.tenderId?.user_id
                  ? room?.tenderId?.status !== TENDER_STATUSES.active
                    ? getOtherUsers(room)
                        ?.map((user) => user?.name?.split(' ')[0])
                        .join(', ')
                    : 'ANONYMOUS'
                  : getOtherUsers(room)
                      ?.map((user) => user?.name?.split(' ')[0])
                      .join(', ')}
              </div>
              {room?.latestMessage ? (
                <div>
                  {room?.latestMessage?.sender === user._id
                    ? 'You'
                    : user?._id !== room?.tenderId?.user_id
                    ? room?.tenderId?.status !== TENDER_STATUSES.active
                      ? otherUserName(room)
                      : 'ANONYMOUS'
                    : otherUserName(room)}
                  {' : '}
                  {room?.latestMessage?.isAttachment
                    ? 'attachment'
                    : getSubString(room?.latestMessage?.content)}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {room?.unseenCount && room?.latestMessage ? (
            <small className='unseen'>{room?.unseenCount}</small>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const handleKeyDownSearch = (e) => {
    if (e.key === 'Enter') {
      getRooms();
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim()) {
      return;
    }
    const newMsg = {
      roomId: activeRoom?._id,
      sender: user._id,
      content: newMessage,
      seenBy: [user._id],
    };
    await sendNewMessage(newMsg);
    socket.emit(`send_message`, newMsg);
    getRooms();
    setMessages((prev) => [
      ...prev,
      { ...newMsg, sender: { _id: user._id, profileUrl: user?.profileUrl } },
    ]);
    setNewMessage('');
  };

  const ifSamePrevSender = (index) => {
    if (!index) {
      return false;
    }
    return (
      messagesList[index]?.sender?._id === messagesList[index - 1]?.sender?._id
    );
  };

  const ifSamePrevDate = (index) => {
    if (!index) {
      return false;
    }
    return isSameDay(
      messagesList[index]?.createdAt,
      messagesList[index - 1]?.createdAt
    );
  };

  const getFileExt = (url) => {
    const parts = url?.split('.');
    return parts[parts.length - 1];
  };

  const getTime = (date) => {
    return (
      <>
        <small className='ms-3 msg-date secondary-text-color'>
          {date && moment(date).format('hh:mm A')}
        </small>
      </>
    );
  };

  const downloadFile = async (url) => {
    setSpinner(true);
    await downloadFiles([url]);
    setSpinner(false);
  };

  const getMessages = () => {
    return (
      <>
        {messagesList.map((message, index) => (
          <div className='my-2' key={generateUniqueId()}>
            {!ifSamePrevDate(index) && (
              <div className='w-100 d-flex align-items-center justify-content-center'>
                <span className='my-2 py-2 px-3 msg-day'>
                  {getDayOrDate(message?.createdAt)}
                </span>
              </div>
            )}
            <div
              className={`w-100 d-flex align-items-center ${
                message?.sender?._id === user?._id
                  ? 'my-msg flex-row-reverse'
                  : 'justify-content-start'
              }`}
            >
              {!ifSamePrevSender(index) && (
                <img
                  className='small-icon border-50 mx-2'
                  src={
                    message?.sender?.profileUrl ||
                    `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                  }
                  alt='profile'
                />
              )}
              <div
                className={`msg-box ${
                  ifSamePrevSender(index) && 'same-person-msg'
                }`}
              >
                {message.isAttachment ? (
                  <>
                    <div className='px-3 py-2 d-flex align-items-center'>
                      <img
                        src={
                          getUploadedFilesPreview([
                            { url: message?.content?.url },
                          ])[0]?.preview
                        }
                        alt='attachment'
                        className='medium-icon me-3'
                      />
                      <div className='me-4'>
                        <div>{getFileNameFromURL(message?.content?.url)}</div>
                        <div className='text-uppercase'>
                          <span className='me-3'>
                            {getFileExt(message?.content?.url)}
                          </span>
                          <span>{bytesToSize(message?.content?.size)}</span>
                        </div>
                      </div>
                      <img
                        src='/assets/icons/supplier/Download.svg'
                        alt='download'
                        className='small-icon'
                        role='button'
                        onClick={() => downloadFile(message?.content?.url)}
                      />
                    </div>
                    <div className='w-100 text-end time-box px-2'>
                      {getTime(message?.createdAt)}
                    </div>
                  </>
                ) : (
                  <div className='px-3 py-2'>
                    {message?.content}
                    {getTime(message?.createdAt)}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const setEmoji = (emoji) => {
    setNewMessage((prev) => prev + emoji.emoji);
    setIsComponentVisible(false);
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const file = await getFilePreview(fileObj);
    setAttachment(file);
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
    setAttachment(null);
    inputRef.current.files = null;
    inputRef.current.value = null;
  };

  const handleAttachmentSend = async () => {
    setSpinner(true);
    const urls = await uploadFiles([attachment], 'attachments/');
    const newMsg = {
      roomId: activeRoom?._id,
      sender: user._id,
      content: urls[0],
      seenBy: [user._id],
      isAttachment: true,
    };
    socket.emit(`send_message`, newMsg);
    sendNewMessage(newMsg);
    setMessages((prev) => [...prev, newMsg]);
    setNewMessage('');
    setSpinner(false);
    setModal(false);
  };

  const handleDetailClick = (tender) => {
    dispatch(tenderActions.persistTender(tender));
    navigate(
      `/${user.role}/tender-detail/${tender?._id}/${TENDER_DETAIL_TABS.tenderDetail}`
    );
  };

  const checkDisputeDisabled = (tender) => {
    return !tender?.disputes?.find(
      (dispute) =>
        dispute.dispute_user === user?._id && dispute.isClosed === false
    );
  };

  const clickResolve = () => {
    setResolveDisputeModal(true);
  };

  const handleResolve = async (reason) => {
    setSpinner(true);
    //updating tender
    await createtender({
      _id: activeRoom?.tenderId,
      status: TENDER_STATUSES.inProgress,
      resolveDispute: true,
      'disputes.$[e1].reason': reason,
      'disputes.$[e1].isClosed': true,
      'disputes.$[e1].endDate': new Date(),
    });
    setSpinner(false);
    setResolveDisputeModal(false);
    setResolvedModal(true);
  };

  const handleResolved = () => {
    navigate(
      `/${user.role}/tender-detail/${activeRoom?.tenderId}/${TENDER_DETAIL_TABS.tenderDetail}`
    );
    setResolvedModal(false);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type='file'
        onChange={handleFileChange}
      />
      <Container className='mt-5'>
        {!showSpinner && !rooms && !search ? (
          <>
            <div className='d-flex align-items-center justify-content-center'>
              <img
                src={`${IMAGES_ASSESTS_PATH}/Messages-large.svg`}
                alt='Tender-Submission'
              />
            </div>
            <h2 className='text-center mt-5'>You have no messages yet</h2>
            <div className='mt-3 secondary-text-color text-center'>
              Your inbox is empty. Send a message to get started.
            </div>
          </>
        ) : (
          <>
            <Row className='messages-row'>
              {((width <= MOBILE_BREAKPOINT && !activeRoom) ||
                width > MOBILE_BREAKPOINT) && (
                <Col className='h-100 border-col' lg='3' md='4' sm='12'>
                  <div className='d-flex align-items-center header-msgs border-col-bottom'>
                    <h5 className='px-3 mb-0'>Messages</h5>
                  </div>
                  <div>
                    <div className='p-3'>
                      <input
                        className='w-100 px-2 search-message'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder='Search messages'
                        onKeyDown={handleKeyDownSearch}
                      />
                    </div>
                    <div>
                      {rooms &&
                        rooms.map((room) => (
                          <div key={generateUniqueId()}>{getRoomDiv(room)}</div>
                        ))}
                    </div>
                  </div>
                </Col>
              )}
              {((width <= MOBILE_BREAKPOINT && activeRoom) ||
                width > MOBILE_BREAKPOINT) && (
                <Col className='h-100 border-col-right' lg='9' md='8' sm='12'>
                  {activeRoom ? (
                    <div className='h-100 msg-body'>
                      <div className='d-flex align-items-center w-100 header-msgs border-col-bottom'>
                        <div className='w-100 header-msgs px-3 d-flex align-items-center justify-content-between'>
                          {width <= MOBILE_BREAKPOINT && (
                            <div>
                              <img
                                src={`${IMAGES_ASSESTS_PATH}/arrow-left.svg`}
                                alt='back'
                                className='small-icon'
                                role='button'
                                onClick={() => setActiveRoom(null)}
                              />
                            </div>
                          )}
                          <div className='d-flex align-items-center'>
                            {getOtherUsers()?.map((user) => (
                              <img
                                key={generateUniqueId()}
                                className='msg-icon border-50'
                                src={
                                  user?._id === activeRoom?.tenderId?.user_id
                                    ? activeRoom?.tenderId?.status !==
                                      TENDER_STATUSES.active
                                      ? user?.profileUrl ||
                                        `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                                      : `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                                    : user?.profileUrl ||
                                      `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                                }
                                alt='profile'
                              />
                            ))}
                            <div className='ms-4'>
                              {user?._id !== activeRoom?.tenderId?.user_id
                                ? activeRoom?.tenderId?.status !==
                                  TENDER_STATUSES.active
                                  ? getOtherUsers()
                                      ?.map((user) => user?.name?.split(' ')[0])
                                      .join(', ')
                                  : 'ANONYMOUS'
                                : getOtherUsers()
                                    ?.map((user) => user?.name?.split(' ')[0])
                                    .join(', ')}
                            </div>
                          </div>
                          {width > MOBILE_BREAKPOINT && (
                            <div>
                              <Button
                                onClick={() =>
                                  handleDetailClick(activeRoom?.tenderId)
                                }
                                className='py-2 px-3 border-green-btn'
                              >
                                Tender Detail
                              </Button>
                              <Button
                                className={`ms-2 py-2 px-3 blue-btn ${
                                  checkDisputeDisabled(activeRoom?.tenderId) &&
                                  'disabled-btn'
                                }`}
                                disabled={checkDisputeDisabled(
                                  activeRoom?.tenderId
                                )}
                                onClick={clickResolve}
                              >
                                Resolve Dispute
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='p-3 border-col-bottom'>
                        <div className='d-flex align-items-center'>
                          <h4 className='mb-0'>
                            <span className='secondary-text-color'>
                              #{getTenderID(activeRoom?.tenderId?._id)}
                            </span>
                            <span className='px-2'>{'-'}</span>
                            <span>{activeRoom?.tenderId?.title}</span>
                          </h4>
                        </div>
                        <div className='pt-3 secondary-text-color pre-wrap'>
                          {activeRoom?.tenderId?.details}
                        </div>
                      </div>
                      <div className='msgs-content'>
                        {getMessages()}
                        <AlwaysScrollToBottom />
                      </div>
                      <div className='position-relative p-3 d-flex align-items-center'>
                        <img
                          className='small-icon'
                          src={`${IMAGES_ASSESTS_PATH}/Attachment.svg`}
                          alt='Attachment'
                          onClick={() => inputRef.current?.click()}
                          role='button'
                        />
                        <img
                          className='small-icon'
                          src={`${IMAGES_ASSESTS_PATH}/Emoji.svg`}
                          alt='emoji'
                          onClick={() => {
                            setIsComponentVisible(true);
                          }}
                          role='button'
                        />
                        <div
                          ref={ref}
                          className='position-absolute emoji-picker'
                        >
                          {isComponentVisible && (
                            <EmojiPicker onEmojiClick={setEmoji} />
                          )}
                        </div>
                        <div className='position-relative w-100'>
                          <input
                            className='w-100 px-2 search-message type-message'
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder='Type a message'
                          />
                          <img
                            role='button'
                            onClick={sendMessage}
                            className='small-icon position-absolute send-btn'
                            src={`${IMAGES_ASSESTS_PATH}/Send.svg`}
                            alt='send'
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
                      <div>
                        <div className='mb-5 w-100 d-flex align-items-center justify-content-center'>
                          <img
                            alt='no-msg'
                            src={`${IMAGES_ASSESTS_PATH}/no-messages.svg`}
                          />
                        </div>
                        <h2 className='text-center'>No Preview Available</h2>
                        <div>Click on any chat to view the conversation.</div>
                      </div>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          </>
        )}
      </Container>
      <SimpleModal
        show={showModal}
        handleClose={handleCloseModal}
        noClick={handleCloseModal}
        yesClick={handleAttachmentSend}
        title={'Send Attachment'}
        message={'Are you sure you want to send this attachment?'}
        img={attachment?.preview}
        enlarge
      />

      <ResolveDisputeModal
        show={resolveDisputeModal}
        handleClose={() => setResolveDisputeModal(false)}
        cancel={() => setResolveDisputeModal(false)}
        handleResolve={handleResolve}
      />
      <OneButtonModal
        show={resolvedModal}
        handleClose={() => setResolvedModal(false)}
        btn={'View Tender'}
        title={'Dispute Resolved'}
        message={'Your Dispute has successfully been resolved.'}
        click={handleResolved}
      />
    </>
  );
}

export default Messages;
