import { generateUniqueId } from '../services/utilService';
import { Row, Col } from 'react-bootstrap';

export const passwordRegex = /^(?=.*[A-Z])(?=.*[@#$!%*?&-]).{8,}$/;

const passSpecialCharsRegex = /(?=.*[@#$!%*?&-])/;

const passCaseRegex = /(?=.*[A-Z])/;

export const checkPassChars = (password, confirmPassword) =>
  (password ? password.length >= 8 : false) &&
  (confirmPassword ? confirmPassword.length >= 8 : false);

export const checkPassSpecialChars = (password, confirmPassword) =>
  (password ? passSpecialCharsRegex.test(password) : false) &&
  (confirmPassword ? passSpecialCharsRegex.test(confirmPassword) : false);

export const checkPassCase = (password, confirmPassword) =>
  (password ? passCaseRegex.test(password) : false) &&
  (confirmPassword ? passCaseRegex.test(confirmPassword) : false);

export const ROLES = {
  farmer: 'farmer',
  supplier: 'supplier',
  retailer: 'retailer',
};

export const ROLES_ENUM = [ROLES.farmer, ROLES.supplier, ROLES.retailer];

export const updateToast = {
  className: 'updateToast',
  iconTheme: {
    primary: 'white',
    secondary: '#3a3a73',
  },
};

export const successToast = {
  className: 'successToast',
  iconTheme: {
    primary: 'white',
    secondary: '#76d89c',
  },
};

export const errorToast = {
  className: 'errorToast',
  iconTheme: {
    primary: 'white',
    secondary: 'red',
  },
};

export const SUSPEND_MSG = 'User is Suspended';

export const FEE_RATE = 0.5;

export const LOGOUT_MODAL_TITLE = 'Logout';
export const LOGOUT_MODAL_MSG = 'Are you sure you want to logout?';

export const DRAFT_MODAL_TITLE = 'Do you want to save as draft?';
export const DRAFT_MODAL_MSG = 'You can save it as draft and post it later.';

export const TENDER_MODAL_TITLE = 'Your tender is submitting...';
export const TENDER_MODAL_MSG =
  "Kindly stay with us until we're processing your request";

export const TENDER_SUBMITTED_TITLE = 'Tender Submitted';
export const TENDER_SUBMITTED_MSG =
  'Your tender has successfully been submitted';

export const QUOTE_SUBMITTED_TITLE = 'Quote Submitted';
export const QUOTE_SUBMITTED_MSG =
  'Your quote has successfully been submitted. Farmer will be notified.';

export const INVOICE_SUBMITTED_TITLE = 'Invoice Submitted';
export const INVOICE_SUBMITTED_MSG =
  'Your invoice has successfully been submitted.';

export const DRAFT_DONE_MODAL_TITLE = 'Tender Saved';
export const DRAFT_DONE_MODAL_MSG =
  'Your tender has successfully been saved as draft.';

export const FUND_TRANSFERRED_TITLE = 'Funds Transferred';
export const FUND_TRANSFERRED_MSg =
  'iinputs.com will be notified of your transfer. Once the transfer is complete, the supplier will then be notified to release the Goods';

export const INVOICE_APPROVED_TITLE = 'Invoice Approved';
export const INVOICE_APPROVED_MSG =
  'Supplier invoice has successfully been approved';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const IMAGES_ASSESTS_PATH = '/assets/images';

export const TENDER_STATUSES = {
  active: 'Active',
  pending: 'Pending',
  inProgress: 'In Progress',
  completed: 'Completed',
  closed: 'Closed',
  draft: 'Draft',
  dispute: 'Dispute',
};

export const TENDER_STATUSES_ENUM = [
  TENDER_STATUSES.active,
  TENDER_STATUSES.closed,
  TENDER_STATUSES.completed,
  TENDER_STATUSES.draft,
  TENDER_STATUSES.inProgress,
  TENDER_STATUSES.pending,
  TENDER_STATUSES.dispute,
];

export const STATUSES_TABS = [
  { title: TENDER_STATUSES.active, color: 'green' },
  { title: TENDER_STATUSES.inProgress, color: '#dd841f' },
  { title: TENDER_STATUSES.completed, color: '#418FEA' },
  { title: TENDER_STATUSES.draft, color: 'black' },
  { title: TENDER_STATUSES.closed, color: '#E56A6A' },
];

export const defaultPageMeta = {
  current_page: 1,
  total_pages: 1,
  total_count: 0,
  per_page: 30,
};

export const SUBMIT_MODAL_TYPES = {
  quote: 'quote',
  tender: 'tender',
};

export const BILLING_HITORY_TABS = {
  RetailerInvoice: "Retailer's Invoice",
  RetailerMyInvoice: 'My Invoice',
};

export const RETAILER_BILLING_HITORY_TABS = [
  BILLING_HITORY_TABS.RetailerInvoice,
  BILLING_HITORY_TABS.RetailerMyInvoice,
];

export const TENDER_DETAIL_TABS = {
  tenderDetail: 'Tender Details',
  quoteDetail: 'Quote Details',
  quotes: 'Quotes',
  hired: 'Hired',
};

export const SUPPLIER_DETAIL_TABS = [
  TENDER_DETAIL_TABS.tenderDetail,
  TENDER_DETAIL_TABS.quoteDetail,
];

export const RETAILER_DETAIL_TABS = [
  TENDER_DETAIL_TABS.tenderDetail,
  TENDER_DETAIL_TABS.quotes,
  TENDER_DETAIL_TABS.hired,
];

export const FARMER_DETAIL_TABS = [
  TENDER_DETAIL_TABS.tenderDetail,
  TENDER_DETAIL_TABS.quotes,
  TENDER_DETAIL_TABS.hired,
];

export const TABLE_COL_CLASSES =
  'h-100 w-100 d-flex justify-content-center align-items-center';

export const MY_QUOTES_TABLE_COLS = [
  { col: 1, title: '# Tender' },
  { col: 3, title: 'Tender Title' },
  { col: 2, title: 'Farmer' },
  { col: 2, title: 'Supply Date' },
  { col: 1, title: 'Amount' },
  { col: 1, title: 'Status' },
  { col: 2, title: 'Action' },
];

export const FARMER_QUOTES_TABLE_COLS = [
  {
    col: 2,
    title: 'Name',
    class: 'h-100 w-100 d-flex align-items-center ms-3',
  },
  {
    col: 2,
    title: 'Supplier Rating',
    class: 'h-100 w-100 d-flex align-items-center ms-3',
  },
  { col: 3, title: 'Date of Supply' },
  { col: 3, title: 'Total Price' },
  { col: 2, title: 'Action' },
];

export const BILLING_TABLE_COLS = [
  {
    col: 2,
    title: 'Tender Number',
  },
  { col: 2, title: 'Title' },
  { col: 2, title: 'Supply Date' },
  { col: 2, title: 'Amount' },
  { col: 2, title: 'Status' },
  {
    col: 2,
    title: 'Action',
    class: 'h-100 w-100 d-flex align-items-center justify-content-center ms-5',
  },
];

export const generateTableHeader = (cols) => {
  return (
    <Row>
      {cols.map((col) => (
        <Col key={generateUniqueId()} lg={col.col}>
          <div className={col.class || TABLE_COL_CLASSES}>{col.title}</div>
        </Col>
      ))}
    </Row>
  );
};

export const ACCEPT_QUOTE_MODAL_TITLE = 'Accept Quote?';
export const ACCEPT_QUOTE_MODAL_MSG =
  "Are you sure you want to accept quote? You won't be able to cancel it afterwards.";

export const ACCEPTED_QUOTE_MODAL_TITLE = 'Quote Accepted';
export const ACCEPTED_QUOTE_MODAL_MSG =
  'Supplier will be notified that their quote has been accepted and they can submit their tax invoice in order to receive funds.';

export const ACCEPT_INVOICE_MODAL_TITLE = 'Approve Invoice?';
export const ACCEPT_INVOICE_MODAL_MSG = `Once Invoice is approved, Your order will be locked in and payments of the deposits will be due immediately. Remainders of the funds will be due upon receiving the goods or as specified by suppliers items`;

export const ACCEPTED_INVOICE_MODAL_TITLE = 'Invoice Approved';
export const ACCEPTED_INVOICE_MODAL_MSG =
  'Supplier invoice has successfully been approved.';

export const QUOTES_STATUSES = {
  sent: 'Sent',
  accepted: 'Accepted',
  rejected: 'Rejected',
};

export const BILLING_FILTERS = {
  min: '',
  to: '',
  toDate: '',
  fromDate: '',
  awaiting_approval: false,
  approved: false,
  cancelled: false,
};

export const MARK_COMPLETE_MODAL_TITLE = 'Mark as Complete';
export const MARK_COMPLETE_MODAL_MSG =
  'Are you sure you want to mark it as complete?';

export const MARK_COMPLETE_DONE_MODAL_TITLE = 'Tender Marked as Completed';
export const MARK_COMPLETE_DONE_MODAL_MSG =
  'iinputs.com has been notified of tender completion. Funds will be transferred as soon as reasonably practical.';

export const MARK_DISPUTE_MODAL_TITLE = 'Mark as Dispute';
export const MARK_DISPUTE_MODAL_MSG =
  'Are you sure you want to mark this tender as dispute?';

export const MARK_DISPUTE_DONE_MODAL_TITLE = 'Dispute Marked';
export const MARK_DISPUTE_DONE_MODAL_MSG =
  'Your tender has been marked as dispute, user will receive a notification about this.';

export const NOTIFICATIONS_TYPES = {
  farmer_new_quote: 'FARMER_NEW_QUOTE',
  farmer_tax_invoice: 'FARMER_TAX_INVOICE',
  farmer_quote_updated: 'FARMER_QUOTE_UPDATED',
  pass_changed: 'PASS_CHANGED',
  new_tender: 'NEW_TENDER',
  supplier_quote_accepted: 'SUPPLIER_QUOTE_ACCEPTED',
  supplier_invoice_approved: 'SUPPLIER_INVOICE_APPROVED',
  mark_disputed: 'MARKED_DISPUTED',
  dispute_resolved: 'DISPUTE_RESOLVED',
  admin_mark_paid: 'ADMIN_MARK_PAID',
  farmer_mark_delivered: 'MARKED_DELIVERED',
};

export const NOTIFICATIONS_ENUM = [
  NOTIFICATIONS_TYPES.farmer_new_quote,
  NOTIFICATIONS_TYPES.farmer_quote_updated,
  NOTIFICATIONS_TYPES.farmer_tax_invoice,
  NOTIFICATIONS_TYPES.pass_changed,
  NOTIFICATIONS_TYPES.new_tender,
  NOTIFICATIONS_TYPES.supplier_quote_accepted,
  NOTIFICATIONS_TYPES.supplier_invoice_approved,
  NOTIFICATIONS_TYPES.mark_disputed,
  NOTIFICATIONS_TYPES.dispute_resolved,
  NOTIFICATIONS_TYPES.admin_mark_paid,
  NOTIFICATIONS_TYPES.farmer_mark_delivered,
];

export const FARM_DISTANCE_ENUM = {
  RANGE_0_5KM: '0-5Km',
  RANGE_5_20KM: '5-20Km',
  RANGE_20_40KM: '20-40Km',
  RANGE_40PLUS_KM: '40+Km',
};

export const PICKUP_RADIUS_ENUM = {
  RANGE_0_50KM: '0-50Km',
  RANGE_100KM: '100Km',
  RANGE_200KM: '200Km',
  RANGE_500KM: '500Km',
  STATE_WIDE: 'State wide',
  AUSTRALIA_WIDE: 'Australia wide',
};

export const PAYMENT_OPTIONS = {
  DIRECT: 'Pay Supplier Direct',
  THROUGH_AG: 'Pay through iinputs.com',
};

export const PAYMENT_MODE = {
  CASH: 'Cash',
  CREDIT: 'Credit',
};

export const TRANSPORT = {
  delivery: 'Delivery',
  pickup: 'Pickup',
};
