import { getErrorMessageFromAxios } from '../../services/utilService';

export const quoteReducer = {
  createQuoteRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  updateQuoteRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  myQuotesRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  acceptQuotesRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  persistQuote: (state, action) => {
    state.error = null;
    state.quote = action.payload;
  },
  persistClear: (state, action) => {
    state.quote = null;
  },
  getUserQuotes: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  requestSuccess: (state, action) => {
    state.loading = false;
    state.error = null;
    state.response = action.payload;
  },
  requestFailure: (state, action) => {
    state.loading = false;
    state.error = getErrorMessageFromAxios(action.payload);
    if (state?.response?.message) {
      state.response.message = null;
    }
  },
  clearErrors: (state, action) => {
    state.error = null;
    state.response = null;
    state.loading = false;
  },
  getFarmerBillsRequest: (state, action) => {
    state.error = null;
    state.loading = true;
  },
  getSupplierBillsRequest: (state, action) => {
    state.error = null;
    state.loading = true;
  },
  getQuoteInvoiceRequest: (state, action) => {
    state.error = null;
    state.loading = true;
  },
};
