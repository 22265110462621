import React, { useEffect, useState } from 'react';
import './profile.css';
import { IMAGES_ASSESTS_PATH } from '../../../constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { ROLES, errorToast } from '../../../constants';
import { userActions } from '../../../store/slices/userSlice';
import SpinnerLoader from '../spinner/Spinner';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profile, setProfile] = useState();
  const [showSpinner, setSpinner] = useState(true);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(userActions.getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setSpinner(user.loading);
    if (user.error) {
      toast.error(user.error, errorToast);
    }
    if (user.user) {
      setProfile(user.user);
      setSpinner(false);
    }
    return () => {
      dispatch(userActions.clearErrors());
    };
  }, [dispatch, user.error, user.loading, user.response, user]);

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      {profile && (
        <div className="container user-container border mt-3">
          {/* left section */}
          <div className="left-section py-3">
            <div className="text-center">
              <img
                src={
                  profile?.profileUrl ||
                  `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                }
                alt=""
                className="user-image"
              />
            </div>
            <div className="user-title text-center mt-2">{profile?.name}</div>
            <div className="user-text mt-4">Total Tenders Fulfilled</div>
            <div className="user-title">
              {profile?.role === ROLES.supplier
                ? profile?.quotesCount
                : profile?.tendersCout}
            </div>
            <div className="user-text mt-4">Rating</div>
            {profile?.rating?.totalRating ? (
              <>
                <div className="me-2">
                  <Rating
                    size={25}
                    initialValue={profile?.rating?.totalRating}
                    fillColor="green"
                    readonly
                  />
                </div>
                <div>
                  {parseInt(profile?.rating?.totalRating).toFixed(1)} of{' '}
                  {profile?.rating?.count} review(s)
                </div>
              </>
            ) : (
              <>No ratings</>
            )}
          </div>

          {/* center section */}
          <div className="center-section p-4">
            <div className="flex-view-between">
              <div className="flex-view-start">
                <div className="vertical-line"></div>
                <div className="user-heading">Profile Info</div>
              </div>

              <div>
                <button
                  className="btn edit-btn"
                  onClick={() => navigate('/user/edit-profile/1')}
                >
                  <img
                    src="/assets/images/Edit.png"
                    alt=""
                    className="edit-icon"
                  />
                  <span className="edit-profile">Edit Profile</span>
                </button>
              </div>
            </div>

            <div className="flex-view-start mt-4">
              <div className="user-text">Email :</div>
              <div className="user-title">{profile?.email}</div>
            </div>

            <div className="flex-view-start mt-4">
              <div className="user-text">Phone Number :</div>
              <div className="user-title">{profile?.phone}</div>
            </div>

            {profile?.role === ROLES.supplier && (
              <>
                <div className="flex-view-start mt-4">
                  <div className="user-text">ABN :</div>
                  <div className="user-title">{profile?.ABN || 'N/A'}</div>
                </div>
                <div className="flex-view-start mt-4">
                  <div className="user-text">Delivery Address :</div>
                  <div className="user-title">{profile?.address}</div>
                </div>
                <div className="flex-view-start mt-4">
                  <div className="user-text">Business Name :</div>
                  <div className="user-title">{profile?.business_name}</div>
                </div>
                <div className="flex-view-start mt-4">
                  <div className="user-text">Billing Address :</div>
                  <div className="user-title">{profile?.billing_address || 'N/A'}</div>
                </div>
              </>
            )}
          </div>

          {/* right section */}
          <div className="right-part p-4">
            <div className="flex-view-between">
              <div className="flex-view-start">
                <div className="vertical-line"></div>
                <div className="user-heading">
                  {user?.user.role === ROLES.supplier
                    ? 'Bank Account Details'
                    : 'Business Details'}
                </div>
              </div>
              <div>
                <button
                  className="btn edit-btn"
                  onClick={() => navigate('/user/edit-profile/2')}
                >
                  <img
                    src="/assets/images/Edit.png"
                    alt=""
                    className="edit-icon"
                  />
                  <span className="edit-profile">Edit Profile</span>
                </button>
              </div>
            </div>

            {profile?.role === ROLES.supplier ? (
              <>
                <div className="flex-view-start mt-4">
                  <div className="user-text">Account Name :</div>
                  <div className="user-title">
                    {profile?.bank_account?.account_name || 'N/A'}
                  </div>
                </div>
                <div className="flex-view-start mt-4">
                  <div className="user-text">BSB Number :</div>
                  <div className="user-title">
                    {profile?.bank_account?.bsb_number || 'N/A'}
                  </div>
                </div>
                <div className="flex-view-start mt-4">
                  <div className="user-text">Account Number :</div>
                  <div className="user-title">
                    {profile?.bank_account?.account_number || 'N/A'}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex-view-start mt-4">
                  <div className="user-text">ABN Number :</div>
                  <div className="user-title">{profile?.ABN || 'N/A'}</div>
                </div>
                <div className="flex-view-start mt-4">
                  <div className="user-text">Delivery Address :</div>
                  <div className="user-title">{profile?.address || 'N/A'}</div>
                </div>
                <div className="flex-view-start mt-4">
                  <div className="user-text">Business Name :</div>
                  <div className="user-title">
                    {profile?.business_name || 'N/A'}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
