import { takeLatest, call, put, all } from 'redux-saga/effects';
import { getMyRooms } from '../../services/messagesService';
import { messagesActions } from '../slices/messagesSlice';

function* myMessagesSaga(action) {
  try {
    const response = yield call(getMyRooms, action.payload);
    yield put(messagesActions.requestSuccess(response));
  } catch (error) {
    yield put(messagesActions.requestFailure(error));
  }
}

function* messagesSaga() {
  yield all([takeLatest(messagesActions.myRoomsRequest.type, myMessagesSaga)]);
}

export default messagesSaga;
