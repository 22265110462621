import React, { useEffect, useState } from 'react';
import './ReviewTender.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFiles } from '../../../services/s3Service';
import { tenderActions } from '../../../store/slices/tenderSlice';
import { ROLES } from '../../../constants';
import moment from 'moment';
import {
  DRAFT_MODAL_MSG,
  DRAFT_MODAL_TITLE,
  IMAGES_ASSESTS_PATH,
  SUBMIT_MODAL_TYPES,
  TENDER_STATUSES,
  errorToast,
  successToast,
} from '../../../constants';
import { toast } from 'react-hot-toast';
import SpinnerLoader from '../../common/spinner/Spinner';
import * as _ from 'lodash';
import SimpleModal from '../../modals/simple-modal/SimpleModal';
import CreatedModal from '../../modals/created-modal/CreatedModal';
import OneButtonModal from '../../modals/one-button-modal/OneButtonModal';
import { attachmentsView } from '../../../services/utilService';

function ReviewTender() {
  const [showSpinner, setSpinner] = useState(false);
  const [showModal, setModal] = useState(false);
  const [tenderModal, setTenderModal] = useState(false);
  const [showIncompleteModal, setIncompleteModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.user);
  const tenderState = useSelector((state) => state.tender);
  const tender = useSelector((state) => state.tender?.tender);

  const saveTender = async (isDraft = false) => {
    if (!user?.ABN || !user?.phone) {
      let emptyFields;
      if (!user?.ABN && !user?.phone) {
        emptyFields = 'ABN and contact number';
      } else if (!user?.ABN) {
        emptyFields = 'ABN';
      } else {
        emptyFields = 'contact number';
      }
      setErrorMsg(
        `You need to provide ${emptyFields} before submitting a Tender.`
      );

      let newTender = _.cloneDeep(tender);
      setSpinner(true);
      if (tender?.files.length) {
        newTender.attachments = await uploadFiles(tender.files, 'tenders/');
      }
      delete newTender.files;
      dispatch(
        tenderActions.createTenderRequest({
          ...newTender,
          status: TENDER_STATUSES.draft,
        })
      );
      toast.success('Draft Created', successToast);
      setIncompleteModal(true);
      return;
    }
    try {
      let newTender = _.cloneDeep(tender);
      setSpinner(true);
      if (tender?.files.length) {
        newTender.attachments = await uploadFiles(tender.files, 'tenders/');
      }
      delete newTender.files;
      dispatch(
        tenderActions.createTenderRequest({
          ...newTender,
          status: isDraft ? TENDER_STATUSES.draft : TENDER_STATUSES.active,
          draftCompleted: !isDraft,
        })
      );
      if (isDraft) {
        setModal(false);
        setTenderModal(false);
        toast.success('Draft Created', successToast);
        navigate(
          user?.role === ROLES.farmer
            ? `/${user?.role}/tender-listing/Draft`
            : `/${user?.role}/my-tenders/Draft`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSpinner(tenderState.loading);
    if (tenderState.error) {
      toast.error(tenderState.error, errorToast);
    }

    if (tenderState.response?.message === TENDER_STATUSES.active) {
      setTenderModal(true);
    }

    return () => {
      dispatch(tenderActions.clearErrors());
    };
  }, [
    tenderState.response?.message,
    tenderState.error,
    tenderState.loading,
    dispatch,
    navigate,
  ]);

  const handleEdit = () => {
    navigate(`/${user?.role}/create-tender`);
  };

  const detailDiv = (title, content) => {
    return (
      <Col sm='12' className='mb-4'>
        <div className='secondary-text-color mb-2'>{title}</div>
        <div className='pre-wrap'>{content}</div>
      </Col>
    );
  };

  const closeSubmitModal = () => {
    setTenderModal(false);
    navigate(
      user?.role === ROLES.farmer
        ? `/${user?.role}/tender-listing/Active`
        : `/${user?.role}/my-tenders/Active`
    );
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container fluid className='mt-4'>
        <div className='form-box footer-space'>
          <Row className='mb-4'>
            <Col sm='12'>
              <h2>Summary</h2>
              <h6 className='secondary-text-color'>
                Here's an overview of your tender before you publish it on the
                platform.
              </h6>
            </Col>
          </Row>

          {/* Tender Details */}
          <Row className='mb-4 review-inner'>
            <div className='inner-header p-3 d-flex align-items-center justify-content-between'>
              <h6 className='text-white mb-0'>Tender Details</h6>
              <img
                src='/assets/images/edit.svg'
                role='button'
                onClick={handleEdit}
                alt='edit'
              />
            </div>
            <div className='p-3'>
              <Row>
                {detailDiv('Tender Title', tender?.title)}

                {detailDiv(
                  `Date of Suppply of Order (${tender?.transport} Date)`,
                  moment(tender?.completion_date).format('DD-MM-YYYY')
                )}

                {detailDiv(
                  'Tender Duration',
                  moment(tender?.duration[1]).diff(
                    tender?.duration[0],
                    'days'
                  ) +
                    1 +
                    ' days'
                )}

                {detailDiv(
                  'Preferred Supplier(s)',
                  tender?.preferred_suppliers || 'N/A'
                )}
                {detailDiv('Order/Product Details', tender?.details || 'N/A')}

                <Col sm='12' className='mb-4'>
                  <div className='secondary-text-color mb-2'>Attachments</div>
                  {tender?.files.length ? (
                    <div className='thumbsContainer'>
                      {attachmentsView(tender?.files)}
                    </div>
                  ) : (
                    'N/A'
                  )}
                </Col>
              </Row>
            </div>
          </Row>

          {/* Shiiping Details */}
          <Row className='mb-4 review-inner'>
            <div className='inner-header p-3 d-flex align-items-center justify-content-between'>
              <h6 className='text-white mb-0'>Shipping Details</h6>
              <img
                src='/assets/images/edit.svg'
                role='button'
                onClick={handleEdit}
                alt='edit'
              />
            </div>
            <div className='p-3'>
              <Row>
                {user?.role !== ROLES.retailer
                  ? detailDiv('Town Closest to Farm', tender?.post_code)
                  : ''}

                {user?.role !== ROLES.retailer
                  ? detailDiv(
                      'Transport Option',
                      tender?.transport === 'Delivery'
                        ? 'Delivered to Farm'
                        : tender?.transport
                    )
                  : ''}

                {user?.role !== ROLES.retailer
                  ? tender?.transport === 'Delivery' &&
                    detailDiv(
                      'Farm Distance from Town Center',
                      tender?.farm_distance || 'N/A'
                    )
                  : ''}
                {user?.role !== ROLES.retailer
                  ? tender?.transport === 'Pickup' &&
                    detailDiv(
                      'Pickup Radius from Town Centre',
                      tender?.pickup_radius || 'N/A'
                    )
                  : ''}
                {detailDiv(
                  user?.role !== ROLES.retailer
                    ? 'Extra Shipping Instructions'
                    : ' Shipping Instructions',
                  tender?.extra_info || 'N/A'
                )}
              </Row>
            </div>
          </Row>

          {/* Payment Details */}

          <Row className='mb-4 review-inner'>
            <div className='inner-header p-3 d-flex align-items-center justify-content-between'>
              <h6 className='text-white mb-0'>Payment Details</h6>
              <img
                src='/assets/images/edit.svg'
                role='button'
                onClick={handleEdit}
                alt='edit'
              />
            </div>
            <div className='p-3'>
              <Row>
                {detailDiv('Payment Option', tender?.payment_option)}

                {detailDiv('Method', tender?.payment_mode)}
              </Row>
            </div>
          </Row>
        </div>
        <div className='tender-footer'>
          <div className='px-3 d-flex align-items-center justify-content-between'>
            <div>
              <Button
                onClick={handleEdit}
                type='button'
                className='border-btn back-btn'
              >
                <div className='d-flex align-items-center'>
                  <span>
                    <img
                      className='me-2'
                      alt='add'
                      src='/assets/icons/supplier/Arrow-Back.svg'
                    />
                  </span>
                  <span>Back</span>
                </div>
              </Button>
            </div>
            <div>
              <Button
                onClick={() => setModal(true)}
                type='button'
                className='draft-btn no-bg-btn'
              >
                Save as Draft
              </Button>
              <Button
                onClick={() => saveTender(false)}
                type='button'
                className='primary-btn'
              >
                Post Tender
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <SimpleModal
        show={showModal}
        handleClose={() => setModal(false)}
        noClick={() => setModal(false)}
        yesClick={() => saveTender(true)}
        title={DRAFT_MODAL_TITLE}
        message={DRAFT_MODAL_MSG}
        img={`${IMAGES_ASSESTS_PATH}/Draft.svg`}
        primary
      />
      <CreatedModal
        show={tenderModal}
        handleClose={() => setTenderModal(false)}
        loading={tenderState.loading}
        click={closeSubmitModal}
        type={SUBMIT_MODAL_TYPES.tender}
      />
      <OneButtonModal
        show={showIncompleteModal}
        icon={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
        title={'Incomplete Profile'}
        message={errorMsg}
        btn={'Go to Profile'}
        click={() => navigate(`/${user?.role}/profile`)}
      />
    </>
  );
}

export default ReviewTender;
