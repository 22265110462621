import { takeLatest, call, put, all } from 'redux-saga/effects';
import { userActions } from '../slices/userSlice';
import {
  login,
  signup,
  forgotPassword,
  validateResetLink,
  changePasswordWithLink,
  getUserProfile,
  updateProfile,
  updatePassword,
  contactUs,
} from '../../services/userService';

function* loginSaga(action) {
  try {
    const response = yield call(login, action.payload);
    yield put(
      userActions.requestSuccess({
        ...response,
        ...{ remember_me: action.payload.remember_me },
      })
    );
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* signupSaga(action) {
  try {
    const response = yield call(signup, action.payload);
    yield put(userActions.requestSuccess(response));
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* forgotPasswordSaga(action) {
  try {
    const response = yield call(forgotPassword, action.payload);
    yield put(userActions.requestSuccess({ ...response, ...action.payload }));
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* updatePasswordSaga(action) {
  try {
    const response = yield call(updatePassword, action.payload);
    yield put(userActions.requestSuccess({ ...response, ...action.payload }));
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* validateResetLinkSaga(action) {
  try {
    yield call(validateResetLink, action.payload);
    yield put(userActions.requestSuccess({}));
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* resetPasswordRequestSaga(action) {
  try {
    const response = yield call(changePasswordWithLink, action.payload);
    yield put(userActions.requestSuccess(response));
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* getUserProfilSaga(action) {
  try {
    const response = yield call(getUserProfile, action.payload);
    yield put(userActions.requestSuccess(response));
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* updateProfilSaga(action) {
  try {
    const response = yield call(updateProfile, action.payload);
    yield put(userActions.requestSuccess(response));
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* contactUsSaga(action) {
  try {
    const response = yield call(contactUs, action.payload);
    yield put(userActions.requestSuccess(response));
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* updateTokensSaga(action) {
  try {
    yield put(userActions.requestSuccess(action.payload));
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* clearUserSaga() {
  try {
    yield put(userActions.clearUser());
  } catch (error) {
    yield put(userActions.requestFailure(error));
  }
}

function* userSaga() {
  yield all([
    takeLatest(userActions.loginRequest.type, loginSaga),
    takeLatest(userActions.signupRequest.type, signupSaga),
    takeLatest(userActions.forgotPasswordRequest.type, forgotPasswordSaga),
    takeLatest(userActions.validateResetLink.type, validateResetLinkSaga),
    takeLatest(userActions.resetPasswordRequest.type, resetPasswordRequestSaga),
    takeLatest(userActions.getUserProfile.type, getUserProfilSaga),
    takeLatest(userActions.updateProfile.type, updateProfilSaga),
    takeLatest(userActions.updatePassword.type, updatePasswordSaga),
    takeLatest(userActions.contactUs.type, contactUsSaga),
    takeLatest(userActions.updateTokens.type, updateTokensSaga),
    takeLatest(userActions.clearUserRequest.type, clearUserSaga),
  ]);
}

export default userSaga;
