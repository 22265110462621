import React, { useState } from 'react';
import './AddReviewModal.css';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { ROLES } from '../../../constants';
import { Rating } from 'react-simple-star-rating';

function AddReviewModal({ show, handleClose, submit, cancel, user }) {
  const [comments, setComment] = useState('');
  const [rating, setRating] = useState(0);

  const submitClick = () => {
    if (!rating) {
      return;
    }
    submit({ comments, rating });
    setComment('');
    setRating(0);
  };

  return (
    <div>
      <Modal className="review-modal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <h4 className="mt-4">Share your experience</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <div className="secondary-text-color">
              Feedback for this{' '}
              {user?.role === ROLES.farmer ? ROLES.supplier : ROLES.farmer}
            </div>
            <div className="mt-4">
              <label className="mb-2">Comments</label>
              <textarea
                placeholder="Add Comments"
                className={`form-input w-100`}
                rows={'10'}
                value={comments}
                onChange={(e) => setComment(e.target.value)}
              />
              <label className="mt-3 d-block">Rating</label>
              <Rating
                size={25}
                initialValue={rating}
                onClick={setRating}
                fillColor="green"
              />
            </div>
            <div className="mt-2 w-100 d-flex align-items-center justify-content-end">
              <Button
                onClick={cancel}
                className={`border-btn me-2 text-nowrap`}
              >
                Cancel
              </Button>
              <Button
                onClick={submitClick}
                className={`primary-btn text-nowrap`}
              >
                Add Review
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddReviewModal;
