import { createSlice } from '@reduxjs/toolkit';
import { notificationReducer } from '../reducers/notificationReducer';
import { notificationState } from '../states/notificationStates';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationState,
  reducers: notificationReducer,
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice;
