import React, { useEffect, useState } from 'react';
import './TenderListing.css';
import TenderListingHeader from '../../common/tender-listing-header/TenderListingHeader';
import { useDispatch, useSelector } from 'react-redux';
import { tenderActions } from '../../../store/slices/tenderSlice';
import SpinnerLoader from '../../common/spinner/Spinner';
import { ROLES, defaultPageMeta, errorToast } from '../../../constants';
import { toast } from 'react-hot-toast';
import TenderListingItem from '../../common/tender-listing-item/TenderListingItem';
import TenderListingSections from '../tender-listing-sections/TenderListingSections';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import SortBy from '../../common/sort-by/SortBy';
import Pages from '../../common/pagination/Pagination';
import { generateUniqueId } from '../../../services/utilService';

function TenderListingFarmer() {
  const navigate = useNavigate();
  const { status } = useParams();
  const [showSpinner, setSpinner] = useState(true);
  const [tenders, setTenders] = useState([]);
  const [meta, setMeta] = useState(defaultPageMeta);
  const [sort, setSort] = useState('');
  const [filters, setFilters] = useState(false);
  const dispatch = useDispatch();
  const tender = useSelector((state) => state.tender);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(
      tenderActions.getUserTenders({
        status,
        ...{
          size: defaultPageMeta.per_page,
          pageNo: defaultPageMeta.current_page,
        },
      })
    );
  }, [dispatch, status]);

  useEffect(() => {
    setSpinner(tender.loading);
    if (tender.error) {
      toast.error(tender.error, errorToast);
    }
    if (tender.response) {
      setTenders(tender.response.data || []);
      setMeta(tender.response.meta);
      setSpinner(false);
    }
    return () => {
      dispatch(tenderActions.clearErrors());
    };
  }, [dispatch, tender.error, tender.loading, tender.response]);

  const handleFilters = (filters) => {
    setFilters(filters);
    dispatch(
      tenderActions.getUserTenders({
        ...filters,
        status,
        sort,
        ...{ size: meta.per_page, pageNo: 1 },
      })
    );
  };

  const handleSort = (value) => {
    setSort(value);
    dispatch(
      tenderActions.getUserTenders({
        ...filters,
        status,
        sort: value,
        ...{ size: meta.per_page, pageNo: meta.current_page },
      })
    );
  };

  const pageClick = (pageNo) => {
    setMeta((prev) => {
      return { ...prev, current_page: pageNo };
    });
    dispatch(
      tenderActions.getUserTenders({
        ...filters,
        status,
        sort,
        ...{ size: meta.per_page, pageNo },
      })
    );
  };

  const pageSize = (size) => {
    setMeta((prev) => {
      return { ...prev, per_page: size };
    });
    dispatch(
      tenderActions.getUserTenders({
        ...filters,
        sort,
        ...{ size, pageNo: 1 },
      })
    );
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='mt-4'>
        <TenderListingHeader
          count={tenders?.length}
          getFilters={handleFilters}
        />
        <div className='my-4'>
          <SortBy sort={handleSort} />
        </div>
        <TenderListingSections
          active={status}
          setActive={(status) =>
            user?.user?.role === ROLES.farmer
              ? navigate(`/${user?.user?.role}/tender-listing/${status}`)
              : navigate(`/${user?.user?.role}/my-tenders/${status}`)
          }
        />
        {tenders && tenders.length ? (
          <>
            {tenders.map((tender) => (
              <TenderListingItem
                key={generateUniqueId()}
                tender={tender}
                tab={status}
              />
            ))}
            <Pages meta={meta} pageClick={pageClick} pageSize={pageSize} />
          </>
        ) : (
          <Container className='p-5'>No Records Found</Container>
        )}
      </div>
    </>
  );
}

export default TenderListingFarmer;
