import awsSlice from './awsSlice';
import messagesSlice from './messagesSlice';
import notificationSlice from './notificationSlice';
import quoteSlice from './quoteSlice';
import settingSlice from './settingSlice';
import tenderSlice from './tenderSlice';
import userSlice from './userSlice';

export const reducers = {
  user: userSlice.reducer,
  aws: awsSlice.reducer,
  tender: tenderSlice.reducer,
  quote: quoteSlice.reducer,
  messages: messagesSlice.reducer,
  notifications: notificationSlice.reducer,
  settings: settingSlice.reducer,
};
