import React from 'react';
import './Nav.css';
import { useNavigate } from 'react-router-dom';
import iinputTextImg from '../../../assets/images/Iinput-text.png';

function Nav() {
  const navigate = useNavigate();

  const getButtons = () => {
    return (
      <div>
        <button className='border-black-btn' onClick={() => navigate('/login')}>
          Login
        </button>
        <button className='black-btn ms-3' onClick={() => navigate('/signup')}>
          Sign Up
        </button>
      </div>
    );
  };

  return (
    <>
      <nav
        className='d-flex flex-row justify-content-between py-3'
        style={{ paddingLeft: '7.3%', paddingRight: '7.3%' }}
      >
        <div>
          <img
            src={iinputTextImg}
            alt='iinput'
            style={{ width: '189px', cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
        <div>{getButtons()}</div>
      </nav>
    </>
  );
}

export default Nav;
