import React, { useEffect, useState } from 'react';
import './UploadInvoice.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import TenderDetailHeader from '../../common/tender-detail-header/TenderDetailHeader';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from '../../common/drop-zone/DropZone';
import AttachmentCard from '../../common/cards/AttachmentCard';
import { generateUniqueId } from '../../../services/utilService';
import SpinnerLoader from '../../common/spinner/Spinner';
import { quoteActions } from '../../../store/slices/quoteSlice';
import SimpleModal from '../../modals/simple-modal/SimpleModal';
import {
  IMAGES_ASSESTS_PATH,
  INVOICE_SUBMITTED_MSG,
  INVOICE_SUBMITTED_TITLE,
  TENDER_DETAIL_TABS,
} from '../../../constants';
import { uploadFiles } from '../../../services/s3Service';
import { useNavigate, useParams } from 'react-router-dom';
import ViewAttachment from '../../common/view-attachment/ViewAttachment';

function UploadInvoice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const tenderObj = useSelector((state) => state.tender?.tender);
  const quote = useSelector((state) => state.quote);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [url, setURL] = useState('');
  const [review, setReview] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [modal, setModal] = useState(false);
  const [attachmentModal, setAttachmentModal] = useState(false);

  useEffect(() => {
    setSpinner(quote.loading);
  }, [quote.loading]);

  const filesChange = (files) => {
    setFiles(files);
  };

  const handleSubmit = async () => {
    setSpinner(true);
    const attachments = await uploadFiles(files, 'quotes/');
    setURL(attachments[0]);
    dispatch(
      quoteActions.updateQuoteRequest({
        invoice: {
          url: attachments[0]?.url,
          list: attachments.map((file) => file.url),
        },
        newInvoice: true,
        _id: id,
      })
    );
    setModal(true);
  };

  const backClick = () => {
    if (review) {
      setReview(false);
    } else {
      navigate(
        `/${user?.user?.role}/tender-detail/${tenderObj._id}/${TENDER_DETAIL_TABS.hired}`
      );
    }
  };

  const buttons = () => {
    return (
      <div className='mt-4 w-100 d-flex justify-content-end'>
        <Button onClick={backClick} className='border-btn me-2'>
          Back
        </Button>
        {!review && (
          <Button
            onClick={() => files.length && setReview(true)}
            className={`primary-btn ${!files.length && 'disabled-btn'}`}
            disabled={!files.length}
          >
            Review
          </Button>
        )}
        {review && (
          <Button onClick={handleSubmit} className={`primary-btn`}>
            Send
          </Button>
        )}
      </div>
    );
  };

  const showTender = () => {
    setModal(false);
    setAttachmentModal(false);
    navigate(
      `/${user?.user?.role}/tender-detail/${tenderObj._id}/${TENDER_DETAIL_TABS.tenderDetail}`
    );
  };

  const showInvoice = () => {
    setModal(false);
    setAttachmentModal(true);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container fluid>
        <div className='mt-4 auth-box tender-detail-box'>
          <TenderDetailHeader showFeeInvoice={false} tender={tenderObj} />
        </div>

        {!review ? (
          <div className='mt-4 auth-box tender-detail-box'>
            <h3>Upload Invoice</h3>
            <div className='secondary-text-color'>
              Please upload the tax invoice proof.
            </div>
            <div className='mt-4'>
              <label>
                Attach Invoice <span className='required-color'>*</span>
              </label>
              <Dropzone onChange={filesChange} value={files} />
            </div>

            {buttons()}
          </div>
        ) : (
          <div className='mt-4 auth-box tender-detail-box'>
            <h3>Summay</h3>
            <div className='secondary-text-color'>
              Here is the summary of you invoice before you send.
            </div>
            <Row className='mt-4 review-inner'>
              <div className='inner-header p-3 d-flex align-items-center justify-content-between'>
                <h6 className='text-white mb-0'>Invoice Details</h6>
                <img
                  src='/assets/images/edit.svg'
                  role='button'
                  onClick={() => setReview(false)}
                  alt='edit'
                />
              </div>
              <div className='p-3'>
                <Row>
                  <Col sm='12' className='mb-4'>
                    <div className='secondary-text-color mb-2'>Attachments</div>
                    {files.map((file) => (
                      <AttachmentCard
                        key={generateUniqueId()}
                        attachment={file}
                        setSpinner={setSpinner}
                        local={true}
                      />
                    ))}
                  </Col>
                </Row>
              </div>
            </Row>

            {buttons()}
          </div>
        )}
      </Container>
      <SimpleModal
        show={modal}
        handleClose={() => setModal(false)}
        noClick={showInvoice}
        yesClick={showTender}
        title={INVOICE_SUBMITTED_TITLE}
        message={INVOICE_SUBMITTED_MSG}
        img={`${IMAGES_ASSESTS_PATH}/Completed.svg`}
        primary
        noBtnText={'View Invoice'}
        yesBtnText={'View Tender'}
      />
      <ViewAttachment
        show={attachmentModal}
        handleClose={showTender}
        file={files.length ? url : { preview: '' }}
      />
    </>
  );
}

export default UploadInvoice;
