import { takeLatest, call, put, all } from 'redux-saga/effects';
import { quoteActions } from '../slices/quoteSlice';
import {
  createquote,
  getUserQuotes,
  updatequote,
  getMyQuotes,
  acceptQuote,
  getFarmerBills,
  getSupplierBills,
  getQuoteInvoice,
} from '../../services/quoteService';

function* createQuoteSaga(action) {
  try {
    const response = yield call(createquote, action.payload);
    yield put(quoteActions.requestSuccess(response));
  } catch (error) {
    yield put(quoteActions.requestFailure(error));
  }
}

function* updateQuoteSaga(action) {
  try {
    const response = yield call(updatequote, action.payload);
    yield put(quoteActions.requestSuccess(response));
  } catch (error) {
    yield put(quoteActions.requestFailure(error));
  }
}

function* getUserQuotesSaga(action) {
  try {
    const response = yield call(getUserQuotes, action.payload);
    yield put(quoteActions.requestSuccess(response));
  } catch (error) {
    yield put(quoteActions.requestFailure(error));
  }
}

function* myQuotesSaga(action) {
  try {
    const response = yield call(getMyQuotes, action.payload);
    yield put(quoteActions.requestSuccess(response));
  } catch (error) {
    yield put(quoteActions.requestFailure(error));
  }
}

function* acceptQuoteSaga(action) {
  try {
    const response = yield call(acceptQuote, action.payload);
    yield put(quoteActions.requestSuccess(response));
  } catch (error) {
    yield put(quoteActions.requestFailure(error));
  }
}

function* getFarmerBillsSaga(action) {
  try {
    const response = yield call(getFarmerBills, action.payload);
    yield put(quoteActions.requestSuccess(response));
  } catch (error) {
    yield put(quoteActions.requestFailure(error));
  }
}

function* getSupplierBillsSaga(action) {
  try {
    const response = yield call(getSupplierBills, action.payload);
    yield put(quoteActions.requestSuccess(response));
  } catch (error) {
    yield put(quoteActions.requestFailure(error));
  }
}

function* getQuoteInvoiceSaga(action) {
  try {
    const response = yield call(getQuoteInvoice, action.payload);
    yield put(quoteActions.requestSuccess(response));
  } catch (error) {
    yield put(quoteActions.requestFailure(error));
  }
}
function* quoteSaga() {
  yield all([
    takeLatest(quoteActions.createQuoteRequest.type, createQuoteSaga),
    takeLatest(quoteActions.updateQuoteRequest.type, updateQuoteSaga),
    takeLatest(quoteActions.getUserQuotes.type, getUserQuotesSaga),
    takeLatest(quoteActions.myQuotesRequest.type, myQuotesSaga),
    takeLatest(quoteActions.acceptQuotesRequest.type, acceptQuoteSaga),
    takeLatest(quoteActions.getFarmerBillsRequest.type, getFarmerBillsSaga),
    takeLatest(quoteActions.getSupplierBillsRequest.type, getSupplierBillsSaga),
    takeLatest(quoteActions.getQuoteInvoiceRequest.type, getQuoteInvoiceSaga),
  ]);
}

export default quoteSaga;
