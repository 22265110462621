import {
  authorizedGetCall,
  authorizedPostCall,
  authorizedPutCall,
} from './APIsService';

export const createquote = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/quote/create', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserQuotes = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/quote/all`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updatequote = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/quote/${body._id}`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMyQuotes = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/quote/my-all`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const acceptQuote = (id) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/quote/accept/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFarmerBills = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/quote/get-farmer-bills', body)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSupplierBills = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/quote/get-supplier-bills', body)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getQuoteInvoice = (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/quote/invoice-detail/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getOneQuote = (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/quote/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
