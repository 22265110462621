import React, { useEffect, useState } from 'react';
import './QuoteDetailTab.css';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  downloadFiles,
  generateUniqueId,
  numberToCurrency,
} from '../../../services/utilService';
import AttachmentCard from '../../common/cards/AttachmentCard';
import { quoteActions } from '../../../store/slices/quoteSlice';
import QuoteForm from '../../common/quotes-creat/QuoteForm';
import { toast } from 'react-hot-toast';
import {
  QUOTES_STATUSES,
  TRANSPORT,
  errorToast,
  successToast,
} from '../../../constants';
import TaxInvoice from './TaxInvoice';
import TenderDetailHeader from '../../common/tender-detail-header/TenderDetailHeader';
import AboutUser from '../../common/about-user/AboutUser';
import { tenderActions } from '../../../store/slices/tenderSlice';

function QuoteDetailTab(props) {
  const quoteState = useSelector((state) => state.quote);
  const user = useSelector((state) => state.user.user);
  const [isEdit, setEdit] = useState(false);
  const [quote, setQuote] = useState(props?.quote);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDownload = async () => {
    props.setSpinner(true);
    const urls = quote.attachments.map((attachment) => attachment.url);
    await downloadFiles(urls, true);
    props.setSpinner(false);
  };

  const detailDiv = (title, content) => {
    return (
      <Col sm='12' className='mb-4'>
        <div className='secondary-text-color mb-2'>{title}</div>
        <div className='pre-wrap'>{content}</div>
      </Col>
    );
  };

  const handleEdit = () => {
    dispatch(quoteActions.persistQuote(quote));
    setEdit(true);
  };

  useEffect(() => {
    props.setSpinner(quoteState.loading);
    if (quoteState.error) {
      toast.error(quoteState.error, errorToast);
    }

    if (quoteState.response?.message) {
      toast.success(quoteState.response?.message, successToast);
      dispatch(tenderActions.persistTender(props.tender));
      setEdit(false);
      setQuote(quoteState.response?.quote);
    }

    return () => {
      dispatch(quoteActions.clearErrors());
    };
  }, [
    quoteState.response?.message,
    quoteState.error,
    quoteState.loading,
    quoteState.response?.quote,
    dispatch,
    navigate,
    props,
  ]);

  return (
    <Container fluid>
      <div className='my-4 auth-box tender-detail-box'>
        <TenderDetailHeader tender={props.tender} isPending={props.isPending} />
      </div>
      {quote?.status === QUOTES_STATUSES.accepted ? (
        <>
          <div className='my-4 auth-box tender-detail-box no-bg'>
            <Row>
              <Col md='12'>
                <AboutUser
                  user={props?.tender?.user_id}
                  tender={props.tender}
                />
              </Col>
              <Col md='12' className='mt-4'>
                <TaxInvoice quote={quote} user={user} />
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <TaxInvoice quote={quote} user={user} />
      )}

      <div className='auth-box quote-detail-box'>
        <Row className='mb-4 review-inner'>
          <div
            className={`inner-header ${
              isEdit && 'inner-header-blue'
            } p-3 d-flex align-items-center justify-content-between`}
          >
            <h6 className='text-white mb-0'>My Offer</h6>
            {!isEdit && !props.isHired && (
              <img
                src='/assets/images/edit.svg'
                role='button'
                onClick={handleEdit}
                alt='edit'
              />
            )}
          </div>
          <div className='p-3'>
            {!isEdit ? (
              <Row>
                {!quote?.quote_shipping
                  ? detailDiv(
                      quote?.transport === TRANSPORT.delivery
                        ? 'Transport Option'
                        : `${quote?.transport} Location`,
                      quote?.transport === TRANSPORT.delivery
                        ? 'Deliver to Farm'
                        : quote?.post_code
                    )
                  : ''}

                {!quote?.quote_shipping
                  ? detailDiv(
                      `${quote?.transport} Date`,
                      moment(quote?.completion_date).format('DD-MM-YYYY')
                    )
                  : ''}

                {quote?.quote_shipping
                  ? detailDiv('Transport', quote?.quote_shipping)
                  : ''}

                {detailDiv('Quote Details', quote?.details || 'N/A')}

                {detailDiv(
                  'Total Price (Includes GST)',
                  `${numberToCurrency(quote?.price)}`
                )}

                {detailDiv(
                  'Quote Validity',
                  quote?.quote_validity
                    ? moment(quote?.quote_validity).format('DD-MM-YYYY')
                    : 'N/A'
                )}

                {detailDiv('Payment Terms', quote?.payment_terms || 'N/A')}

                {quote?.attachments?.length ? (
                  <div className='mt-3'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                      <h5>Attachments</h5>
                      <div
                        onClick={handleDownload}
                        role='button'
                        className='primary-color'
                      >
                        <span>Download all</span>
                        <img
                          className='download-icon ms-2 mb-2'
                          src={'/assets/icons/supplier/Download.svg'}
                          alt='download'
                        />
                      </div>
                    </div>

                    <Row>
                      {quote?.attachments.map((attachment) => (
                        <AttachmentCard
                          key={generateUniqueId()}
                          attachment={attachment}
                          setSpinner={props.setSpinner}
                        />
                      ))}
                    </Row>
                  </div>
                ) : (
                  <>{detailDiv('Attachments', 'N/A')}</>
                )}
              </Row>
            ) : (
              <QuoteForm
                isUploaded={true}
                id={quote?.tender_id}
                edit={true}
                cancel={() => setEdit(false)}
                setSpinner={props.setSpinner}
              />
            )}
          </div>
        </Row>
      </div>
    </Container>
  );
}

export default QuoteDetailTab;
