import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  IMAGES_ASSESTS_PATH,
  LOGOUT_MODAL_MSG,
  LOGOUT_MODAL_TITLE,
  ROLES,
} from '../../../constants';
import { Button, Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import './Navbar.css';
import { userActions } from '../../../store/slices/userSlice';
import SimpleModal from '../../modals/simple-modal/SimpleModal';
import { capitalize } from 'lodash';
import { tenderActions } from '../../../store/slices/tenderSlice';
import { connect } from 'socket.io-client';
import { getMyRooms } from '../../../services/messagesService';
import {
  generateUniqueId,
  getNotifiNavigate,
  getNotificationImg,
  isTokenExpired,
} from '../../../services/utilService';
import moment from 'moment';
import {
  fetchNotifications,
  updateNotification,
} from '../../../services/notificationService';
import { settingActions } from '../../../store/slices/settingSlice';
import { toast } from 'react-hot-toast';

function NavBar() {
  const [showLogoutModal, setLogoutModal] = useState(false);
  const [role, setRole] = useState(false);
  const [socket, setSocket] = useState();
  const [notifications, setNotifications] = useState([]);
  const [unseenMsg, setUnseenMsg] = useState();
  const [unseenNotif, setUnseenNotif] = useState();
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (user.user?.access_token && !isTokenExpired()) {
      switch (user?.user?.role) {
        case ROLES.farmer:
          setRole(ROLES.farmer);
          break;
        case ROLES.supplier:
          setRole(ROLES.supplier);
          break;
        default:
          setRole(ROLES.retailer);
      }
    } else {
      setRole(false);
    }
  }, [user.user?.role, user.user?.access_token, location.pathname]);

  const getRooms = useCallback(() => {
    getMyRooms()
      .then((data) => {
        const sum = data.reduce((accumulator, object) => {
          return (
            accumulator +
            (object?.latestMessage?.content ? object.unseenCount : 0)
          );
        }, 0);
        setUnseenMsg(sum);
      })
      .catch((error) => console.log(error));
  }, []);

  useLayoutEffect(() => {
    const newSocket = connect(
      process.env.REACT_APP_BACKEND_URL.replace('/api/v1', '')
    );
    newSocket.emit('join_room', 'ALLCHATS');
    setSocket(newSocket);

    return () => {
      newSocket.off('receive_message');
      newSocket.disconnect();
    };
  }, [user.user?.role]);

  const getNewNotifications = () => {
    fetchNotifications()
      .then((data) => {
        setNotifications(data.data);
        setUnseenNotif(data.unseenCount);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNewNotifications();
    getRooms();
  }, [user.user?.role, getRooms]);

  useEffect(() => {
    if (socket) {
      socket.on('update_rooms', () => {
        getRooms();
      });
      socket.on('update_notification', () => {
        getNewNotifications();
      });
      socket.on('admin_pass_changed', (data) => {
        if (data.userId === user?.user?._id) {
          toast.error(
            'Admin has changed your password. You need to Login again!'
          );
          dispatch(userActions.clearUserRequest());
          dispatch(settingActions.clearRequest());
          navigate('/login');
        }
      });
    }
  }, [socket, getRooms, dispatch, navigate, user?.user?._id]);

  const loginButton = () => {
    return (
      <Button className='border-green-btn' onClick={() => navigate('/login')}>
        Login
      </Button>
    );
  };

  const signUpButton = () => {
    return (
      <Button className='primary-btn' onClick={() => navigate('/signup')}>
        Sign Up
      </Button>
    );
  };

  const noAuthNavbar = () => {
    if (location.pathname === '/') {
      return (
        <Nav>
          <Nav.Link className='me-2'>{loginButton()}</Nav.Link>
          <Nav.Link>{signUpButton()}</Nav.Link>
        </Nav>
      );
    } else if (location.pathname === '/login') {
      return (
        <Nav>
          <Nav.Link>{signUpButton()}</Nav.Link>
        </Nav>
      );
    } else if (location.pathname === '/signup') {
      return (
        <Nav>
          <Nav.Link>{loginButton()}</Nav.Link>
        </Nav>
      );
    } else {
      return <Nav></Nav>;
    }
  };

  const userInfo = (name, email, hide = false) => {
    return (
      <div className='d-flex align-items-center justify-content-between'>
        <img
          className='profile-img'
          src={
            user?.user?.profileUrl || `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
          }
          alt='img'
        />
        <div className={`${hide && 'md-hide'}`}>
          <small>{name}</small>
          <br />
          <small>{email}</small>
        </div>
      </div>
    );
  };

  const handleLogout = () => {
    setLogoutModal(false);
    dispatch(userActions.clearUserRequest());
    dispatch(settingActions.clearRequest());
    navigate('/login');
  };

  const handleTenderLisiting = () => {
    switch (role) {
      case ROLES.farmer:
        navigate('/farmer/tender-listing/Active');
        break;
      case ROLES.supplier:
        navigate('/supplier/tender-listing');
        break;
      default:
        navigate('/retailer/tender-listing');
        break;
    }
  };

  const handleLogoClick = () => {
    if (role) {
      role === ROLES.farmer
        ? navigate('/farmer/tender-listing/Active')
        : navigate('/supplier/tender-listing');
    } else {
      navigate('/');
    }
  };

  const checkIfActive = (path) => location.pathname.startsWith(path);

  const newTender = () => {
    dispatch(tenderActions.persistClear());
    navigate(`/${user.user?.role}/create-tender`);
  };

  const handleProfile = () => {
    navigate(`/${user?.user?.role}/profile`);
  };

  const navigateNotification = async (notif) => {
    await updateNotification({
      _id: notif._id,
      isRead: true,
    });
    getNewNotifications();
    const url = getNotifiNavigate(notif?.type, notif?.data, user?.user?.role);
    if (url === location.pathname) {
      navigate(0);
    } else {
      navigate(url);
    }
  };

  const getNotifications = () => {
    const list = [];
    notifications.length &&
      list.push(
        <NavDropdown.Item
          className='notif-head pe-none'
          key={generateUniqueId()}
        >
          <div className='w-100 py-2 text-white'>Notifications</div>
        </NavDropdown.Item>
      );
    notifications.slice(0, 3).forEach((notification) => {
      list.push(
        <NavDropdown.Item
          onClick={() => navigateNotification(notification)}
          key={generateUniqueId()}
          className={!notification?.isRead && 'unread-notif'}
        >
          <div className='py-2'>
            <div className='d-flex align-items-center'>
              <img
                src={getNotificationImg(notification?.type, notification?.data)}
                alt='profile'
                className='small-icon me-2 border-50'
              />
              <div className='wrap-break'>{notification?.title}</div>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
              <small className='secondary-text-color'>
                {moment(notification?.createdAt).format('DD-MM-YYYY')}
              </small>
            </div>
          </div>
        </NavDropdown.Item>
      );
    });
    list.push(
      notifications.length ? (
        <NavDropdown.Item
          key={generateUniqueId()}
          onClick={() => navigate(`/${user?.user?.role}/notifications`)}
        >
          <div className='w-100 text-center py-2'>View All</div>
        </NavDropdown.Item>
      ) : (
        <NavDropdown.Item key={generateUniqueId()}>
          <div className='w-100 text-center py-2 pe-none'>No Notifications</div>
        </NavDropdown.Item>
      )
    );
    return list;
  };

  const getNavbar = () => {
    if (!role) {
      return noAuthNavbar();
    } else {
      return (
        <Nav>
          {(user?.user?.role === ROLES.farmer ||
            user?.user?.role === ROLES.retailer) && (
            <Nav.Link>
              <div className='h-100 d-flex align-items-center'>
                <Button
                  onClick={newTender}
                  className='primary-btn md-hide px-4 me-4'
                >
                  <div className='d-flex align-items-center'>
                    <span>
                      <img
                        className='me-2'
                        alt='add'
                        src='/assets/icons/supplier/Add.svg'
                      />
                    </span>
                    <span>Submit New Tender</span>
                  </div>
                </Button>
              </div>
            </Nav.Link>
          )}
          <Nav.Link onClick={() => navigate(`/${user.user?.role}/messages`)}>
            <div className='position-relative'>
              <img src={`${IMAGES_ASSESTS_PATH}/Messages.svg`} alt='messages' />
              {unseenMsg ? (
                <div className='position-absolute count-unseen'>
                  {unseenMsg}
                </div>
              ) : (
                <></>
              )}
            </div>
          </Nav.Link>
          <NavDropdown
            className='notif'
            title={
              <div className='position-relative'>
                <img
                  src={`${IMAGES_ASSESTS_PATH}/Notifications.svg`}
                  alt='messages'
                />
                {unseenNotif ? (
                  <div className='position-absolute count-unseen'>
                    {unseenNotif}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            }
          >
            {getNotifications()}
          </NavDropdown>
          <NavDropdown
            title={userInfo(user?.user?.name, capitalize(role), true)}
            className='user-icon'
          >
            <NavDropdown.Item>
              {userInfo(user?.user?.name, user?.user?.email)}
            </NavDropdown.Item>
            <NavDropdown.Item className='mt-3' onClick={handleProfile}>
              <span>
                <img
                  src={`${IMAGES_ASSESTS_PATH}/User-Profile.svg`}
                  alt='profile'
                  className='dropdown-icon'
                />
              </span>
              <span role='button'>Profile</span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={() => navigate(`/${user.user.role}/my-disputes`)}
            >
              <span>
                <img
                  src={`${IMAGES_ASSESTS_PATH}/Dispute.svg`}
                  alt='Dispute'
                  className='dropdown-icon'
                />
              </span>
              My Disputes
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => navigate(`/${user?.user?.role}/billing-history`)}
            >
              <span>
                <img
                  src={`${IMAGES_ASSESTS_PATH}/Billing.svg`}
                  alt='Billing'
                  className='dropdown-icon'
                />
              </span>
              Billing History
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => navigate(`/${user.user.role}/settings`)}
            >
              <span>
                <img
                  src={`${IMAGES_ASSESTS_PATH}/Settings.svg`}
                  alt='Settings'
                  className='dropdown-icon'
                />
              </span>
              Settings
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => navigate(`/${user.user.role}/contact-us`)}
            >
              <span>
                <img
                  src={`${IMAGES_ASSESTS_PATH}/Contact.svg`}
                  alt='Contact'
                  className='dropdown-icon'
                />
              </span>
              Contact Us
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => setLogoutModal(true)}>
              <span>
                <img
                  src={`${IMAGES_ASSESTS_PATH}/Logout.svg`}
                  alt='Logout'
                  className='dropdown-icon'
                />
              </span>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      );
    }
  };

  const innerNav = () => {
    return (
      <>
        <Nav.Link
          className={`nav-hover ${
            checkIfActive(`/${role}/tender-listing`) && 'active'
          }`}
          onClick={handleTenderLisiting}
        >
          Tender Listing
        </Nav.Link>
        {user?.user?.role === ROLES.retailer ? (
          <Nav.Link
            className={`nav-hover ${
              checkIfActive(`/retailer/my-tenders/Active`) && 'active'
            }`}
            onClick={() => navigate('/retailer/my-tenders/Active')}
          >
            My Tenders
          </Nav.Link>
        ) : (
          ''
        )}
        {role === ROLES.farmer ? (
          <></>
        ) : (
          <Nav.Link
            className={`nav-hover ${
              checkIfActive(`/${role}/my-quotes`) && 'active'
            }`}
            onClick={() => navigate(`/${user?.user?.role}/my-quotes`)}
          >
            My Quotes
          </Nav.Link>
        )}
      </>
    );
  };

  return (
    <>
      <div className='navbar-div d-flex align-items-center'>
        <div className='mobile-nav'>
          <Navbar>
            <Container>
              <Nav>
                <NavDropdown
                  title={<img src='/assets/images/Menu.svg' alt='menu' />}
                >
                  <NavDropdown.Item onClick={handleTenderLisiting}>
                    Tender Listing
                  </NavDropdown.Item>
                  {role === ROLES.farmer ? (
                    <></>
                  ) : (
                    <NavDropdown.Item
                      onClick={() => navigate(`/${user.user.role}/my-quotes`)}
                    >
                      My Quotes
                    </NavDropdown.Item>
                  )}
                  {role === ROLES.farmer || role === ROLES.supplier ? (
                    <></>
                  ) : (
                    <NavDropdown.Item
                      onClick={() => navigate('/retailer/my-tenders/Active')}
                    >
                      My Tenders
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              </Nav>
            </Container>
          </Navbar>
        </div>
        <div className='main-nav'>
          <Navbar>
            <Container fluid className='navbar-container'>
              <Navbar.Brand>
                <img
                  src={`${IMAGES_ASSESTS_PATH}/Logo.png`}
                  className='d-inline-block align-top logo'
                  alt='logo'
                  role='button'
                  onClick={handleLogoClick}
                />
              </Navbar.Brand>
              {role ? (
                <Nav className='me-auto left-nav'>{innerNav()}</Nav>
              ) : (
                <Nav className='me-auto'></Nav>
              )}
              {getNavbar()}
            </Container>
          </Navbar>
        </div>
      </div>
      <SimpleModal
        show={showLogoutModal}
        handleClose={() => setLogoutModal(false)}
        noClick={() => setLogoutModal(false)}
        yesClick={handleLogout}
        title={LOGOUT_MODAL_TITLE}
        message={LOGOUT_MODAL_MSG}
        img={`${IMAGES_ASSESTS_PATH}/Logout-Modal.svg`}
      />
      {location.pathname.startsWith('/farmer/tender-listing') && (
        <div className='add-icon-sticky'>
          <Button
            onClick={() => navigate('/farmer/create-tender')}
            className='add-sticky-btn p-3'
          >
            <img
              src='/assets/icons/supplier/Add.svg'
              alt='add'
              className='add-sticky-img'
            />
          </Button>
        </div>
      )}
      <Outlet />
    </>
  );
}

export default NavBar;
