import React from 'react';
import './AboutPage.css';
import { Col, Container, Row } from 'react-bootstrap';
import frasserImage from '../../assets/images/About Us/About Us.png';
import soilImage from '../../assets/images/Rectangle 19561.png';
import plantImage from '../../assets/images/Mask Group 1.png';
import Footer from '../common/Footer/Footer';
import Nav from '../common/landing-nav/Nav';

function AboutPage() {
  return (
    <>
      <Nav />
      <Container fluid>
        <Row>
          <Col xl='6' className='body-container '>
            <div className='top-text'>
              <span style={{ color: '#438031' }}>Who</span> Are We
            </div>
            <div className='body-text'>
              <p>
                <span style={{ color: '#438031' }}>iinputs.com</span> is a
                product of <span style={{ color: '#438031' }}>Averell</span> &{' '}
                <span style={{ color: '#438031' }}>Fraser Kessling</span>, a
                father-son duo from the farming town of Kimba, South Australia.
                Averell’s been crunching ag numbers in finance and accounting
                for over 35 years, while Fraser’s Engineering background brings
                the tech and innovation.
              </p>
              <p>
                iinputs.com? That’s our solution to the headaches farmers face
                in getting fair deals on farming inputs. Based right here in our
                rural community, it’s a national tendering marketplace where
                farmers can grab the best deal on Chemical, Fertiliser, Fuel, or
                Seed, hassle-free.
              </p>
              <p>
                Why us? Well, we get the farming life because we’re living it
                too. No fancy suits here just a genuine effort to make things
                simpler for our fellow farmers. Join us at iinputs.com and let’s
                make ag procurement a breeze.
              </p>
            </div>
          </Col>
          <Col xl='6' className='d-sm-flex justify-content-center'>
            <img src={frasserImage} alt='Frasser' style={{ width: '50vw' }} />
          </Col>
        </Row>
      </Container>

      <Container fluid style={{ backgroundColor: '#F1F8EA' }}>
        <Row>
          <Col
            xs={{ order: 2 }}
            xl={{ order: 1, span: 6 }}
            className='d-sm-flex justify-content-center'
          >
            <img src={soilImage} alt='Soil' style={{ width: '50vw' }} />
          </Col>
          <Col
            xs={{ order: 1 }}
            xl={{ order: 2, span: 6 }}
            className='body-container '
          >
            <div className='top-text'>
              Our <span style={{ color: '#438031' }}>Mission</span>
            </div>
            <div className='body-text'>
              Our mission at iinputs.com is to bridge the gap between farmers
              and suppliers, making agricultural transactions seamless and
              efficient. We understand the challenges that growers face in
              sourcing the best deal when it comes to chemical and fertiliser.
              We aim to provide them with a technology solution that empowers
              them to shop around with ease.
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col xl='6' className='body-container'>
            <div className='top-text'>
              <span style={{ color: '#438031' }}>About</span>
            </div>
            <div className='body-text'>
              iinputs.com , Connecting Growers with Suppliers in the ag-tech
              industry. We are proud to introduce ourselves as a pioneering farm
              inputs tendering marketplace, dedicated to simplifying and
              streamlining the way growers connect with wholesale suppliers,
              retailers, and manufacturers. At iinputs.com, we are driven by
              innovation and committed to empowering the agriculture community.
            </div>
          </Col>
          <Col xl='6' className='d-sm-flex justify-content-center'>
            <img src={plantImage} alt='plants' style={{ width: '50vw' }} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default AboutPage;
