import React, { useState } from 'react';
import './PasswordField.css';
import { IMAGES_ASSESTS_PATH, passwordRegex } from '../../../constants';

function PasswordField(props) {
  const [showPass, setShowPass] = useState(false);
  return (
    <div className="position-relative">
      <input
        placeholder={props.placeholder}
        className={props.className}
        {...props.register(props.name, {
          ...(props.validate && { pattern: passwordRegex }),
          required: true,
        })}
        type={showPass ? 'text' : 'password'}
        disabled={props.disabled}
      />
      {showPass ? (
        <img
          role="button"
          onClick={() => setShowPass((prev) => !prev)}
          className="position-absolute eye-icon"
          src={`${IMAGES_ASSESTS_PATH}/show-pass.svg`}
          alt="show-pass"
        />
      ) : (
        <img
          role="button"
          onClick={() => setShowPass((prev) => !prev)}
          className="position-absolute eye-icon"
          src={`${IMAGES_ASSESTS_PATH}/hide-pass.svg`}
          alt="hide-pass"
        />
      )}
    </div>
  );
}

export default PasswordField;
