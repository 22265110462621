import React from 'react';
import './TenderDetailSections.css';
import { generateUniqueId } from '../../../services/utilService';
import { Container } from 'react-bootstrap';
import {
  FARMER_DETAIL_TABS,
  ROLES,
  SUPPLIER_DETAIL_TABS,
  TENDER_DETAIL_TABS,
  RETAILER_DETAIL_TABS,
} from '../../../constants';

function TenderDetailSections({ active, setActive, role, isQuoteSubmitted }) {
  const getTabs = () => {
    switch (role) {
      case ROLES.farmer:
        return FARMER_DETAIL_TABS;
      case ROLES.supplier:
        return SUPPLIER_DETAIL_TABS;
      case ROLES.retailer:
        return RETAILER_DETAIL_TABS;
      default:
    }
  };

  return (
    <Container
      fluid
      className='mt-4 text-nowrap overflow-scroll tender-sections'
    >
      <div className='auth-box tender-detail-box no-bg'>
        {getTabs().map((status) => (
          <div
            key={generateUniqueId()}
            className={`mb-2 py-2 px-3 text-center secondary-text-color d-inline-block ${
              active === status ? 'active-tab primary-color' : 'passive-tab'
            } ${
              !isQuoteSubmitted &&
              status === TENDER_DETAIL_TABS.quoteDetail &&
              'd-none'
            }`}
            onClick={() => setActive(status)}
            role='button'
          >
            {status}
          </div>
        ))}
      </div>
    </Container>
  );
}

export default TenderDetailSections;
