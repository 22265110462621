import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TenderDetail.css';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { tenderActions } from '../../../store/slices/tenderSlice';
import SpinnerLoader from '../spinner/Spinner';
import { createRoom } from '../../../services/messagesService';
import BreadCrumb from '../../../assets/images/Breadcrumb.png';
import {
  IMAGES_ASSESTS_PATH,
  MARK_COMPLETE_DONE_MODAL_MSG,
  MARK_COMPLETE_DONE_MODAL_TITLE,
  MARK_COMPLETE_MODAL_MSG,
  MARK_COMPLETE_MODAL_TITLE,
  MARK_DISPUTE_DONE_MODAL_MSG,
  MARK_DISPUTE_DONE_MODAL_TITLE,
  MARK_DISPUTE_MODAL_MSG,
  MARK_DISPUTE_MODAL_TITLE,
  ROLES,
  TENDER_DETAIL_TABS,
  TENDER_STATUSES,
  errorToast,
} from '../../../constants';
import TenderDetailSections from '../tender-detail-sections/TenderDetailSections';
import TenderDetailTab from '../tender-detail-tab/TenderDetailTab';
import QuoteDetailTab from '../../supplier/quote-detail-tab/QuoteDetailTab';
import { toast } from 'react-hot-toast';
import { quoteActions } from '../../../store/slices/quoteSlice';
import QuotesListing from '../../farmer/quotes-listing/QuotesListing';
import Hired from '../../farmer/hired/Hired';
import { createtender } from '../../../services/tenderService';
import SimpleModal from '../../modals/simple-modal/SimpleModal';
import OneButtonModal from '../../modals/one-button-modal/OneButtonModal';
import ResolveDisputeModal from '../../modals/resolve-dispute/ResolveDisputeModal';
import AddReviewModal from '../../modals/add-review/AddReviewModal';

const TenderDetail = ({ showFeeInvoice, hideTabs }) => {
  const tenderState = useSelector((state) => state.tender);
  const location = useLocation();
  const [showSpinner, setSpinner] = useState(true);
  const [tender, setTender] = useState();
  const [quotes, setQuotes] = useState();
  const { id, tab } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [showModal, setModal] = useState(false);
  const [showSubmitModal, setSubmitModal] = useState(false);

  const [disputeModal, setDisputeModal] = useState(false);
  const [doneDisputeModal, setDoneDisputeModal] = useState(false);

  const [resolveDisputeModal, setResolveDisputeModal] = useState(false);
  const [resolvedModal, setResolvedModal] = useState(false);

  const [reviewModal, setReviewModal] = useState(false);
  const [reviewDoneModal, setReviewDoneModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    location.pathname.startsWith(
      `/retailer/tender-detail/${id}/Quote%20Details`
    ) ||
    location.pathname.startsWith(
      `/retailer/tender-detail/${id}/Tender%20Details`
    )
      ? dispatch(tenderActions.getRetailerQuotesRequest(id))
      : dispatch(tenderActions.getTenderQuotesRequest(id));
    // dispatch(tenderActions.getRetailerQuotesRequest(id));
    return () => {
      dispatch(tenderActions.clearErrors());
    };
  }, [dispatch, id, resolvedModal, reviewDoneModal]);

  useEffect(() => {
    if (tenderState.error) {
      toast.error(tenderState.error, errorToast);
    }
    if (tenderState.response) {
      setTender(tenderState.response.tender);
      dispatch(tenderActions.persistTender(tenderState.response.tender));
      setQuotes(tenderState.response.quotes);
      setSpinner(false);
    }
  }, [tenderState.loading, tenderState.error, tenderState.response, dispatch]);

  const showTabComponent = () => {
    switch (tab) {
      case TENDER_DETAIL_TABS.tenderDetail:
        return (
          <TenderDetailTab
            quote={quotes?.length ? quotes[0] : null}
            setSpinner={setSpinner}
            tender={tender}
            tender1={tenderState.tender}
            showFeeInvoice={showFeeInvoice}
            role={user.role}
            userId={user._id}
            isPending={
              (user.role === ROLES.supplier || user.role === ROLES.retailer) &&
              quotes?.length &&
              tender.status === TENDER_STATUSES.active
            }
          />
        );

      case TENDER_DETAIL_TABS.quoteDetail:
        return (
          <QuoteDetailTab
            quote={quotes?.length ? quotes[0] : null}
            tender={tender}
            setSpinner={setSpinner}
            isPending={
              (user.role === ROLES.supplier || user.role === ROLES.retailer) &&
              quotes?.length &&
              tender.status === TENDER_STATUSES.active
            }
            isHired={
              tender.status === TENDER_STATUSES.inProgress ||
              tender.status === TENDER_STATUSES.completed ||
              tender.status === TENDER_STATUSES.dispute ||
              tender.status === TENDER_STATUSES.closed
            }
          />
        );

      case TENDER_DETAIL_TABS.quotes:
        return <QuotesListing tender_id={tender._id} />;

      case TENDER_DETAIL_TABS.hired:
        return (
          <Hired
            quote={quotes?.length ? quotes[0] : null}
            tender={tender}
            setSpinner={setSpinner}
            isHired={
              tender.status === TENDER_STATUSES.inProgress ||
              tender.status === TENDER_STATUSES.completed ||
              tender.status === TENDER_STATUSES.dispute ||
              tender.status === TENDER_STATUSES.closed
            }
          />
        );

      default:
        return (
          <TenderDetailTab
            quote={quotes?.length ? quotes[0] : null}
            setSpinner={setSpinner}
            tender={tender}
            tender1={tenderState.tender}
            showFeeInvoice={showFeeInvoice}
            role={user.role}
            isPending={
              user.role === ROLES.supplier &&
              quotes?.length &&
              tender.status === TENDER_STATUSES.active
            }
          />
        );
    }
  };

  const handleSubmit = () => {
    dispatch(quoteActions.persistClear());
    navigate(`/${user?.role}/create-quote/${id}`);
  };

  const checkInvoiceDisabled = () => {
    return (
      tender.status !== TENDER_STATUSES.inProgress || quotes[0]?.invoice?.url
    );
  };

  const startChat = async () => {
    const room = await createRoom({
      ids: [tender?.user_id?._id, user._id],
      tenderId: tender._id,
    });
    navigate(`/${user?.role}/messages`, { state: { room: room._id } });
  };

  const handleUploadInvoice = () => {
    if (checkInvoiceDisabled()) {
      return;
    }
    dispatch(quoteActions.persistQuote(quotes[0]));
    navigate(`/${user?.role}/upload-invoice/${quotes[0]._id}`);
  };

  const handleTabClick = (tab) => {
    location.pathname.startsWith(
      `/retailer/tender-detail/${tender?._id}/Quotes`
    )
      ? dispatch(tenderActions.getRetailerQuotesRequest(tender?._id))
      : dispatch(tenderActions.getTenderQuotesRequest(tender?._id));

    navigate(`/${user.role}/tender-detail/${tender._id}/${tab}`);
  };

  const handleDeliveryComplete = async () => {
    setSpinner(true);
    //updating tender
    await createtender({
      _id: tender._id,
      status: TENDER_STATUSES.completed,
      markComplete: true,
    });
    setSpinner(false);
    setSubmitModal(true);
  };

  const handleDispute = async () => {
    setSpinner(true);
    //updating tender
    await createtender({
      _id: tender._id,
      status: TENDER_STATUSES.dispute,
      newDispute: true,
      $push: {
        disputes: {
          dispute_user: user._id,
        },
      },
    });
    setSpinner(false);
    setDisputeModal(false);
    setDoneDisputeModal(true);
  };

  const checkDisputeDisabled = () => {
    return tender?.disputes?.find(
      (dispute) =>
        dispute.dispute_user !== user?._id && dispute.isClosed === false
    );
  };

  const handleResolve = async (reason) => {
    setSpinner(true);
    //updating tender
    await createtender({
      _id: tender._id,
      resolveDispute: true,
      status: TENDER_STATUSES.inProgress,
      'disputes.$[e1].reason': reason,
      'disputes.$[e1].isClosed': true,
      'disputes.$[e1].endDate': new Date(),
    });
    setSpinner(false);
    setResolveDisputeModal(false);
    setResolvedModal(true);
  };

  const handleResolved = () => {
    navigate(
      `/${user.role}/tender-detail/${tender._id}/${TENDER_DETAIL_TABS.tenderDetail}`
    );
    setResolvedModal(false);
  };

  const handleReviewSubmit = async (obj) => {
    setSpinner(true);
    await createtender({
      _id: tender._id,
      ...(user?._id !== tender?.user_id._id
        ? { supplier_review: obj, user_reviewed: tender?.user_id }
        : { user_review: obj, user_reviewed: tender?.supplier_id }),
    });
    setReviewModal(false);
    setReviewDoneModal(true);
    setSpinner(false);
  };

  const checkIfReviewAdded = () => {
    return user?._id !== tender?.user_id._id
      ? tender?.supplier_review?.rating
      : tender?.user_review?.rating;
  };

  return (
    <div className='mt-4'>
      <SpinnerLoader showSpinner={showSpinner} />
      {!showFeeInvoice && (
        <div className='container auth-box tender-detail-box no-bg'>
          <div className='d-flex separate-small justify-content-between align-items-center px-3'>
            <div className='directions'>
              <img src={BreadCrumb} alt='breadcrumbs' />
            </div>
            <div className='d-flex flex-direction-row gap-3'>
              {(user?.role === ROLES.supplier ||
                user?.role === ROLES.retailer) &&
                user?._id !== tender?.user_id?._id && (
                  <div className='actions'>
                    <button className='primary-btn' onClick={startChat}>
                      Message Buyer
                    </button>
                  </div>
                )}
              {(user?.role === ROLES.supplier ||
                user?.role === ROLES.retailer) &&
              user?._id !== tender?.user_id?._id &&
              !quotes?.length ? (
                <div className='actions'>
                  <button className='primary-btn' onClick={handleSubmit}>
                    Submit a Quote
                  </button>
                </div>
              ) : (
                <></>
              )}
              {(user?.role === ROLES.supplier ||
                user?.role === ROLES.retailer ||
                user?.role === !hideTabs) &&
              tender?.user_id._id !== user?._id &&
              quotes?.length &&
              !quotes[0]?.fundsTransferred ? (
                <div className='actions'>
                  <button
                    className={`primary-btn ${
                      checkInvoiceDisabled() && 'disabled-btn'
                    }`}
                    onClick={handleUploadInvoice}
                    disabled={checkInvoiceDisabled()}
                  >
                    Upload Invoice
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>

            {quotes?.length &&
            quotes[0]?.fundsTransferred &&
            tender.status !== TENDER_STATUSES.completed &&
            tender.status !== TENDER_STATUSES.dispute ? (
              <div className='actions d-flex justify-content-end'>
                <button
                  className={`danger-btn mx-1 my-1`}
                  onClick={() => setDisputeModal(true)}
                >
                  Mark as Dispute
                </button>
                {(user?.role === ROLES.supplier ||
                  user?.role === ROLES.retailer) &&
                  location.pathname.startsWith(
                    `/${user?.role}/tender-detail/${id}/Quote%20Details`
                  ) && (
                    <button
                      className={`primary-btn mx-1 my-1`}
                      onClick={() => setModal(true)}
                    >
                      Delivery Complete
                    </button>
                  )}
              </div>
            ) : (
              <></>
            )}

            {tender?.status === TENDER_STATUSES.completed &&
            !checkIfReviewAdded() ? (
              <div className='actions d-flex justify-content-end'>
                <button
                  className={`border-green-btn`}
                  onClick={() => setReviewModal(true)}
                >
                  Add Review
                </button>
              </div>
            ) : (
              <></>
            )}

            {quotes?.length && tender.status === TENDER_STATUSES.dispute ? (
              <div className='actions d-flex justify-content-end'>
                <button
                  className={`blue-border-btn ${
                    checkDisputeDisabled() && 'disabled-btn'
                  }`}
                  disabled={checkDisputeDisabled()}
                  onClick={() => setResolveDisputeModal(true)}
                >
                  Resolve Dispute
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {!hideTabs && !showSpinner && user._id === tender.user_id._id && (
        <TenderDetailSections
          role={user.role}
          active={tab ? tab : TENDER_DETAIL_TABS.tenderDetail}
          setActive={handleTabClick}
          isQuoteSubmitted={quotes?.length}
        />
      )}

      {!showSpinner && showTabComponent()}
      <SimpleModal
        show={showModal}
        handleClose={() => setModal(false)}
        noClick={() => setModal(false)}
        yesClick={handleDeliveryComplete}
        title={MARK_COMPLETE_MODAL_TITLE}
        message={MARK_COMPLETE_MODAL_MSG}
        img={`${IMAGES_ASSESTS_PATH}/complete-tender.svg`}
        primary
      />
      <OneButtonModal
        show={showSubmitModal}
        handleClose={() => setSubmitModal(false)}
        btn={'Go to Billing History'}
        title={MARK_COMPLETE_DONE_MODAL_TITLE}
        message={MARK_COMPLETE_DONE_MODAL_MSG}
        click={() => navigate(`/${user?.role}/billing-history`)}
      />

      <SimpleModal
        show={disputeModal}
        handleClose={() => setDisputeModal(false)}
        noClick={() => setDisputeModal(false)}
        yesClick={handleDispute}
        title={MARK_DISPUTE_MODAL_TITLE}
        message={MARK_DISPUTE_MODAL_MSG}
        img={`${IMAGES_ASSESTS_PATH}/Dispute-Modal.svg`}
        danger
      />
      <OneButtonModal
        show={doneDisputeModal}
        handleClose={() => setDoneDisputeModal(false)}
        btn={'View Dispute'}
        title={MARK_DISPUTE_DONE_MODAL_TITLE}
        message={MARK_DISPUTE_DONE_MODAL_MSG}
        click={() => navigate(`/${user?.role}/my-disputes`)}
      />

      <ResolveDisputeModal
        show={resolveDisputeModal}
        handleClose={() => setResolveDisputeModal(false)}
        cancel={() => setResolveDisputeModal(false)}
        handleResolve={handleResolve}
      />
      <OneButtonModal
        show={resolvedModal}
        handleClose={() => setResolvedModal(false)}
        btn={'View Tender'}
        title={'Dispute Resolved'}
        message={'Your Dispute has successfully been resolved.'}
        click={handleResolved}
      />

      <AddReviewModal
        show={reviewModal}
        handleClose={() => setReviewModal(false)}
        cancel={() => setReviewModal(false)}
        submit={handleReviewSubmit}
        user={user}
      />
      <OneButtonModal
        show={reviewDoneModal}
        handleClose={() => setReviewDoneModal(false)}
        btn={'Close'}
        title={'Review Added'}
        message={'Your Review has successfully been submitted.'}
        click={() => setReviewDoneModal(false)}
      />
    </div>
  );
};

export default TenderDetail;
