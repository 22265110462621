import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import PasswordField from '../../common/password-field/PasswordField';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../store/slices/userSlice';
import { ROLES, SUSPEND_MSG, errorToast } from '../../../constants';
import { toast } from 'react-hot-toast';
import SpinnerLoader from '../../common/spinner/Spinner';

function Login() {
  const [showSpinner, setSpinner] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(userActions.loginRequest(data));
  };

  useEffect(() => {
    setSpinner(user.loading);
    if (user.user?.access_token) {
      switch (user.user?.role) {
        case ROLES.farmer:
          navigate('/farmer/tender-listing/Active');
          break;
        case ROLES.supplier:
          navigate('/supplier/tender-listing');
          break;
        case ROLES.retailer:
          navigate('/retailer/tender-listing');
          break;
        default:
      }
    }
    if (user.error) {
      toast.error(user.error, errorToast);
      user.error === SUSPEND_MSG && navigate('/account-suspended');
    }

    return () => {
      dispatch(userActions.clearErrors());
    };
  }, [
    user.loading,
    user.error,
    user.user?.role,
    user.user?.access_token,
    navigate,
    dispatch,
  ]);

  const navigateSignUp = () => {
    dispatch(userActions.clearUser());
    navigate(`/signup`);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container fluid className='mt-5'>
        <div className='auth-box'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className='mb-4'>
              <Col sm='12'>
                <h2 className='text-center'>Login</h2>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col sm='12'>
                <label className='mb-2 d-block'>
                  Email <span className='required-color'>*</span>
                </label>
                <input
                  placeholder='Enter your email'
                  className={`form-input w-100 ${
                    errors['email'] && 'invalid-input'
                  }`}
                  type='email'
                  {...register('email', { required: true })}
                />
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col sm='12'>
                <label className='mb-2 d-block'>
                  Password <span className='required-color'>*</span>
                </label>
                <PasswordField
                  placeholder='Enter password'
                  className={`form-input w-100 ${
                    errors['password'] && 'invalid-input'
                  }`}
                  register={register}
                  name='password'
                  validate={false}
                />
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col sm='12'>
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    <span>
                      <input
                        className='primary-checkbox'
                        type='checkbox'
                        {...register('remember_me')}
                      />
                    </span>{' '}
                    <span className='align-text-bottom px-2'>Remember Me</span>
                  </div>
                  <div>
                    <u>
                      <Link className='primary-color' to={`/forgot-password`}>
                        Forgot Password?
                      </Link>
                    </u>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col sm='12'>
                <Button type='submit' className='primary-btn w-100'>
                  Login
                </Button>
              </Col>
            </Row>

            <Row>
              <Col sm='12'>
                <div className='text-center secondary-text-color '>
                  Don't have an account?{' '}
                  <u>
                    <Link className='primary-color' onClick={navigateSignUp}>
                      Sign Up
                    </Link>
                  </u>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </Container>
    </>
  );
}

export default Login;
