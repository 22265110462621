import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CreateQuote.css';
import { Row, Col, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Dropzone from '../../common/drop-zone/DropZone';
import CustomDatePicker from '../../common/date-picker/DatePicker';
import { quoteActions } from '../../../store/slices/quoteSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES, TENDER_DETAIL_TABS, TRANSPORT } from '../../../constants';
import { getUploadedFilesPreview } from '../../../services/utilService';
import { uploadFiles } from '../../../services/s3Service';
import * as _ from 'lodash';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import CurrencyInput from 'react-currency-input-field';

const QuoteForm = ({ isUploaded, id, edit, cancel, setSpinner }) => {
  const quote = useSelector((state) => state.quote.quote);
  const user = useSelector((state) => state.user.user);
  const tenderState = useSelector((state) => state.tender);
  const [postedBy, setPostedBy] = useState(
    tenderState.response?.tender?.user_id?.role
  );
  const [files, setFiles] = useState(
    quote?.files
      ? quote?.files
      : quote?.attachments
      ? getUploadedFilesPreview(quote.attachments)
      : []
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      ...quote,
      transport: quote?.transport ? quote?.transport : '',
      completion_date: quote?.completion_date
        ? new Date(quote?.completion_date)
        : '',
      quote_validity: quote?.quote_validity
        ? new Date(quote?.quote_validity)
        : '',
    },
  });

  const onSubmit = async (data) => {
    if (edit) {
      let newQuote = _.cloneDeep(data);
      setSpinner(true);
      const newFiles = files.filter((file) => file.name);
      if (newFiles.length) {
        const newAttachments = await uploadFiles(newFiles, 'quotes/');
        newQuote.attachments = [...newQuote.attachments, ...newAttachments];
      }
      dispatch(
        quoteActions.updateQuoteRequest({
          ...newQuote,
          price: parseFloat(newQuote?.price),
        })
      );
      setSpinner(false);
    } else {
      dispatch(
        quoteActions.persistQuote({
          ...data,
          files,
          price: data?.price ? parseFloat(data?.price) : 0,
        })
      );
      navigate(`/${user?.role}/review-quote`);
    }
  };

  const filesChange = (files) => {
    setFiles(files);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${!edit && 'footer-space'}`}>
          <Row className='mb-5'>
            <Col sm='12'>
              <h2>{edit ? 'Edit Offer' : 'Quote Details'}</h2>
              <h6 className='secondary-text-color'>
                {edit
                  ? 'Here you can update you offer details.'
                  : 'Please fill out the following details.'}
              </h6>
            </Col>
          </Row>
          {postedBy !== ROLES.retailer && (
            <Row className='mb-4'>
              <label className='mb-2 d-block'>
                Transport Option <span className='required-color'>*</span>
              </label>
              <Col sm='12'>
                <label>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value='Delivery'
                    {...register('transport', { required: true })}
                  />
                  Deliver to Farm
                </label>

                <label className='px-3'>
                  <input
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value='Pickup'
                    {...register('transport', { required: true })}
                  />
                  Pickup
                </label>
              </Col>
            </Row>
          )}
          {postedBy === ROLES.retailer && (
            <Row className='mb-4'>
              <label className='mb-2 d-block'>
                Transport <span className='required-color'>*</span>
              </label>
              <textarea
                placeholder='ex location i.e. ex Adelaide Perth, Geelong etc. Or any other shipping instructions that are applicable'
                className={`form-input w-100 ${
                  errors['quote_shipping'] && 'invalid-input'
                }`}
                rows={'5'}
                {...register('quote_shipping', { required: true })}
              />
            </Row>
          )}
          {postedBy !== ROLES.retailer && (
            <Row className='mb-4'>
              {watch('transport') === TRANSPORT.pickup && (
                <Col sm='12' md='6'>
                  <label className='mb-2 d-block'>
                    Pickup Location
                    <span className='required-color'>*</span>
                  </label>
                  <Controller
                    control={control}
                    rules={
                      postedBy !== ROLES.retailer ? { required: true } : ''
                    }
                    name='post_code'
                    render={({ field: { onChange } }) => (
                      <ReactGoogleAutocomplete
                        placeholder='Enter Location'
                        className={`form-input w-100 ${
                          errors['post_code'] && 'invalid-input'
                        }`}
                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        onPlaceSelected={(place) => {
                          onChange(place.formatted_address);
                        }}
                        defaultValue={quote?.post_code || ''}
                        options={{
                          types: ['geocode', 'establishment'],
                          componentRestrictions: { country: 'aus' },
                        }}
                      />
                    )}
                  />
                </Col>
              )}
              <Col sm='12' md='6'>
                <label className='mb-2 d-block'>
                  {watch('transport')} Date
                  <span className='required-color'>*</span>
                </label>
                <CustomDatePicker
                  className={`form-input w-100 ${
                    errors['completion_date'] && 'invalid-input'
                  }`}
                  name='completion_date'
                  placeholder={'Select date'}
                  control={control}
                  setValue={setValue}
                  required={true}
                />
              </Col>
            </Row>
          )}
          <Row className='mb-4'>
            <Col sm='12'>
              <div className='d-flex align-items-center justify-content-between'>
                <label className='mb-2 d-inline-block'>
                  Order/Product Details
                </label>
                <small className='secondary-text-color'>(Optional)</small>
              </div>
              <textarea
                placeholder='Enter details'
                className={`form-input w-100 ${
                  errors['details'] && 'invalid-input'
                }`}
                rows={'5'}
                {...register('details')}
              />
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col sm='12' md='6'>
              <label className='mb-2 d-block'>
                Total Price (Includes GST){' '}
                <span className='required-color'>*</span>
              </label>
              <Controller
                control={control}
                rules={{ required: true }}
                name='price'
                render={({ field: { onChange } }) => (
                  <CurrencyInput
                    className={`form-input w-100 ${
                      errors['price'] && 'invalid-input'
                    }`}
                    prefix='$'
                    placeholder='Enter amount'
                    defaultValue={quote?.price}
                    decimalsLimit={2}
                    onValueChange={onChange}
                  />
                )}
              />
            </Col>
          </Row>
          {/*****************  New Field ****************/}
          <Row className='mb-4'>
            <Col sm='12' md='6' className='pt-3 pt-md-0'>
              <label className='mb-2 d-block'>
                Quote Valid until <span className='required-color'>*</span>
              </label>
              <CustomDatePicker
                className={`form-input w-100 ${
                  errors['quote_validity'] && 'invalid-input'
                }`}
                name='quote_validity'
                placeholder={'Select date'}
                control={control}
                setValue={setValue}
                required={true}
              />
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col sm='12'>
              <div className='d-flex align-items-center justify-content-between'>
                <label className='mb-2 d-inline-block'>
                  Payment Terms <span className='required-color'>*</span>
                </label>
              </div>
              <div className='note-color'>
                Note: Include details about Non-refundable deposits, Interest
                free periods as well as any other payment expectations
              </div>
              <textarea
                placeholder='Enter details'
                className={`form-input w-100 ${
                  errors['payment_terms'] && 'invalid-input'
                }`}
                rows={'5'}
                {...register('payment_terms', { required: true })}
              />
            </Col>
          </Row>
          <Row>
            <div className='d-flex align-items-center justify-content-between'>
              <label className='mb-2 d-inline-block'>Attach Files</label>
              <small className='secondary-text-color'>(Optional)</small>
            </div>
            <Col sm='12'>
              <Dropzone
                onChange={filesChange}
                value={files}
                isUploaded={isUploaded}
              />
            </Col>
          </Row>
        </div>
        {!edit ? (
          <div className='tender-footer'>
            <div className='px-3 d-flex align-items-center justify-content-between'>
              <div>
                <Button
                  onClick={() =>
                    navigate(
                      `/${user?.role}/tender-detail/${id}/${TENDER_DETAIL_TABS.tenderDetail}`
                    )
                  }
                  type='button'
                  className='border-btn back-btn'
                >
                  <div className='d-flex align-items-center'>
                    <span>
                      <img
                        className='me-2'
                        alt='add'
                        src='/assets/icons/supplier/Arrow-Back.svg'
                      />
                    </span>
                    <span>Back</span>
                  </div>
                </Button>
              </div>
              <div>
                <Button type='submit' className='primary-btn'>
                  Review Quote
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className='mt-4'>
            <div className='d-flex align-items-center justify-content-end'>
              <Button
                onClick={cancel}
                type='button'
                className='border-btn me-3'
              >
                Cancel
              </Button>
              <Button type='submit' className='primary-btn'>
                Update Offer
              </Button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default QuoteForm;
