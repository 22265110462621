import React, { useEffect, useState } from 'react';
import './BillingHistoryFarmer.css';
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../../store/slices/quoteSlice';
import SpinnerLoader from '../../common/spinner/Spinner';
import { toast } from 'react-hot-toast';
import {
  BILLING_FILTERS,
  BILLING_TABLE_COLS,
  QUOTES_STATUSES,
  ROLES,
  TABLE_COL_CLASSES,
  TENDER_DETAIL_TABS,
  TENDER_STATUSES,
  defaultPageMeta,
  errorToast,
  generateTableHeader,
} from '../../../constants';
import Pages from '../../common/pagination/Pagination';
import {
  downloadFiles,
  generateUniqueId,
  getStatusClass,
  getTenderID,
  numberToCurrency,
} from '../../../services/utilService';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { tenderActions } from '../../../store/slices/tenderSlice';
import BillingModal from '../../modals/billing-modal/BillingModal';
import DropDownIcon from '../../../assets/icons/arrow-down-up.svg';

function BillingHistoryFarmer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quote = useSelector((state) => state.quote);
  const user = useSelector((state) => state.user);
  const [showSpinner, setSpinner] = useState(true);
  const [quotes, setQuotes] = useState([]);
  const [meta, setMeta] = useState(defaultPageMeta);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState(BILLING_FILTERS);
  const [search, setSearch] = useState();
  const [sort, setSort] = useState('');
  const [mobileViewIndex, setMobileViewIndex] = useState(-1);

  useEffect(() => {
    dispatch(
      quoteActions.getFarmerBillsRequest({
        size: defaultPageMeta.per_page,
        pageNo: defaultPageMeta.current_page,
        search: search,
        min: filters.minAmount,
        to: filters.maxAmount,
        toDate: filters.toDate,
        fromDate: filters.fromDate,
        status: [
          ...(filters.approved ? [QUOTES_STATUSES.accepted] : []),
          ...(filters.awaiting_approval ? [QUOTES_STATUSES.sent] : []),
          ...(filters.cancelled ? [QUOTES_STATUSES.rejected] : []),
        ],
        sort,
      })
    );
  }, [dispatch, filters, search, sort]);

  useEffect(() => {
    setSpinner(quote.loading);
    if (quote.error) {
      toast.error(quote.error, errorToast);
    }
    if (quote.response) {
      setQuotes(quote.response.data || []);
      setMeta(quote.response.meta);
      setSpinner(false);
    }
    return () => {
      dispatch(quoteActions.clearErrors());
    };
  }, [dispatch, quote.error, quote.loading, quote.response]);

  const pageClick = (pageNo) => {
    setMeta((prev) => {
      return { ...prev, current_page: pageNo };
    });
    dispatch(
      quoteActions.getFarmerBillsRequest({ size: meta.per_page, pageNo })
    );
  };

  const pageSize = (size) => {
    setMeta((prev) => {
      return { ...prev, per_page: size };
    });
    dispatch(quoteActions.getFarmerBillsRequest({ size, pageNo: 1 }));
  };

  const handleClick = (quote) => {
    dispatch(tenderActions.persistTender(quote.tender_id));
    navigate(
      `/${user?.user?.role}/tender-detail/${quote._id}/${TENDER_DETAIL_TABS.hired}`
    );
  };

  const handleDownload = async (obj) => {
    setSpinner(true);
    await downloadFiles([obj?.invoice?.url]);
    setSpinner(false);
  };

  const handleFilterModal = () => {
    setShowModal(!showModal);
  };

  const handleSearch = (e) => {
    if (e.code === 'Enter') {
      dispatch(
        quoteActions.getFarmerBillsRequest({
          size: defaultPageMeta.per_page,
          pageNo: defaultPageMeta.current_page,
          search: search,
        })
      );
    }
  };

  const handleFilters = (filters) => {
    setFilters(filters);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <BillingModal
          showModal={showModal}
          handleClose={handleFilterModal}
          setFilters={handleFilters}
          filters={filters}
        />
      )}
      <SpinnerLoader showSpinner={showSpinner} />
      <Container className='mt-4'>
        {user?.user?.role !== ROLES.retailer && <h5>Billing History</h5>}
        <div className='table-container mt-5'>
          <div className='search-filter mx-2 m-3 p-3 d-flex flex-wrap justify-content-between '>
            <div className='input-group w-50'>
              <div
                className='input-group-prepend '
                role='button'
                onClick={handleSearch}
              >
                <span
                  className='input-group-text icon'
                  id='inputGroup-sizing-lg'
                >
                  <img
                    src='/assets/icons/supplier/Search.svg'
                    alt=''
                    className=''
                  />
                </span>
              </div>
              <input
                type='text'
                className='form-control search-input'
                aria-label='Large'
                aria-describedby='inputGroup-sizing-sm'
                placeholder='Search transactions here'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleSearch}
              />
            </div>
            <div className='d-flex'>
              <button
                className='btn btn-light d-flex border border-dark me-2'
                onClick={handleFilterModal}
              >
                <img src='/assets/icons/supplier/Filters.svg' alt='filter' />
                <span className='mt-1 mx-2'>Filter</span>
              </button>
              <div className='dropdown'>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='success'
                    id='dropdown-basic'
                    className='btn btn-light sort-by-btn'
                  >
                    <img src={DropDownIcon} alt='sort' className='me-2 my-2' />
                    <small>Sort by</small>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setSort('completion_date')}>
                      Supply Date
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSort('price')}>
                      Amount
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className='md-hide farmer-table-header '>
            {generateTableHeader(BILLING_TABLE_COLS)}
          </div>
          {quotes && quotes?.length ? (
            <>
              {quotes.map((quote) =>
                quote.quotes.map((q) => (
                  <div className='m1-2 p-3' key={generateUniqueId()}>
                    <div className='md-hide'>
                      <Row>
                        <Col>
                          <div className={TABLE_COL_CLASSES}>
                            #{getTenderID(quote?._id)}
                          </div>
                        </Col>
                        <Col>
                          <div className={TABLE_COL_CLASSES}>
                            {quote?.title}
                          </div>
                        </Col>
                        <Col>
                          <div className={TABLE_COL_CLASSES}>
                            {moment(q?.completion_date).format('ddd, MMM DD')}
                          </div>
                        </Col>
                        <Col>
                          <div className={TABLE_COL_CLASSES}>
                            {numberToCurrency(q?.price)}
                          </div>
                        </Col>
                        <Col>
                          <div className={TABLE_COL_CLASSES}>
                            <button
                              className={`px-3 status-small ${getStatusClass(
                                quote.status === TENDER_STATUSES.active
                                  ? TENDER_STATUSES.pending
                                  : q?.status
                              )}`}
                            >
                              {q?.status}
                            </button>
                          </div>
                        </Col>
                        <Col>
                          <div className='w-100 d-flex justify-content-end'>
                            <Button
                              className='btn-light px-1 py-1'
                              onClick={() => handleClick(quote)}
                              type='button'
                            >
                              <img
                                className='ms-1'
                                src={'/assets/icons/view.svg'}
                                alt='arrow'
                              />
                            </Button>
                            <Button
                              className='btn-light px-1 py-1'
                              onClick={() => handleDownload(q)}
                              type='button'
                            >
                              <img
                                className='ms-1'
                                src={'/assets/icons/download.svg'}
                                alt='arrow'
                              />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className='md-show '>
                      <div className='md-show mobile-responsive'>
                        <div className='mobile-view-title'>
                          <div className='tender-title-mobile'>
                            {quote?.title}
                          </div>
                          <div className='dropdown-button mx-2 '>
                            <img
                              src='/assets/icons/Dropdown-upward.svg'
                              alt=''
                              role='button'
                              onClick={(e) => setMobileViewIndex(-1)}
                              style={
                                q?._id !== mobileViewIndex
                                  ? { display: 'none' }
                                  : { display: 'block' }
                              }
                            />
                            <img
                              src='/assets/icons/Dropdown-downward.svg'
                              alt=''
                              role='button'
                              onClick={(e) => setMobileViewIndex(q._id)}
                              style={
                                q?._id == mobileViewIndex
                                  ? { display: 'none' }
                                  : { display: 'block' }
                              }
                            />
                          </div>
                        </div>
                        <div
                          className='mobile-view-value'
                          key={q?._id}
                          style={
                            q?._id == mobileViewIndex
                              ? { display: 'block' }
                              : { display: 'none' }
                          }
                        >
                          <div className='mobileView'>
                            <div className='title-text'>Tender Number</div>
                            <div className='title-value'>#{quote?._id}</div>
                          </div>

                          <div className='mobileView'>
                            <div className='title-text'>Date</div>
                            <div className='title-value'>
                              {moment(q?.completion_date).format('ddd, MMM DD')}
                            </div>
                          </div>

                          <div className='mobileView'>
                            <div className='title-text'>Amount</div>
                            <div className='title-value'>
                              {numberToCurrency(q?.price)}
                            </div>
                          </div>

                          <div className='mobileView'>
                            <div className='title-text'>Status</div>
                            <div className=''>
                              <button
                                className={`px-3 status-small ${getStatusClass(
                                  q.status === TENDER_STATUSES.active
                                    ? TENDER_STATUSES.pending
                                    : q?.status
                                )}`}
                              >
                                {q?.status}
                              </button>
                            </div>
                          </div>

                          <div className='mobileView'>
                            <div className='title-text'>Actions</div>
                            <div className='w-100 d-flex justify-content-end'>
                              <Button
                                className='btn-light px-1 py-1'
                                onClick={() => handleClick(quote)}
                                type='button'
                              >
                                <img
                                  className='ms-1'
                                  src={'/assets/icons/view.svg'}
                                  alt='arrow'
                                />
                              </Button>
                              <Button
                                className='btn-light px-1 py-1'
                                onClick={() => handleDownload(q)}
                                type='button'
                              >
                                <img
                                  className='ms-1'
                                  src={'/assets/icons/download.svg'}
                                  alt='arrow'
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
              <Pages
                meta={meta}
                pageClick={pageClick}
                pageSize={pageSize}
                defaultPage={30}
              />
            </>
          ) : (
            <div className='p-5'>No Records Found</div>
          )}
        </div>
      </Container>
    </>
  );
}

export default BillingHistoryFarmer;
