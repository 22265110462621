import React from 'react';
import './Pagination.css';
import { Container, Pagination } from 'react-bootstrap';
import { generateUniqueId } from '../../../services/utilService';
import { IMAGES_ASSESTS_PATH } from '../../../constants';

const Pages = ({ meta, pageClick, pageSize, defaultPage }) => {
  const items = () => {
    let active = meta?.current_page || 1;
    let items = [];
    items.push(
      <Pagination.Item
        className="page-icon-div"
        key={generateUniqueId()}
        onClick={() => pageClick(active - 1)}
        disabled={active - 1 === 0}
        active={active - 1 !== 0}
        role="button"
      >
        <img
          src={`${IMAGES_ASSESTS_PATH}/backward${
            active - 1 === 0 ? '' : '-white'
          }.svg`}
          className="page-icon"
          alt="backward"
        />
      </Pagination.Item>
    );
    for (let number = 1; number <= meta?.total_pages; number++) {
      items.push(
        <Pagination.Item
          key={generateUniqueId()}
          onClick={() => pageClick(number)}
          active={number === active}
        >
          {number}
        </Pagination.Item>
      );
    }
    items.push(
      <Pagination.Item
        className="page-icon-div"
        key={generateUniqueId()}
        onClick={() => pageClick(active + 1)}
        disabled={active === meta?.total_pages}
        active={active !== meta?.total_pages}
        role="button"
      >
        <img
          src={`${IMAGES_ASSESTS_PATH}/forward${
            active === meta?.total_pages ? '' : '-white'
          }.svg`}
          className="page-icon"
          alt="forward"
        />
      </Pagination.Item>
    );
    return items;
  };

  const handleChange = (e) => {
    pageSize(parseInt(e.target.value));
  };

  const dropdown = () => {
    return (
      <select
        defaultValue={defaultPage || 30}
        onChange={handleChange}
        className="entries-dropdown mx-1"
      >
        <option value={1}>1</option>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={30}>30</option>
      </select>
    );
  };

  return (
    <Container className="mt-4">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          Show {dropdown()} of {meta?.total_count} entries
        </div>
        <div>
          <Pagination size="md">{items()}</Pagination>
        </div>
      </div>
    </Container>
  );
};

export default Pages;
