import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import './AttachmentCard.css';
import {
  bytesToSize,
  downloadFiles,
  getFileNameFromURL,
  getFilePreview,
} from '../../../services/utilService';

const AttachmentCard = ({ attachment, setSpinner, local }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const getPreview = async () => {
      const url = local
        ? attachment.preview
        : await getFilePreview(attachment, false);
      setUrl(url);
    };
    getPreview();
  }, [attachment, local]);

  const handleDownload = async () => {
    setSpinner(true);
    const urls = [attachment.url];
    await downloadFiles(urls, false);
    setSpinner(false);
  };

  return (
    <Col xl="3" lg="4" md="6" sm="12" className="p-1">
      <div className="file-card">
        <div className="d-flex align-items-center">
          <div>
            <div className="d-flex align-items-center justify-content-center me-3">
              <img src={url} alt="attachment" className="file-card-img" />
            </div>
          </div>
          <div className="w-100">
            <div className="ms-1 h-100 d-flex align-items-center justify-content-start">
              <div>
                <h5>{getFileNameFromURL(attachment.url || attachment.name)}</h5>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <small className="secondary-text-color">
                {attachment?.size ? bytesToSize(attachment?.size) : ''}
              </small>
              {!local && (
                <u
                  onClick={handleDownload}
                  role="button"
                  className="primary-color me-3"
                >
                  Download
                </u>
              )}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default AttachmentCard;
