import React, { useState } from 'react';
import './Hired.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  ACCEPTED_INVOICE_MODAL_MSG,
  ACCEPTED_INVOICE_MODAL_TITLE,
  ACCEPT_INVOICE_MODAL_MSG,
  ACCEPT_INVOICE_MODAL_TITLE,
  FUND_TRANSFERRED_MSg,
  FUND_TRANSFERRED_TITLE,
  IMAGES_ASSESTS_PATH,
  PAYMENT_OPTIONS,
  TENDER_DETAIL_TABS,
} from '../../../constants';
import TenderDetailHeader from '../../common/tender-detail-header/TenderDetailHeader';
import { useDispatch, useSelector } from 'react-redux';
import AboutUser from '../../common/about-user/AboutUser';
import TaxInvoice from '../../supplier/quote-detail-tab/TaxInvoice';
import SupplierOffer from '../../common/supplier-offer/SupplierOffer';
import { quoteActions } from '../../../store/slices/quoteSlice';
import SimpleModal from '../../modals/simple-modal/SimpleModal';
import { useNavigate } from 'react-router-dom';
import OneButtonModal from '../../modals/one-button-modal/OneButtonModal';
import { getStatusClass, getTenderID } from '../../../services/utilService';

function Hired({ tender, quote, setSpinner, isHired }) {
  const [approveModal, setApproveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [doneModal, setDoneModal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateTender = () => {
    setApproveModal(false);
    setDoneModal(false);
    window.location.reload();
  };
  const showTenderDetails = () => {
    setShowModal(true);
    setSpinner(true);
    dispatch(
      quoteActions.updateQuoteRequest({
        fundsTransferred: true,
        _id: quote?._id,
      })
    );
    setSpinner(false);
  };

  const handleClose = () => {
    setShowModal(!showModal);
    navigate(
      `/${user?.role}/tender-detail/${tender._id}/${TENDER_DETAIL_TABS.tenderDetail}`
    );
  };

  const onApprove = () => {
    setSpinner(true);
    dispatch(
      quoteActions.updateQuoteRequest({
        invoice: {
          ...quote?.invoice,
          isApproved: true,
        },
        invoiceApproved: true,
        _id: quote?._id,
      })
    );
    setApproveModal(false);
    setDoneModal(true);
    setSpinner(false);
  };

  const getStatus = () => {
    if (!quote?.fundsTransferred) {
      return 'Payment Outstanding';
    } else if (quote?.fundsTransferred && !quote?.isReceived) {
      return 'Pending Verification';
    } else {
      return 'Payment Received';
    }
  };

  return (
    <>
      <Container fluid>
        {isHired ? (
          <>
            <div className='my-4 auth-box tender-detail-box'>
              <TenderDetailHeader tender={tender} />
            </div>

            <div className='my-4 auth-box tender-detail-box no-bg'>
              <Row>
                <Col md='12'>
                  <AboutUser user={quote?.user_id} tender={tender} />
                </Col>
                <Col md='12' className='mt-4'>
                  <TaxInvoice
                    quote={quote}
                    user={user}
                    onApprove={() => setApproveModal(true)}
                  />
                </Col>
              </Row>
            </div>

            {tender?.payment_option === PAYMENT_OPTIONS.THROUGH_AG &&
              quote?.invoice?.isApproved && (
                <div className='my-4 auth-box tender-detail-box'>
                  <div className='d-flex align-items-center'>
                    <h5 className='mb-0 bold-text'>Payment Status</h5>
                    <button
                      className={`ms-4 px-4 ${getStatusClass(getStatus())}`}
                    >
                      {getStatus()}
                    </button>
                  </div>
                  {!quote?.fundsTransferred && (
                    <>
                      <div className='mt-4'>
                        <div className='secondary-text-color text-center'>
                          Please pay iinputs.com the amount shown on the
                          supplier's tax invoice
                        </div>
                        <div className='d-flex justify-content-center mt-4'>
                          <div>
                            <div className='d-flex align-items-center justify-content-between my-2'>
                              <h5 className='secondary-text-color me-5'>
                                Company Name:
                              </h5>
                              <h5>iinputs.com</h5>
                            </div>
                            <div className='d-flex align-items-center justify-content-between my-2'>
                              <h5 className='secondary-text-color me-5'>
                                BSB Number:
                              </h5>
                              <h5>015 462</h5>
                            </div>
                            <div className='d-flex align-items-center justify-content-between my-2'>
                              <h5 className='secondary-text-color me-5'>
                                Account Number:
                              </h5>
                              <h5>333 333 487</h5>
                            </div>
                            <div className='d-flex align-items-center justify-content-between my-2'>
                              <h5 className='secondary-text-color me-5'>
                                Reference Description:
                              </h5>
                              <h5>#{getTenderID(tender?._id)}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='w-100 d-flex align-items-center justify-content-end'>
                        <Button
                          onClick={showTenderDetails}
                          className='primary-btn'
                        >
                          Funds Transferred
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}

            <SupplierOffer quote={quote} setSpinner={setSpinner} />
          </>
        ) : (
          <div className='auth-box'>
            <div className='w-100 d-flex justify-content-center'>
              <img src={`${IMAGES_ASSESTS_PATH}/hired-none.svg`} alt='none' />
            </div>
            <div>
              <h3 className='mt-5 text-center'>None Found</h3>
              <div className='mt-3 text-center secondary-text-color'>
                Kindly accept the quote first to view the hired person
              </div>
            </div>
          </div>
        )}
      </Container>
      <SimpleModal
        show={approveModal}
        handleClose={() => setApproveModal(false)}
        noClick={() => setApproveModal(false)}
        yesClick={onApprove}
        title={ACCEPT_INVOICE_MODAL_TITLE}
        message={ACCEPT_INVOICE_MODAL_MSG}
        img={`${IMAGES_ASSESTS_PATH}/Quote-large.svg`}
        primary
      />
      <OneButtonModal
        show={doneModal}
        handleClose={() => setDoneModal(false)}
        img={`${IMAGES_ASSESTS_PATH}/Completed.svg`}
        title={ACCEPTED_INVOICE_MODAL_TITLE}
        message={ACCEPTED_INVOICE_MODAL_MSG}
        btn={'View Tender'}
        click={navigateTender}
      />
      <OneButtonModal
        title={FUND_TRANSFERRED_TITLE}
        show={showModal}
        handleClose={() => setShowModal(!showModal)}
        message={FUND_TRANSFERRED_MSg}
        btn={'View Tender Details'}
        click={handleClose}
      />
    </>
  );
}

export default Hired;
