import React from 'react';
import './CreatedModal.css';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import {
  IMAGES_ASSESTS_PATH,
  QUOTE_SUBMITTED_MSG,
  QUOTE_SUBMITTED_TITLE,
  SUBMIT_MODAL_TYPES,
  TENDER_MODAL_MSG,
  TENDER_MODAL_TITLE,
  TENDER_SUBMITTED_MSG,
  TENDER_SUBMITTED_TITLE,
} from '../../../constants';
import Lottie from 'react-lottie-player';
import loadingLoti from '../../../assets/loti/Loading.json';

function CreatedModal({ show, handleClose, loading, click, type }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="my-4 d-flex align-items-center justify-content-center">
            {loading ? (
              <Lottie
                className="loading-loti"
                loop
                animationData={loadingLoti}
                play
              />
            ) : (
              <img
                src={`${IMAGES_ASSESTS_PATH}/Completed.svg`}
                alt="success"
                className="modal-img"
              />
            )}
          </div>
          <div className="mb-4">
            <h4 className="text-center">
              {type === SUBMIT_MODAL_TYPES.tender
                ? loading
                  ? TENDER_MODAL_TITLE
                  : TENDER_SUBMITTED_TITLE
                : QUOTE_SUBMITTED_TITLE}
            </h4>
            <div className="text-center">
              {type === SUBMIT_MODAL_TYPES.tender
                ? loading
                  ? TENDER_MODAL_MSG
                  : TENDER_SUBMITTED_MSG
                : QUOTE_SUBMITTED_MSG}
            </div>
          </div>
          <div className="mb-4 d-flex align-items-center justify-content-center">
            <Button onClick={click} className="primary-btn">
              View {type === SUBMIT_MODAL_TYPES.tender ? 'Tender' : 'Quote'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreatedModal;
