import React from 'react';
import { Container } from 'react-bootstrap';
import './TenderListingSections.css';
import { STATUSES_TABS } from '../../../constants';
import { generateUniqueId } from '../../../services/utilService';

function TenderListingSections({ active, setActive }) {
  return (
    <Container className="no-bg mb-4 text-nowrap overflow-scroll tender-sections">
      {STATUSES_TABS.map((status) => (
        <div
          key={generateUniqueId()}
          className={`py-3 px-5 text-center secondary-text-color d-inline-block`}
          {...(active === status.title && {
            style: {
              color: status.color,
              borderBottom: `1px solid ${status.color}`,
            },
          })}
          onClick={() => setActive(status.title)}
          role="button"
        >
          {status.title}
        </div>
      ))}
    </Container>
  );
}

export default TenderListingSections;
