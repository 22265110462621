import {
  postCall,
  authorizedPostCall,
  authorizedGetCall,
  authorizedPutCall,
} from './APIsService';
import { getAccessToken, getRefreshToken } from './utilService';

export const isLoggedIn = () => {
  const token = getAccessToken();
  const refreshToken = getRefreshToken();
  if (!token || !refreshToken) {
    return false;
  }
  return true;
};

export const login = async (body) => {
  return new Promise((resolve, reject) => {
    postCall('/user/login', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const signup = async (body) => {
  return new Promise((resolve, reject) => {
    postCall('/user/register', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const refreshToken = async () => {
  console.log('calling refresh token: ', getRefreshToken());
  return new Promise((resolve, reject) => {
    postCall('/user/refresh_token', {
      token: getRefreshToken(),
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyLogin = async () => {
  return new Promise((resolve, reject) => {
    !getRefreshToken() && resolve(false);
    authorizedPostCall('/user/verify-login')
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgotPassword = (body) => {
  return new Promise((resolve, reject) => {
    postCall('/user/forgot_password', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const validateResetLink = (body) => {
  return new Promise((resolve, reject) => {
    postCall('/user/validate_pass_reset_link', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changePasswordWithLink = (body) => {
  return new Promise((resolve, reject) => {
    postCall('/user/reset_password', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserProfile = () => {
  return new Promise((resolve, reject) => {
    authorizedGetCall('/user/profile')
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateProfile = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall('/user/update', body)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updatePassword = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall('/user/update_password', body)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const contactUs = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/user/contact_us', body)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
