import React, { useState } from 'react';
import './Contact.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGES_ASSESTS_PATH, ROLES } from '../../../constants';
import { userActions } from '../../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import OneButtonModal from '../../modals/one-button-modal/OneButtonModal';

const Contact = () => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowMdal] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    dispatch(userActions.contactUs(data));
    setShowMdal(true);
  };

  const closeModal = () => {
    setShowMdal(false);

    if (user.role === ROLES.farmer) {
      navigate(`/farmer/tender-listing/Active`);
    }

    if (user.role === ROLES.supplier) {
      navigate(`/supplier/tender-listing`);
    }
  };

  return (
    <>
      <div className="container border contact-container my-4">
        <div className="contact-text my-3 mx-1 ">Contact Us</div>
        <div className="grey-text my-2 mx-1">
          Please fill out the form and we'll contact you.
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* subject */}
          <div className="my-4 mx-1">
            <label className="mb-2 d-block">
              Subject <span className="required-color">*</span>
            </label>
            <input
              placeholder="Enter subject"
              className={`form-input w-100 ${
                errors['name'] && 'invalid-input'
              }`}
              type="text"
              {...register('subject', { required: true })}
            />
          </div>
          {user?.role === ROLES.supplier && (
            <div className="my-4 mx-1 d-flex align-items-center w-100 ">
              <div className="w-50 mx-1">
                <label className="mb-2 d-block">Phone</label>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    validate: (value) =>
                      value ? isValidPhoneNumber(value) : true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      className={`form-input w-100 ${
                        errors['phone'] && 'invalid-input'
                      }`}
                      placeholder="(123) 123-1234"
                      value={value}
                      onChange={onChange}
                      defaultCountry="US"
                      id="phone"
                    />
                  )}
                />
              </div>
              <div className="w-50 mx-1">
                <label className="mb-2 d-block">
                  Email <span className="required-color">*</span>
                </label>
                <input
                  placeholder="Enter email"
                  className={`form-input w-100 ${
                    errors['name'] && 'invalid-input'
                  }`}
                  type="text"
                  {...register('email', { required: true })}
                />
              </div>
            </div>
          )}
          <div className="my-4 mx-1">
            <label className="mb-2 d-block">
              Message <span className="required-color">*</span>
            </label>
            <textarea
              placeholder="Enter message"
              cols={50}
              rows={8}
              className={`form-input w-100 ${
                errors['name'] && 'invalid-input'
              }`}
              type="text"
              {...register('message', { required: true })}
            />
          </div>
          <div className="d-flex justify-content-end align-items-center my-5">
            <button className="btn btn-default mx-2" onClick={closeModal}>
              Cancel
            </button>
            <button className="btn success-btn mx-2" type="submit">
              Send
            </button>
          </div>
        </form>
      </div>
      {showModal && (
        <OneButtonModal
          show={showModal}
          icon={`${IMAGES_ASSESTS_PATH}/Completed.svg`}
          title={'Message Sent'}
          message={
            'Your message has successfully been sent. You will be contacted soon'
          }
          btn={'Close'}
          click={closeModal}
        />
      )}
    </>
  );
};

export default Contact;
