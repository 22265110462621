import { getErrorMessageFromAxios } from '../../services/utilService';

export const tenderReducer = {
  createTenderRequest: (state, action) => {
    state.loading = true;
    state.tender = null;
    state.error = null;
  },
  getTenderQuotesRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  getRetailerQuotesRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  getDisputesRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  persistTender: (state, action) => {
    state.error = null;
    state.tender = { ...state.tender, ...action.payload };
  },
  persistClear: (state, action) => {
    state.tender = null;
  },
  getUserTenders: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  getOtherTenders: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  requestSuccess: (state, action) => {
    state.loading = false;
    state.error = null;
    state.response = action.payload;
  },
  requestFailure: (state, action) => {
    state.loading = false;
    state.error = getErrorMessageFromAxios(action.payload);
  },
  clearErrors: (state, action) => {
    state.error = null;
    state.response = null;
    state.loading = false;
  },
};
