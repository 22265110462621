import React, { useEffect, useMemo, useState } from 'react';
import './SignUp.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';
import {
  checkPassCase,
  checkPassChars,
  checkPassSpecialChars,
  errorToast,
  IMAGES_ASSESTS_PATH,
  ROLES,
  successToast,
} from '../../../constants';
import PasswordField from '../../common/password-field/PasswordField';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../store/slices/userSlice';
import { toast } from 'react-hot-toast';
import SpinnerLoader from '../../common/spinner/Spinner';
import farmerPdf from '../../../assets/documents/Farmers.pdf';
import supplierPdf from '../../../assets/documents/Suppliers T_Cs.docx.pdf';
import retailerPdf from '../../../assets/documents/Retail User - T&Cs.pdf';
import farmerIcon from '../../../assets/icons/Farmer.svg';
import selecedFarmer from '../../../assets/icons/selectedFarmer.svg';
import supplierIcon from '../../../assets/icons/Supplier.svg';
import selecedSupplier from '../../../assets/icons/SelectedSupplier.svg';
import storeIcon from '../../../assets/icons/StoreUnselected.svg';
import selecedStoreIcon from '../../../assets/icons/StoreSelected.svg';
import checkIcon from '../../../assets/icons/Check.svg';

function SignUp() {
  const [showSpinner, setSpinner] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: useMemo(() => {
      return { role: null };
    }, []),
  });

  useEffect(() => {
    setSpinner(user.loading);
    if (user.user?.email) {
      toast.success('User Regitered', successToast);
      navigate('/login');
    }
    user.error && toast.error(user.error, errorToast);

    return () => {
      dispatch(userActions.clearErrors());
    };
  }, [user.loading, user.error, user.user?.email, navigate, dispatch]);

  const onSubmit = (data) => {
    if (watch('password') !== watch('confirm_password')) {
      toast.error('Passwords do no Match!', errorToast);
      return;
    }
    dispatch(userActions.signupRequest(data));
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container fluid className='mt-5'>
        <div className='auth-box sign-up-box'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className='mb-4'>
              <Col sm='12'>
                <h1 className='text-center'>Sign Up As</h1>
              </Col>
            </Row>

            <div className='d-flex flex-row justify-content-center align-items-center gap-0 gap-sm-5 mb-5'>
              <div
                className={`d-flex flex-column justify-content-center align-items-center gap-2 ${
                  watch('role') === ROLES.farmer
                    ? 'role-box-active'
                    : 'role-box-inactive'
                }`}
                onClick={() => setValue('role', ROLES.farmer)}
              >
                {watch('role') === ROLES.farmer ? (
                  <img src={selecedFarmer} alt='farmer' />
                ) : (
                  <img src={farmerIcon} alt='farmer' />
                )}
                Farmer
                {watch('role') === ROLES.farmer ? (
                  <img src={checkIcon} alt='check' className='check-position' />
                ) : (
                  ''
                )}
              </div>
              <div
                className={`d-flex flex-column justify-content-center align-items-center gap-2 ${
                  watch('role') === ROLES.retailer
                    ? 'role-box-active'
                    : 'role-box-inactive'
                }`}
                onClick={() => setValue('role', ROLES.retailer)}
              >
                {watch('role') === ROLES.retailer ? (
                  <img src={selecedStoreIcon} alt='retailer' />
                ) : (
                  <img src={storeIcon} alt='retailer' />
                )}
                Retailer
                {watch('role') === ROLES.retailer ? (
                  <img src={checkIcon} alt='check' className='check-position' />
                ) : (
                  ''
                )}
              </div>
              <div
                className={`d-flex flex-column justify-content-center align-items-center gap-2 ${
                  watch('role') === ROLES.supplier
                    ? 'role-box-active'
                    : 'role-box-inactive'
                }`}
                onClick={() => setValue('role', ROLES.supplier)}
              >
                {watch('role') === ROLES.supplier ? (
                  <img src={selecedSupplier} alt='supplier' />
                ) : (
                  <img src={supplierIcon} alt='supplier' />
                )}
                Supplier
                {watch('role') === ROLES.supplier ? (
                  <img src={checkIcon} alt='check' className='check-position' />
                ) : (
                  ''
                )}
              </div>
            </div>

            {watch('role') !== null && (
              <div>
                <Row className='mb-4'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>
                      Contact Name <span className='required-color'>*</span>
                    </label>
                    <input
                      placeholder='Enter your name'
                      className={`form-input w-100 ${
                        errors['name'] && 'invalid-input'
                      }`}
                      type='text'
                      {...register('name', { required: true })}
                    />
                  </Col>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>
                      Email <span className='required-color'>*</span>
                    </label>
                    <input
                      placeholder='Enter your email'
                      className={`form-input w-100 ${
                        errors['email'] && 'invalid-input'
                      }`}
                      type='email'
                      {...register('email', { required: true })}
                    />
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Phone</label>
                    <Controller
                      name='phone'
                      control={control}
                      rules={{
                        validate: (value) =>
                          value ? isValidPhoneNumber(value) : true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          className={`form-input w-100 ${
                            errors['phone'] && 'invalid-input'
                          }`}
                          placeholder='(123) 123-1234'
                          value={value}
                          onChange={onChange}
                          defaultCountry='AU'
                          id='phone'
                        />
                      )}
                    />
                  </Col>
                  {(watch('role') === ROLES.supplier ||
                    watch('role') === ROLES.retailer) && (
                    <Col sm='12' md='6'>
                      <label className='mb-2 d-block'>
                        Address <span className='required-color'>*</span>
                      </label>
                      <input
                        placeholder='Enter your address'
                        className={`form-input w-100 ${
                          errors['address'] && 'invalid-input'
                        }`}
                        type='text'
                        {...register('address', { required: true })}
                      />
                    </Col>
                  )}
                </Row>

                {(watch('role') === ROLES.supplier ||
                  watch('role') === ROLES.retailer) && (
                  <Row className='mb-4'>
                    <Col sm='12' md='6'>
                      <label className='mb-2 d-block'>
                        Business Name <span className='required-color'>*</span>
                      </label>
                      <input
                        placeholder='Enter your business name'
                        className={`form-input w-100 ${
                          errors['business_name'] && 'invalid-input'
                        }`}
                        type='text'
                        {...register('business_name', { required: true })}
                      />
                    </Col>
                    <Col sm='12' md='6'>
                      <label className='mb-2 d-block'>
                        ABN <span className='required-color'>*</span>
                      </label>
                      <input
                        placeholder='Enter your ABN'
                        className={`form-input w-100 ${
                          errors['ABN'] && 'invalid-input'
                        }`}
                        type='text'
                        {...register('ABN', { required: true })}
                      />
                    </Col>
                  </Row>
                )}

                {(watch('role') === ROLES.supplier ||
                  watch('role') === ROLES.retailer) && (
                  <Row className='mb-4'>
                    <Col sm='12' md='6'>
                      <label className='mb-2 d-block'>Billing Address</label>
                      <input
                        placeholder='Enter your Billing Address'
                        className={`form-input w-100 ${
                          errors['ABN'] && 'invalid-input'
                        }`}
                        type='text'
                        {...register('billing_address')}
                      />
                    </Col>
                  </Row>
                )}

                <Row className='mb-4'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>
                      Password <span className='required-color'>*</span>
                    </label>
                    <PasswordField
                      placeholder='Enter password'
                      className={`form-input w-100 ${
                        errors['password'] && 'invalid-input'
                      }`}
                      register={register}
                      name='password'
                    />
                  </Col>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>
                      Confirm Password <span className='required-color'>*</span>
                    </label>
                    <PasswordField
                      placeholder='Re-enter password'
                      className={`form-input w-100 ${
                        errors['confirm_password'] && 'invalid-input'
                      }`}
                      register={register}
                      name='confirm_password'
                    />
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col sm='12'>
                    <div className='secondary-text-color'>
                      <span>
                        <img
                          src={
                            checkPassChars(
                              watch('password'),
                              watch('confirm_password')
                            )
                              ? `${IMAGES_ASSESTS_PATH}/tick-green.svg`
                              : `${IMAGES_ASSESTS_PATH}/tick-red.svg`
                          }
                          alt='tick'
                        />
                      </span>
                      <span className='px-2'>Must be 8 characters</span>
                    </div>
                  </Col>
                  <Col sm='12'>
                    <div className='secondary-text-color'>
                      <span>
                        <img
                          src={
                            checkPassSpecialChars(
                              watch('password'),
                              watch('confirm_password')
                            )
                              ? `${IMAGES_ASSESTS_PATH}/tick-green.svg`
                              : `${IMAGES_ASSESTS_PATH}/tick-red.svg`
                          }
                          alt='tick'
                        />
                      </span>
                      <span className='px-2'>One special character</span>
                    </div>
                  </Col>
                  <Col sm='12'>
                    <div className='secondary-text-color'>
                      <span>
                        <img
                          src={
                            checkPassCase(
                              watch('password'),
                              watch('confirm_password')
                            )
                              ? `${IMAGES_ASSESTS_PATH}/tick-green.svg`
                              : `${IMAGES_ASSESTS_PATH}/tick-red.svg`
                          }
                          alt='tick'
                        />
                      </span>
                      <span className='px-2'>At least one uppercase</span>
                    </div>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col sm='12'>
                    <div className='secondary-text-color'>
                      <span>
                        <input
                          className='primary-checkbox'
                          type='checkbox'
                          {...register('agree_terms', { required: true })}
                        />
                      </span>{' '}
                      <span className='align-text-bottom px-2'>
                        By signing, you agree to our{' '}
                        <a
                          href={
                            watch('role') === ROLES.supplier
                              ? supplierPdf
                              : watch('role') === ROLES.farmer
                              ? farmerPdf
                              : retailerPdf
                          }
                          target='_blank'
                          className='external-link'
                        >
                          Terms of Use
                        </a>
                      </span>
                      {errors['agree_terms'] && (
                        <p className='mb-0 error-message'>
                          You must agree to continue
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col sm='12'>
                    <Button type='submit' className='primary-btn w-100'>
                      Sign Up
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col sm='12'>
                    <div className='text-center secondary-text-color '>
                      Already have an account?{' '}
                      <u>
                        <Link className='primary-color' to={`/login`}>
                          Login
                        </Link>
                      </u>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </form>
        </div>
      </Container>
    </>
  );
}

export default SignUp;
