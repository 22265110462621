import { createSlice } from '@reduxjs/toolkit';
import { messagesState } from '../states/messagesState';
import { messagesReducer } from '../reducers/messagesReducer';

const messagesSlice = createSlice({
  name: 'messages',
  initialState: messagesState,
  reducers: messagesReducer,
});

export const messagesActions = messagesSlice.actions;
export default messagesSlice;
