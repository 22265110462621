import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import facedbookIcon from '../../../assets/icons/Group 49142-6.svg';
import twitterIcon from '../../../assets/icons/x.svg';
import mailIcon from '../../../assets/icons/Group 49142-2.svg';
import contactIcon from '../../../assets/icons/Group 49142-1.svg';
import locationIcon from '../../../assets/icons/Group 49142.svg';
import verticalLinesIcon from '../../../assets/icons/Group 49179.svg';
import privacyPolicy from '../../../assets/documents/Privacy-Policy.pdf';
import { IMAGES_ASSESTS_PATH } from '../../../constants';

function Footer() {
  return (
    <>
      <div className='footer'>
        <div className='row top-container mx-5 p-2'>
          <div className='col discription p-5'>
            <img
              src={`${IMAGES_ASSESTS_PATH}/Logo-white.png`}
              alt=''
              className='mb-3'
            />
            <p>
              A national tendering marketplace for farming inputs such as
              chemical, fertiliser, fuel & seed. Transforming agriculture
              through innovation and unlocking huge value for Farmers all across
              Australia.
            </p>
          </div>

          <div className='col social-icons p-5 '>
            <h5>Follow us on</h5>
            <div className='my-2'>
              <img src={facedbookIcon} alt='facebook' />
              <a
                rel='noreferrer'
                target='_blank'
                href='https://www.facebook.com/iinputscom/'
              >
                Facebook
              </a>
            </div>
            <div>
              <img
                src={twitterIcon}
                alt='twitter'
                className='social-icon mx-2'
              />
              <a
                rel='noreferrer'
                target='_blank'
                href='https://twitter.com/iinputs'
              >
                Twitter
              </a>
            </div>
          </div>

          <div className='col p-5'>
            <h5>Quick Link</h5>
            <div>
              <Link to='/about'>About Us</Link>
            </div>
          </div>

          <div className='col contact-us p-5'>
            <h5>Contact us</h5>
            <div>
              <img src={mailIcon} alt='' />
              <a
                rel='noreferrer'
                target='_blank'
                href='mailto:contact@iinputs.com'
              >
                contact@iinputs.com
              </a>
            </div>
            <div>
              <img src={contactIcon} alt='' />
              <a href='tel:+61493700485'>+61493700485</a>
            </div>
            <div>
              <img src={locationIcon} alt='' />
              <span>39 High Street, Kimba SA 5641</span>
            </div>
          </div>
        </div>
        <div className='line'>
          <div className='left-line mx-5'>
            <hr />
          </div>
          <img src={verticalLinesIcon} alt='' />
          <div className='right-line mx-5'>
            <hr />
          </div>
        </div>
        <div className='low-container'>
          <div className='privacy-policy'>
            <div>
              <a target='_blank' href={privacyPolicy} rel='noreferrer'>
                Privacy Policy
              </a>
            </div>
          </div>
          <div className='copyright'>
            &copy;2023 Copyrights. All rights are reserved.
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
