import React from 'react';
import {
  getStatusClass,
  getTenderID,
  getTimeDiff,
} from '../../../services/utilService';
import { Col, Row } from 'react-bootstrap';
import { TENDER_STATUSES } from '../../../constants';

function TenderDetailHeader({ showFeeInvoice, tender, isPending, applied }) {
  const getStatus = () => {
    return isPending ? TENDER_STATUSES.pending : tender?.status;
  };

  return (
    <>
      <Row className={`${showFeeInvoice && 'header-border'}`}>
        <Col xl={showFeeInvoice ? '8' : '12'} lg="12" className="mb-3">
          <div className="top-header mb-2">
            {!showFeeInvoice && (
              <div className="secondary-text-color">
                {(applied ? 'Applied ' : 'Posted ') +
                  getTimeDiff(tender?.createdAt) +
                  ' ago'}
              </div>
            )}
          </div>
          <div className="d-lg-flex d-sm-block align-items-center">
            <h4 className="secondary-text-color d-inline-block mb-0">
              {'#' + getTenderID(tender?._id)}
              <span className="px-2">{'-'}</span>
            </h4>
            <h2 className="text-black d-inline-block me-3">{tender?.title}</h2>
            <button className={`px-4 py-1 ${getStatusClass(getStatus())}`}>
              {getStatus()}
            </button>
          </div>
        </Col>

        <Col
          xl={showFeeInvoice ? '4' : '12'}
          lg="12"
          className={showFeeInvoice && 'mb-3'}
        >
          {showFeeInvoice && (
            <div className="h-100 d-flex align-items-center justify-content-xl-end">
              <div>
                <h3 className="blink">Important Reminder</h3>
                <h6>
                  iinputs.com Charge a Finders Fee of 0.5% on the total Invoiced
                  Amount.
                </h6>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default TenderDetailHeader;
