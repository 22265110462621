import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { IMAGES_ASSESTS_PATH } from '../../../constants';

function AccountSuspended() {
  return (
    <Container fluid>
      <div className="auth-box mt-5">
        <Row className="mb-4">
          <Col sm="12">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <img
                className="medium-icon"
                src={`${IMAGES_ASSESTS_PATH}/suspend.svg`}
                alt="suspend"
              />
            </div>
          </Col>
          <Col sm="12">
            <h3 className="text-center mb-3">Your account is suspended</h3>
          </Col>
          <Col sm="12">
            <p className="text-center secondary-text-color mb-0">
              Your account has been inactive for a long time, it is suspended to
              prevent the unauthorized access. For more information, visit the
              help center
            </p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col sm="12">
            <Button type="submit" className="primary-btn w-100">
              Contact Us
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default AccountSuspended;
