import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import './Settings.css';
import PasswordField from '../password-field/PasswordField';
import { IMAGES_ASSESTS_PATH, ROLES, passwordRegex } from '../../../constants';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import OneButtonModal from '../../modals/one-button-modal/OneButtonModal';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../../store/slices/userSlice';
import { settingActions } from '../../../store/slices/settingSlice';
import SpinnerLoader from '../../common/spinner/Spinner';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { generateUniqueId } from '../../../services/utilService';

const Settings = () => {
  const navigate = useNavigate();
  const [showSpinner, setSpinner] = useState(false);
  const [btnId, setBtnId] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [quoteUpdates, setQuoteUpdates] = useState(false);
  const [disputeUpdates, setDisputesUpdates] = useState(false);
  const [messageUpdates, setMessageUpdates] = useState(false);
  const [newTenderUpdates, setNewTenderUpdates] = useState(false);
  const [locationUpdates, setLocationUpdates] = useState(false);
  const [location, setLocation] = useState([]);
  const [locationInput, setLocationInput] = useState([]);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.settings);
  const ref = useRef();

  useEffect(() => {
    dispatch(settingActions.getSettings());
  }, [dispatch]);

  useEffect(() => {
    setMessageUpdates(
      settings?.setting?.message_updates !== undefined
        ? settings?.setting?.message_updates
        : true
    );
    setDisputesUpdates(
      settings?.setting?.dispute_updates !== undefined
        ? settings?.setting?.dispute_updates
        : true
    );
    setQuoteUpdates(
      settings?.setting?.quote_updates !== undefined
        ? settings?.setting?.quote_updates
        : true
    );
    setNewTenderUpdates(
      settings?.setting?.new_tender_updates !== undefined
        ? settings?.setting?.new_tender_updates
        : true
    );
    setLocation(settings?.setting?.location);
    setLocationUpdates(settings?.setting?.location?.length > 0 ? true : false);
    setLocationInput(location ? location : []);
  }, [settings, location]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    if (data?.newPassword !== data?.confirmNewPassword) {
      toast.error('Password Must be same');
      return;
    }

    if (!passwordRegex.test(data?.newPassword)) {
      toast.error(
        'Password must be 8 characters, one special character and one uppercase'
      );
      return;
    }
    dispatch(userActions.updatePassword(data));
  };

  useEffect(() => {
    if (user.user.error) {
      setShowModal(false);
      toast.error(user.user.error);
    }

    if (user.user.message) {
      setShowModal(true);
    }
    return () => {
      dispatch(userActions.clearErrors());
    };
  }, [dispatch, user.error, user.loading, user.response, user.user]);

  const handleQuoteUpdates = () => {
    setQuoteUpdates(!quoteUpdates);
    setSpinner(settings?.setting?.loading);
    dispatch(
      settingActions.updateSetting({
        user_id: user.user?._id,
        quote_updates: !quoteUpdates,
      })
    );
    setSpinner(settings?.setting?.loading);
  };

  const handleMessageUpdate = () => {
    setMessageUpdates(!messageUpdates);
    setSpinner(settings?.setting?.loading);
    dispatch(
      settingActions.updateSetting({
        user_id: user.user?._id,
        message_updates: !messageUpdates,
      })
    );
    setSpinner(settings?.setting?.loading);
  };

  const handleDisputeUpdates = () => {
    setDisputesUpdates(!disputeUpdates);
    setSpinner(settings?.setting?.loading);
    dispatch(
      settingActions.updateSetting({
        user_id: user.user?._id,
        dispute_updates: !disputeUpdates,
      })
    );
    setSpinner(settings?.setting?.loading);
  };

  const handleNewTenderUpdates = () => {
    setNewTenderUpdates(!newTenderUpdates);
    setSpinner(settings?.setting?.loading);
    dispatch(
      settingActions.updateSetting({
        user_id: user.user?._id,
        new_tender_updates: !newTenderUpdates,
      })
    );
    setSpinner(settings?.setting?.loading);
  };

  const handleLocationUpdates = () => {
    const valuesArray = locationInput;
    setSpinner(settings?.setting?.loading);
    dispatch(
      settingActions.updateSetting({
        user_id: user.user?._id,
        location: valuesArray,
      })
    );
    toast.success('Location Updated');
    setSpinner(settings?.setting?.loading);
  };

  const closeModal = () => {
    setShowModal(!showModal);
    if (user.user.role === ROLES.farmer) {
      navigate(`/farmer/tender-listing/Active`);
    }

    if (user.user.role === ROLES.supplier) {
      navigate(`/supplier/tender-listing`);
    }
  };

  const handleDelete = (location) => {
    setLocationInput((prev) => prev.filter((loc) => loc !== location));
  };

  const handleAddition = (tag) => {
    ref.current.value = '';
    setLocationInput((prev) => [...prev, tag.formatted_address]);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='container border mt-5 d-flex flex-wrap flex-lg-nowrap flex-md-nowrap'>
        <div className='sidebar border-right my-3 mx-2'>
          <button
            className={`btn ${
              btnId === 1 ? 'success-btn' : 'default-btn '
            } mx-2`}
            onClick={() => setBtnId(1)}
          >
            Change Password
          </button>
          <button
            className={`btn ${
              btnId === 2 ? 'success-btn' : 'default-btn'
            } my-2`}
            onClick={() => setBtnId(2)}
          >
            Notification Settings
          </button>
        </div>

        <div className='right-content my-3 mx-5 w-100'>
          <div className='setting '>
            <div className='setting-text mb-2'>Settings</div>
            <div className='grey-text'>Manage your settings here</div>
          </div>
          <div className='w-100 border my-4'></div>
          <form action='' onSubmit={handleSubmit(onSubmit)}>
            {btnId === 1 && (
              <>
                <div className='input-div my-4'>
                  <label className='mb-2 d-block'>Current Password</label>
                  <PasswordField
                    placeholder='Enter current password'
                    className={`form-input  w-100 ${
                      errors['password'] && 'invalid-input'
                    }`}
                    register={register}
                    name='oldPassword'
                  />
                </div>

                <div className='d-flex my-3 flex-wrap flex-lg-nowrap flex-md-nowrap'>
                  <div className='input-div '>
                    <label className='mb-2 d-block'>New Password</label>
                    <PasswordField
                      placeholder='Enter new password'
                      className={`form-input w-100 ${
                        errors['password'] && 'invalid-input'
                      }`}
                      register={register}
                      name='newPassword'
                    />
                  </div>
                  <div className='input-div'>
                    <label className='mb-2 d-block'>Confirm New Password</label>
                    <PasswordField
                      placeholder='Confirm new password'
                      className={`form-input w-100 ${
                        errors['password'] && 'invalid-input'
                      }`}
                      register={register}
                      name='confirmNewPassword'
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <img src={`${IMAGES_ASSESTS_PATH}/tick-red.svg`} alt='' />
                    <span className='grey-text'>Must be 8 characters</span>
                  </div>
                  <div>
                    <img src={`${IMAGES_ASSESTS_PATH}/tick-red.svg`} alt='' />
                    <span className='grey-text'>One special charater</span>
                  </div>
                  <div>
                    <img src={`${IMAGES_ASSESTS_PATH}/tick-red.svg`} alt='' />
                    <span className='grey-text'>At least one uppercase</span>
                  </div>
                </div>
                <div className='d-flex justify-content-end my-3'>
                  <button
                    className='btn default-btn border border-dark me-2'
                    onClick={() =>
                      navigate(`/${user.user.role}/tender-listing`)
                    }
                  >
                    Cancel
                  </button>
                  <button className='success-btn' type='submit'>
                    Change Password
                  </button>
                </div>
              </>
            )}

            {btnId === 2 && (
              <>
                <div className='notification my-3'>
                  <div className='px-2 my-3 notification-text setting-text'>
                    Notifications
                  </div>
                  <div className='d-flex justify-content-between align-items-center my-2'>
                    <div className='grey-text'>Enable Quotes Updates</div>
                    <div className='form-check form-switch'>
                      <input
                        className={`form-check-input ${
                          quoteUpdates ? 'checked-color' : 'unchecked-color'
                        }`}
                        type='checkbox'
                        id='quoteUpdate'
                        role='button'
                        checked={quoteUpdates}
                        onClick={handleQuoteUpdates}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center my-2'>
                    <div className='grey-text'>Enable Dispute Updates</div>
                    <div className='form-check form-switch'>
                      <input
                        className={`form-check-input ${
                          disputeUpdates ? 'checked-color' : 'unchecked-color'
                        }`}
                        type='checkbox'
                        id='disputeUpdates'
                        role='button'
                        checked={disputeUpdates}
                        onClick={handleDisputeUpdates}
                      />
                    </div>
                  </div>
                  {user.user?.role === ROLES.farmer && (
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <div className='grey-text'>Enable Message Updates</div>
                      <div className='form-check form-switch'>
                        <input
                          className={`form-check-input ${
                            messageUpdates ? 'checked-color' : 'unchecked-color'
                          }`}
                          type='checkbox'
                          id='messageUpdates'
                          role='button'
                          checked={messageUpdates}
                          onClick={handleMessageUpdate}
                        />
                      </div>
                    </div>
                  )}
                  {(user.user?.role === ROLES.supplier ||
                    user.user?.role === ROLES.retailer) && (
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <div className='grey-text'>
                        Notify me via text message when there is a new tender
                      </div>
                      <div className='form-check form-switch'>
                        <input
                          className={`form-check-input ${
                            newTenderUpdates
                              ? 'checked-color'
                              : 'unchecked-color'
                          }`}
                          type='checkbox'
                          id='locationUpdates'
                          checked={newTenderUpdates}
                          onChange={handleNewTenderUpdates}
                        />
                      </div>
                    </div>
                  )}
                  {/* {locationUpdates && (
                    <div className="mb-3 border p-4">
                      {locationInput.map((location) => (
                        <div
                          className="d-inline-block me-2 location-tag"
                          key={generateUniqueId()}
                        >
                          {location}
                          <span
                            onClick={() => handleDelete(location)}
                            role="button"
                            className="ms-2"
                          >
                            x
                          </span>
                        </div>
                      ))}
                      <div className="input-group mt-3">
                        <ReactGoogleAutocomplete
                          ref={ref}
                          aria-label="Recipient's username"
                          placeholder="Enter towns/cities/states"
                          className="form-control"
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          onPlaceSelected={handleAddition}
                          options={{
                            types: ['geocode', 'establishment'],
                            componentRestrictions: { country: 'aus' },
                          }}
                        />
                        <div
                          className="input-group-append"
                          role="button"
                          onClick={handleLocationUpdates}
                        >
                          <span
                            className="input-group-text save-btn"
                            id="basic-addon2"
                          >
                            Save
                          </span>
                        </div>
                      </div>
                      <div className="my-2">
                        Note: You can enter multiple locations
                      </div>
                    </div>
                  )} */}
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      {showModal && (
        <OneButtonModal
          show={showModal}
          icon={`${IMAGES_ASSESTS_PATH}/Completed.svg`}
          title={'Password Updated'}
          message={'Your password has successfully been updated'}
          btn={'Close'}
          click={closeModal}
        />
      )}
    </>
  );
};

export default Settings;
