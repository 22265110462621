import { authorizedGetCall, authorizedPutCall } from './APIsService';

export const updateSetting = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall('/setting/update_setting', body)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSettings = () => {
  return new Promise((resolve, reject) => {
    authorizedGetCall('/setting/find_setting')
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
