import { takeLatest, call, put, all } from 'redux-saga/effects';
import { settingActions } from '../slices/settingSlice';
import { updateSetting, getSettings } from '../../services/settingService';

function* getUserSettingsSaga(action) {
  try {
    const response = yield call(getSettings, action.payload);
    yield put(settingActions.requestSuccess(response));
  } catch (error) {
    yield put(settingActions.requestFailure(error));
  }
}

function* updateSettingsSaga(action) {
  try {
    const response = yield call(updateSetting, action.payload);
    yield put(settingActions.requestSuccess(response));
  } catch (error) {
    yield put(settingActions.requestFailure(error));
  }
}

function* clearSaga() {
  try {
    yield put(settingActions.clearSettings());
  } catch (error) {
    yield put(settingActions.requestFailure(error));
  }
}

function* settingSaga() {
  yield all([
    takeLatest(settingActions.updateSetting.type, updateSettingsSaga),
    takeLatest(settingActions.getSettings.type, getUserSettingsSaga),
    takeLatest(settingActions.clearRequest.type, clearSaga),
  ]);
}

export default settingSaga;
