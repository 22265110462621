import { takeLatest, call, put, all } from 'redux-saga/effects';
import { tenderActions } from '../slices/tenderSlice';
import {
  createtender,
  getDisputes,
  getTenderQuotes,
  getRetailerQuotes,
  getUserTenders,
  getOtherTenders,
} from '../../services/tenderService';

function* createTenderSaga(action) {
  try {
    const response = yield call(createtender, action.payload);
    yield put(tenderActions.requestSuccess(response));
  } catch (error) {
    yield put(tenderActions.requestFailure(error));
  }
}

function* getUserTendersSaga(action) {
  try {
    const response = yield call(getUserTenders, action.payload);
    yield put(tenderActions.requestSuccess(response));
  } catch (error) {
    yield put(tenderActions.requestFailure(error));
  }
}

function* getOtherTendersSaga(action) {
  try {
    const response = yield call(getOtherTenders, action.payload);
    yield put(tenderActions.requestSuccess(response));
  } catch (error) {
    yield put(tenderActions.requestFailure(error));
  }
}

function* getTenderQuotesSaga(action) {
  try {
    const response = yield call(getTenderQuotes, action.payload);
    yield put(tenderActions.requestSuccess(response));
  } catch (error) {
    yield put(tenderActions.requestFailure(error));
  }
}
function* getRetailerQuotesSaga(action) {
  try {
    const response = yield call(getRetailerQuotes, action.payload);
    yield put(tenderActions.requestSuccess(response));
  } catch (error) {
    yield put(tenderActions.requestFailure(error));
  }
}

function* getDisputesSaga(action) {
  try {
    const response = yield call(getDisputes, action.payload);
    yield put(tenderActions.requestSuccess(response));
  } catch (error) {
    yield put(tenderActions.requestFailure(error));
  }
}

function* tenderSaga() {
  yield all([
    takeLatest(tenderActions.createTenderRequest.type, createTenderSaga),
    takeLatest(tenderActions.getUserTenders.type, getUserTendersSaga),
    takeLatest(tenderActions.getOtherTenders.type, getOtherTendersSaga),
    takeLatest(tenderActions.getTenderQuotesRequest.type, getTenderQuotesSaga),
    takeLatest(
      tenderActions.getRetailerQuotesRequest.type,
      getRetailerQuotesSaga
    ),
    takeLatest(tenderActions.getDisputesRequest.type, getDisputesSaga),
  ]);
}

export default tenderSaga;
