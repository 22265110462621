import React from 'react';
import './TenderListingItem.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES, TENDER_DETAIL_TABS, TENDER_STATUSES } from '../../../constants';
import { tenderActions } from '../../../store/slices/tenderSlice';
import { getTenderID, tenderInfoDiv } from '../../../services/utilService';
import quotesReceived from '../../../assets/icons/quotesReceived.svg';

function TenderListingItem({ tender, tab }) {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleClick = (tender) => {
    dispatch(tenderActions.persistTender(tender));
    if (tab === TENDER_STATUSES.draft) {
      navigate(`/${user?.user?.role}/create-tender`);
    } else {
      navigate(
        `/${user?.user?.role}/tender-detail/${tender._id}/${TENDER_DETAIL_TABS.tenderDetail}`
      );
    }
  };

  return (
    <Container className='tender-item-container'>
      <Row>
        <div className='posted-by'>
          {tender?.postedBy === ROLES.retailer ? "Retailer's" : "Farmer's"}{' '}
          Tender
        </div>
      </Row>
      <Row>
        <Col className='py-4 ps-4' lg='8' md='12' sm='12'>
          <div className='border-right h-100 pe-4'>
            <div className='d-lg-flex d-sm-block align-items-center'>
              <h4 className='secondary-text-color d-inline-block mb-0'>
                {'#' + getTenderID(tender._id)}
                <span className='px-3'>{'-'}</span>
              </h4>
              <h2 className='text-black d-inline-block mb-0'>{tender.title}</h2>
            </div>
            <div className='mt-3 secondary-text-color pre-wrap'>
              {tender.details}
            </div>
          </div>
        </Col>
        <Col className='p-4' lg='4' md='12' sm='12 '>
          {/* {(user?.user.role === ROLES.supplier ||
            user?.user.role === ROLES.retailer) &&
            !location.pathname.startsWith('/retailer/my-tenders') && (
              <div className='text-end'>
                <span className='quotes-count'>{tender?.total_quotes}</span>{' '}
                Quotes Submitted
              </div>
            )} */}
          <div className='my-lg-4 my-sm-2'>
            {tender?.postedBy !== ROLES.retailer
              ? tenderInfoDiv(
                  '/assets/icons/supplier/Delivery.svg',
                  `${tender?.transport} Location:`,
                  tender.post_code,
                  true
                )
              : ''}

            {tenderInfoDiv(
              '/assets/icons/supplier/Calendar.svg',
              'Date Of Supply:',
              tender?.completion_date
                ? moment(tender?.completion_date).format('DD-MM-YYYY')
                : 'N/A',
              true
            )}

            {tenderInfoDiv(
              '/assets/icons/supplier/duration.svg',
              'Tender Duration:',
              tender.duration[0]
                ? moment(tender?.duration[1]).diff(
                    tender?.duration[0],
                    'days'
                  ) +
                    1 +
                    ' days'
                : 'N/A',
              true
            )}

            {/* {tenderInfoDiv(
              '/assets/icons/Username.svg',
              'Farmer Name:',
              tender?.farmerName || 'N/A',
              true
            )}

            {tenderInfoDiv(
              '/assets/icons/Rating.svg',
              'Farmer Feedback Rating:',
              <Rating
                size={25}
                initialValue={tender?.rating[0]?.totalRating}
                fillColor="green"
                readonly
              />,
              true
            )} */}

            {!location.pathname.startsWith('/retailer') &&
              tenderInfoDiv(
                '/assets/icons/Success-Rate.svg',
                'Farmer Tender Success Rate:',
                (
                  (tender?.closed_user_tenders / tender?.total_user_tenders) *
                  100
                ).toFixed(2) + '%',
                true
              )}

            {location.pathname.startsWith('/retailer/my-tenders') &&
              tenderInfoDiv(
                quotesReceived,
                'Quotes Received:',
                tender.total_quotes,
                true
              )}

            <div className='ms-2 mt-4 view-details-btn'>
              <Button
                className='border-green-btn py-2'
                onClick={() => handleClick(tender)}
                type='button'
              >
                <div className='d-flex align-items-center'>
                  {tab === TENDER_STATUSES.draft
                    ? 'Complete your tender'
                    : 'View Details'}
                  <img
                    className='ms-3'
                    src={'/assets/icons/supplier/arrow-right.svg'}
                    alt='arrow'
                  />
                </div>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default TenderListingItem;
