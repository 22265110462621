import React, { useEffect, useState } from 'react';
import './QuoteDetail.css';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerLoader from '../../common/spinner/Spinner';
import TenderDetailHeader from '../../common/tender-detail-header/TenderDetailHeader';
import { Button, Col, Row } from 'react-bootstrap';
import {
  ACCEPTED_QUOTE_MODAL_MSG,
  ACCEPTED_QUOTE_MODAL_TITLE,
  ACCEPT_QUOTE_MODAL_MSG,
  ACCEPT_QUOTE_MODAL_TITLE,
  IMAGES_ASSESTS_PATH,
  TENDER_STATUSES,
} from '../../../constants';
import SimpleModal from '../../modals/simple-modal/SimpleModal';
import { quoteActions } from '../../../store/slices/quoteSlice';
import OneButtonModal from '../../modals/one-button-modal/OneButtonModal';
import { useNavigate, useParams } from 'react-router-dom';
import SupplierOffer from '../../common/supplier-offer/SupplierOffer';
import moment from 'moment';
import { createRoom } from '../../../services/messagesService';
import { getOneQuote } from '../../../services/quoteService';
import { Rating } from 'react-simple-star-rating';

function QuoteDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quoteState = useSelector((state) => state.quote);
  const tenderState = useSelector((state) => state.tender);
  const user = useSelector((state) => state.user.user);
  const [showSpinner, setSpinner] = useState(false);
  const [showModal, setModal] = useState(false);
  const [showSubmitModal, setSubmitModal] = useState(false);
  const [quote, setQuote] = useState(quoteState.quote);
  const [tender, setTender] = useState(tenderState.tender);

  const handleSubmit = () => {
    dispatch(quoteActions.acceptQuotesRequest(quote._id));
    setModal(false);
    setSubmitModal(true);
  };

  const handleClick = () => {
    setSubmitModal(false);
    dispatch(quoteActions.persistClear());
    navigate(`/${user?.role}/tender-detail/${tender._id}/Hired`);
  };

  useEffect(() => {
    if (id) {
      setSpinner(true);
      getOneQuote(id)
        .then((data) => {
          setQuote(data);
          setTender(data.tender_id);
          setSpinner(false);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
        });
    }
    return () => {
      dispatch(quoteActions.clearErrors());
    };
  }, [dispatch, id]);

  const checkDisabled = () => tender?.status !== TENDER_STATUSES.active;

  const startChat = async () => {
    const room = await createRoom({
      ids: [quote?.user_id?._id, user._id],
      tenderId: tender._id,
    });
    navigate(`/${user.role}/messages`, { state: { room: room._id } });
  };

  return (
    <>
      <div className='mt-4'>
        <SpinnerLoader showSpinner={showSpinner} />
        <div className='container auth-box tender-detail-box no-bg'>
          <div className='d-flex justify-content-between align-items-center px-3'>
            <div className='directions'>
              <img
                src={'/assets/images/Breadcrumbs-Quote.png'}
                alt='breadcrumbs'
              />
            </div>
          </div>
        </div>
        <div className='mt-4 auth-box tender-detail-box'>
          <TenderDetailHeader showFeeInvoice={false} tender={tender} applied />
        </div>

        <SupplierOffer
          quote={quote}
          checkDisabled={checkDisabled}
          setModal={setModal}
          setSpinner={setSpinner}
        />

        <div className='my-4 auth-box tender-detail-box'>
          <h5 className='bold-text'>About Supplier</h5>
          <div className='mt-5'>
            <Row>
              <Col md='3'>
                <div className='d-flex align-items-center'>
                  <img
                    src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                    className='large-icon me-3'
                    alt='profile'
                  />
                  <div>
                    <h6 className='bold-text'>{quote?.user_id?.name}</h6>

                    <div className='d-flex align-items-center'>
                      {quote?.user_id?.rating?.totalRating ? (
                        <>
                          <div className='me-2'>
                            <Rating
                              size={25}
                              initialValue={quote?.user_id?.rating?.totalRating}
                              fillColor='green'
                              readonly
                            />
                          </div>
                          <div>
                            {parseInt(
                              quote?.user_id?.rating?.totalRating
                            ).toFixed(1)}{' '}
                            of {quote?.user_id?.rating?.count} review(s)
                          </div>
                        </>
                      ) : (
                        <>No ratings</>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md='3'>
                <div className='d-flex flex-column justify-content-center'>
                  <div className='bold-text mt-3 mt-md-0'>Bussinees Name</div>
                  <div className='mt-2'>
                    {quote?.user_id?.business_name || 'N/A'}
                  </div>
                </div>
              </Col>
              <Col md='2'>
                <div className='d-flex align-items-center'>
                  <img
                    src={`${IMAGES_ASSESTS_PATH}/location.svg`}
                    alt='location'
                    className='me-3'
                  />
                  <span>Supplier Location</span>
                </div>
                <div className='ms-5'>{quote?.user_id?.address || 'N/A'}</div>
              </Col>
              <Col md='2' className='mt-3 mt-md-0'>
                <div className='d-flex align-items-center'>
                  <span>Supplier Rating</span>
                </div>
                <Rating
                  size={25}
                  initialValue={quote?.user_id?.rating?.totalRating}
                  fillColor='green'
                  readonly
                />
              </Col>

              <Col md='2'>
                <div className='w-100 d-flex align-items-center justify-content-end'>
                  <Button onClick={startChat} className='blue-btn'>
                    Chat with Supplier
                  </Button>
                </div>
              </Col>
            </Row>
            <div className='mt-5'>
              Member since{' '}
              {moment(quote?.user_id?.createdAt).format('ddd, MMM DD')}
            </div>
          </div>
        </div>
      </div>
      <SimpleModal
        show={showModal}
        handleClose={() => setModal(false)}
        noClick={() => setModal(false)}
        yesClick={handleSubmit}
        title={ACCEPT_QUOTE_MODAL_TITLE}
        message={ACCEPT_QUOTE_MODAL_MSG}
        img={`${IMAGES_ASSESTS_PATH}/Quote-large.svg`}
        primary
      />
      <OneButtonModal
        show={showSubmitModal}
        handleClose={() => setSubmitModal(false)}
        btn={'View Tender'}
        title={ACCEPTED_QUOTE_MODAL_TITLE}
        message={ACCEPTED_QUOTE_MODAL_MSG}
        click={handleClick}
      />
    </>
  );
}

export default QuoteDetail;
