import { authorizedGetCall, authorizedPostCall } from './APIsService';

export const createtender = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/tender/create', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserTenders = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/tender/all`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOtherTenders = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/tender/other-tenders`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTenderQuotes = (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/tender/quotes/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRetailerQuotes = (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/tender/quotes-retailer/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getDisputes = () => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/tender/all-disputes`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
