import { createSlice } from '@reduxjs/toolkit';
import { awsState } from '../states/awsStates';
import { awsReducer } from '../reducers/awsReducer';

const awsSlice = createSlice({
  name: 'aws',
  initialState: awsState,
  reducers: awsReducer,
});

export const awsActions = awsSlice.actions;
export default awsSlice;
