import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './FilterModal.css';
import { Controller, useForm } from 'react-hook-form';
import CustomDatePicker from '../../common/date-picker/DatePicker';
import { generateUniqueId } from '../../../services/utilService';
import ReactGoogleAutocomplete from 'react-google-autocomplete';

const FilterModal = ({ showModal, handleClose, setFilters }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    setFilters(data);
  };

  const ranges = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  return (
    <Modal show={showModal} onHide={handleClose} className="modal-lg">
      <Modal.Header closeButton className="modal-container">
        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="modal-container">
          <h4 className="mb-2">Supply Date</h4>
          <div className="date-inputs d-flex">
            <div className="from-date form-group ">
              <label htmlFor="fromDate">From</label>
              <CustomDatePicker
                className={`form-input w-100 ${
                  errors['completion_date'] && 'invalid-input'
                }`}
                name="fromDate"
                control={control}
                setValue={setValue}
              />
            </div>
            <div className="to-date form-group">
              <label htmlFor="toDate">To</label>
              <CustomDatePicker
                className={`form-input w-100 ${
                  errors['completion_date'] && 'invalid-input'
                }`}
                name="toDate"
                control={control}
                setValue={setValue}
              />
            </div>
          </div>

          <h4 className="mb-2 mt-5">Location (Town)</h4>
          <div className="location-input form-group">
            <label htmlFor="zipCode">Location</label>
            <Controller
              control={control}
              name="zipCode"
              render={({ field: { onChange } }) => (
                <ReactGoogleAutocomplete
                  placeholder="Enter location"
                  className="form-control"
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  onPlaceSelected={(place) => {
                    onChange(place.formatted_address);
                  }}
                  options={{
                    types: ['geocode', 'establishment'],
                    componentRestrictions: { country: 'aus' },
                  }}
                />
              )}
            />
          </div>
          <h4 className="mb-3 mt-5">Tender Duration (Days)</h4>
          <div className="range">
            <div className="slider-limit d-flex justify-content-between">
              <small>Min</small>
              <small>Max</small>
            </div>
            <input
              type="range"
              name="range"
              min={0}
              max={15}
              defaultValue={0}
              className="form-range range-input"
              {...register('range')}
            />
            <div className="slider-caption d-flex justify-content-between ml-2 mt-1 mb-5">
              {ranges.map((range) => {
                return <span key={generateUniqueId()}>{range}</span>;
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-container">
          <div className="modal-action-buttons d-flex justify-content-between mt-5 w-100">
            <Button
              type="button"
              className="btn btn-light text-dark"
              onClick={() => reset()}
            >
              Clear filters
            </Button>
            <Button className="btn btn-success" type="submit">
              Apply filters
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default FilterModal;
