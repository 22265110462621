import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  IMAGES_ASSESTS_PATH,
  checkPassCase,
  checkPassChars,
  checkPassSpecialChars,
  errorToast,
  successToast,
} from '../../../constants';
import PasswordField from '../../common/password-field/PasswordField';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { userActions } from '../../../store/slices/userSlice';
import SpinnerLoader from '../../common/spinner/Spinner';

function ResetPassword() {
  const [showSpinner, setSpinner] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (data) => {
    if (watch('password') !== watch('confirm_password')) {
      toast.error('Passwords do no Match!', errorToast);
      return;
    }
    dispatch(
      userActions.resetPasswordRequest({ password: data.password, token })
    );
  };

  useEffect(() => {
    setSpinner(user.loading);
    if (user.error) {
      setDisabled(true);
      toast.error(user.error, errorToast);
    }

    if (user.user?.message) {
      toast.success(user.user?.message, successToast);
      navigate('/login');
    }

    return () => {
      dispatch(userActions.clearErrors());
    };
  }, [user.loading, user.error, user.user?.message, dispatch, navigate]);

  useEffect(() => {
    dispatch(userActions.validateResetLink({ token }));
  }, [dispatch, token]);

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container fluid>
        <div className="auth-box">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-4">
              <Col sm="12">
                <div className="d-flex align-items-center justify-content-center mb-5">
                  <img
                    className="medium-icon"
                    src={`${IMAGES_ASSESTS_PATH}/password.svg`}
                    alt="password"
                  />
                </div>
              </Col>
              <Col sm="12">
                <h3 className="text-center mb-3">Create new Password</h3>
              </Col>
              <Col sm="12">
                <p className="text-center mb-0">
                  That’s it. Change your password
                </p>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm="12">
                <label className="mb-2 d-block">
                  Password <span className="required-color">*</span>
                </label>
                <PasswordField
                  disabled={disabled}
                  placeholder="Enter password"
                  className={`form-input w-100 ${
                    errors['password'] && 'invalid-input'
                  }`}
                  register={register}
                  name="password"
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm="12">
                <label className="mb-2 d-block">
                  Confirm Password <span className="required-color">*</span>
                </label>
                <PasswordField
                  disabled={disabled}
                  placeholder="Re-enter password"
                  className={`form-input w-100 ${
                    errors['confirm_password'] && 'invalid-input'
                  }`}
                  register={register}
                  name="confirm_password"
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm="12">
                <div className="secondary-text-color">
                  <span>
                    <img
                      src={
                        checkPassChars(
                          watch('password'),
                          watch('confirm_password')
                        )
                          ? `${IMAGES_ASSESTS_PATH}/tick-green.svg`
                          : `${IMAGES_ASSESTS_PATH}/tick-red.svg`
                      }
                      alt="tick"
                    />
                  </span>
                  <span className="px-2">Must be 8 characters</span>
                </div>
              </Col>
              <Col sm="12">
                <div className="secondary-text-color">
                  <span>
                    <img
                      src={
                        checkPassSpecialChars(
                          watch('password'),
                          watch('confirm_password')
                        )
                          ? `${IMAGES_ASSESTS_PATH}/tick-green.svg`
                          : `${IMAGES_ASSESTS_PATH}/tick-red.svg`
                      }
                      alt="tick"
                    />
                  </span>
                  <span className="px-2">One special character</span>
                </div>
              </Col>
              <Col sm="12">
                <div className="secondary-text-color">
                  <span>
                    <img
                      src={
                        checkPassCase(
                          watch('password'),
                          watch('confirm_password')
                        )
                          ? `${IMAGES_ASSESTS_PATH}/tick-green.svg`
                          : `${IMAGES_ASSESTS_PATH}/tick-red.svg`
                      }
                      alt="tick"
                    />
                  </span>
                  <span className="px-2">At least one uppercase</span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <Button type="submit" className="primary-btn w-100">
                  Set new Password
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </Container>
    </>
  );
}

export default ResetPassword;
