import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../store/slices/userSlice';
import { toast } from 'react-hot-toast';
import {
  IMAGES_ASSESTS_PATH,
  errorToast,
  successToast,
} from '../../../constants';
import SpinnerLoader from '../../common/spinner/Spinner';

function EmailSent() {
  const [showSpinner, setSpinner] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    setSpinner(user.loading);
    user.error && toast.error(user.error, errorToast);
    if (user.user?.message) {
      toast.success(user.user?.message, successToast);
    }

    return () => {
      dispatch(userActions.clearErrors());
    };
  }, [user.loading, user.error, user.user?.message, dispatch]);

  const resendEmail = () => {
    dispatch(userActions.forgotPasswordRequest({ email: user?.user?.email }));
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container fluid>
        <div className="auth-box">
          <Row className="mb-4">
            <Col sm="12">
              <div className="d-flex align-items-center justify-content-center mb-5">
                <img
                  className="medium-icon"
                  src={`${IMAGES_ASSESTS_PATH}/email.svg`}
                  alt="email"
                />
              </div>
            </Col>
            <Col sm="12">
              <h3 className="text-center mb-3">Check your email</h3>
            </Col>
            <Col sm="12">
              <p className="text-center secondary-text-color mb-4">
                We’ve sent a verification email to {user?.user?.email}
              </p>
            </Col>
            <Col sm="12">
              <h6 className="text-center mb-4">
                Click the link in your email to verify your account.
              </h6>
            </Col>
            <Col sm="12">
              <p className="text-center secondary-text-color mb-5">
                If you have trouble finding your email, check your spam folder
                for an email from noreply@reversemarketplace.com
              </p>
            </Col>
            <Col sm="12">
              <h6 className="text-center mb-0">Didn’t receive an email?</h6>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm="12">
              <Button
                onClick={resendEmail}
                type="submit"
                className="primary-btn w-100"
              >
                Resend link
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default EmailSent;
