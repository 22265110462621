import React from 'react';
import { Spinner } from 'react-bootstrap';
import './Spinner.css';

const SpinnerLoader = ({ showSpinner }) => {
  return (
    <>
      {showSpinner && (
        <div className={'spinner-container'}>
          <div className={'spinner'}>
            <Spinner />
          </div>
        </div>
      )}
    </>
  );
};

export default SpinnerLoader;
