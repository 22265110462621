import { createSlice } from '@reduxjs/toolkit';
import { settingState } from '../states/settingState';
import { settingReducer } from '../reducers/settingReducer';

const settingSlice = createSlice({
  name: 'setting',
  initialState: settingState,
  reducers: settingReducer,
});

export const settingActions = settingSlice.actions;
export default settingSlice;
