import React from 'react';
import './LandingPage.css';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Footer from '../common/Footer/Footer';
import mobile_image from '../../assets/images/New-Mobile.png';
import iinputTextImg from '../../assets/images/Iinput-text.png';
import signUpIcon from '../../assets/icons/Landing page Final v2/Group 49793.svg';
import signInIcon from '../../assets/icons/Landing page Final v2/Group 49792.svg';
import facebook from '../../assets/icons/Landing page Final v2/Facebook Logo.png';
import twitter from '../../assets/icons/Landing page Final v2/Twitter X Icon.png';
import products from '../../assets/icons/Landing page Final v2/Product.png';
import chemical from '../../assets/icons/Landing page Final v2/Chemical.png';
import fertiliser from '../../assets/icons/Landing page Final v2/Fertiliser.png';
import fuel from '../../assets/icons/Landing page Final v2/Fuel.svg';
import seed from '../../assets/icons/Landing page Final v2/Seed.svg';
import anonymous from '../../assets/icons/Landing page Final v2/Anonymous.svg';
import buyingGroup from '../../assets/icons/Landing page Final v2/Buying Group.png';
import features from '../../assets/icons/Landing page Final v2/Features.svg';
import network from '../../assets/icons/Landing page Final v2/Network.svg';
import news from '../../assets/icons/Landing page Final v2/News.png';
import theLand from '../../assets/icons/Landing page Final v2/The Land.png';
import register from '../../assets/icons/Landing page Final v2/Register.png';
import queensLand from '../../assets/icons/Landing page Final v2/Queesn Land.png';
import stockLand from '../../assets/icons/Landing page Final v2/Stock & Land.png';
import stockJournal from '../../assets/icons/Landing page Final v2/Stock journal.png';
import weeklyTimes from '../../assets/icons/Landing page Final v2/Weekly Times.png';
import gearIcon from '../../assets/icons/Landing page Final v2/Gear Icon.svg';
import Nav from '../common/landing-nav/Nav';

function LandingPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='w-100 w-lg-50'>
          <div className='d-flex flex-column gap-5 position-div'>
            <img
              src={iinputTextImg}
              alt='iinput.com'
              className='txt-img'
              style={{ width: '40vw' }}
            />
            <div className='text'>
              <span className='green-text'>iinputs.com</span> is a{' '}
              <span className='green-text'>national tendering marketplace</span>{' '}
              for farming inputs. We connect Farmers with Suppliers of
              <span className='green-text'>
                {' '}
                AgChem, Fertiliser, Fuel & Seed
              </span>{' '}
              through our unique tendering process.
            </div>
            <div className='mt-4 d-flex flex-row gap-4'>
              <button className='cutom-btn' onClick={() => navigate('/login')}>
                <span className='mx-4'>Login</span>
                <img src={signInIcon} alt='signin img' className='btn-img' />
              </button>
              <button
                className='cutom-btn signup-btn'
                onClick={() => navigate('/signup')}
              >
                <span className='mx-4'>Sign Up</span>
                <img src={signUpIcon} alt='signin img' className='btn-img' />
              </button>
            </div>
            <div className='d-flex flex-row gap-3 mt-5'>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://www.facebook.com/iinputscom/'
              >
                <img src={facebook} alt='facebook' />
              </a>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://twitter.com/iinputs'
              >
                <img src={twitter} alt='twitter' />
              </a>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-center align-items-center w-100 w-lg-50'>
          <img src={mobile_image} alt='hero' className='hero-img' />
        </div>
      </div>

      <Container
        fluid
        className='products d-flex flex-column justify-content-center align-items-center'
      >
        <div
          className='d-flex flex-row align-items-center gap-4'
          style={{ padding: '7.31rem' }}
        >
          <div className='prod-text'>Products</div>
          <div>
            <img
              className='responsive-icon'
              src={products}
              alt='products symbol'
            />
          </div>
        </div>
        <div className='d-flex flex-lg-row flex-column items-container'>
          <div className='container-ladning'>
            <img
              src={chemical}
              alt='chemical symbol'
              style={{ height: '300px' }}
            />
            <div className='chemicals-text'>CHEMICAL</div>
          </div>
          <div className='container-ladning'>
            <img
              src={fertiliser}
              alt='chemical symbol'
              style={{ height: '300px' }}
            />
            <div className='chemicals-text'>FERTILISER</div>
          </div>
          <div className='container-ladning'>
            <img src={fuel} alt='chemical symbol' style={{ height: '300px' }} />
            <div className='chemicals-text'>FUEL</div>
          </div>
          <div className='container-ladning'>
            <img src={seed} alt='chemical symbol' style={{ height: '300px' }} />
            <div className='chemicals-text'>SEED</div>
          </div>
        </div>
      </Container>

      <div className='mt-4'>
        <Container fluid>
          <div className='mt-5 Gugi-Font primary-color text-center d-flex flex-row gap-4 justify-content-center align-items-center'>
            <div className='gear-text'>How it works</div>
            <img className='responsive-gear-icon' src={gearIcon} alt='src' />
          </div>
          <div className='py-5 d-flex align-items-center justify-content-center'>
            <iframe
              width='980'
              height='580'
              src='https://www.youtube.com/embed/Qoeugg2vdGI?si=RDvrGfha34DGCsfA'
              title='YouTube video player'
              frameborder='0'
              allow='fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen
            ></iframe>
          </div>
        </Container>
      </div>

      <div>
        <img
          src='/assets/landing/Quotes.png'
          alt='quotes'
          className='w-100 mt-4'
        />
      </div>

      <Container
        fluid
        className='products d-flex flex-column justify-content-center align-items-center'
      >
        <div
          className='d-flex flex-row align-items-center gap-4'
          style={{ padding: '7.31rem' }}
        >
          <div className='prod-text'>Features</div>
          <div>
            <img
              className='responsive-icon'
              src={features}
              alt='features symbol'
            />
          </div>
        </div>
        <div className='d-flex flex-lg-row flex-column items-container'>
          <div className='container-ladning'>
            <img
              src={anonymous}
              alt='anonymous symbol'
              style={{ height: '144px' }}
            />
            <div className='features-text'>
              Farmers submit a tender anonymously, only reveal your identity
              once you accept a quote.
            </div>
          </div>
          <div className='container-ladning'>
            <img
              src={buyingGroup}
              alt='group symbol'
              style={{ height: '144px' }}
            />
            <div className='features-text'>
              iinputs.com allows farmers to create their own buying groups.
              contact admin for your unique login details!
            </div>
          </div>
          <div className='container-ladning'>
            <img
              src={network}
              alt='network symbol'
              style={{ height: '144px' }}
            />
            <div className='features-text'>
              With a click of a button, your Tender goes out to a national
              network of suppliers. More suppliers than you could ever dream of
              reaching via email!
            </div>
          </div>
        </div>
      </Container>

      <Container
        fluid
        className='d-flex flex-column justify-content-center align-items-center'
      >
        <div
          className='d-flex flex-row align-items-center gap-4'
          style={{ padding: '7.31rem' }}
        >
          <div className='prod-text'>Featured in</div>
          <div>
            <img className='responsive-icon' src={news} alt='products symbol' />
          </div>
        </div>
        <div className='d-flex flex-row items-container'>
          <div className='container-ladning'>
            <img
              src={stockJournal}
              alt='anonymous symbol'
              style={{ width: '20vw' }}
            />
          </div>
          <div className='container-ladning'>
            <img src={theLand} alt='group symbol' style={{ width: '20vw' }} />
          </div>
          <div className='container-ladning'>
            <img
              src={stockLand}
              alt='network symbol'
              style={{ width: '20vw' }}
            />
          </div>
        </div>
        <div
          className='d-flex flex-row  items-container pt-5'
          style={{ paddingBottom: '120px' }}
        >
          <div className='container-ladning'>
            <img
              src={queensLand}
              alt='anonymous symbol'
              style={{ width: '20vw' }}
            />
          </div>
          <div className='container-ladning'>
            <img
              src={weeklyTimes}
              alt='group symbol'
              style={{ width: '20vw' }}
            />
          </div>
          <div className='container-ladning'>
            <img
              src={register}
              alt='network symbol'
              style={{ width: '20vw' }}
            />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default LandingPage;
