import React, { useEffect, useState } from 'react';
import './ReviewQuote.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFiles } from '../../../services/s3Service';
import { quoteActions } from '../../../store/slices/quoteSlice';
import moment from 'moment';
import {
  SUBMIT_MODAL_TYPES,
  TENDER_DETAIL_TABS,
  TRANSPORT,
  errorToast,
} from '../../../constants';
import { toast } from 'react-hot-toast';
import * as _ from 'lodash';
import SpinnerLoader from '../../common/spinner/Spinner';
import CreatedModal from '../../modals/created-modal/CreatedModal';
import {
  attachmentsView,
  numberToCurrency,
  tenderInfoDiv,
} from '../../../services/utilService';
import TenderDetailHeader from '../../common/tender-detail-header/TenderDetailHeader';

function ReviewQuote() {
  const [showSpinner, setSpinner] = useState(false);
  const [showModal, setModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const quoteState = useSelector((state) => state.quote);
  const quote = useSelector((state) => state.quote?.quote);
  const tender = useSelector((state) => state.tender?.tender);
  const user = useSelector((state) => state.user?.user);

  const saveQuote = async () => {
    try {
      let newQuote = _.cloneDeep(quote);
      setSpinner(true);
      if (quote?.files.length) {
        newQuote.attachments = await uploadFiles(quote.files, 'quotes/');
      }
      delete newQuote.files;
      newQuote.tender_id = tender._id;
      dispatch(quoteActions.createQuoteRequest(newQuote));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSpinner(quoteState.loading);
    if (quoteState.error) {
      toast.error(quoteState.error, errorToast);
    }

    if (quoteState.response?.message) {
      setModal(true);
    }

    return () => {
      dispatch(quoteActions.clearErrors());
    };
  }, [
    quoteState.response?.message,
    quoteState.error,
    quoteState.loading,
    dispatch,
    navigate,
  ]);

  const handleEdit = () => {
    navigate(`/${user?.role}/create-quote/${tender._id}`);
  };

  const detailDiv = (title, content) => {
    return (
      <Col sm='12' className='mb-4'>
        <div className='secondary-text-color mb-2'>{title}</div>
        <div className='pre-wrap'>{content}</div>
      </Col>
    );
  };

  const handleClose = () => {
    setModal(false);
    navigate(
      `/${user?.role}/tender-detail/${tender._id}/${TENDER_DETAIL_TABS.quoteDetail}`
    );
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container fluid>
        <div className='auth-box tender-detail-box my-4 px-5'>
          <TenderDetailHeader
            showFeeInvoice={true}
            tender={tender}
            quote={quote}
          />

          <div className='d-flex tender-info-div align-items-center mt-4'>
            {tenderInfoDiv(
              '/assets/icons/supplier/Delivery.svg',
              `${tender?.transport} Location:`,
              tender?.post_code
            )}

            {tenderInfoDiv(
              '/assets/icons/supplier/Calendar.svg',
              'Date Of Supply:',
              moment(tender?.completion_date).format('DD-MM-YYYY')
            )}

            {tenderInfoDiv(
              '/assets/icons/supplier/duration.svg',
              'Tender Duration:',
              moment(tender?.duration[1]).diff(tender?.duration[0], 'days') +
                1 +
                ' days'
            )}
          </div>
        </div>

        <div className='auth-box tender-detail-box footer-space'>
          <Row className='mb-4'>
            <Col sm='12'>
              <h2>Summary</h2>
              <h6 className='secondary-text-color'>
                Here's an overview of your quote before you submit.
              </h6>
            </Col>
          </Row>

          <Row className='mb-4 review-inner'>
            <div className='inner-header p-3 d-flex align-items-center justify-content-between'>
              <h6 className='text-white mb-0'>Quote Details</h6>
              <img
                src='/assets/images/edit.svg'
                role='button'
                onClick={handleEdit}
                alt='edit'
              />
            </div>
            <div className='p-3'>
              <Row>
                {!quote?.quote_shipping
                  ? detailDiv(
                      quote?.transport === TRANSPORT.delivery
                        ? 'Transport Option'
                        : `${quote?.transport} Location`,
                      quote?.transport === TRANSPORT.delivery
                        ? 'Deliver to Farm'
                        : quote?.post_code
                    )
                  : ''}

                {!quote?.quote_shipping
                  ? detailDiv(
                      `${quote?.transport} Date`,
                      moment(quote?.completion_date).format('DD-MM-YYYY')
                    )
                  : ''}

                {quote?.quote_shipping
                  ? detailDiv('Transport', quote?.quote_shipping)
                  : ''}

                {detailDiv('Quote Details', quote?.details || 'N/A')}

                {detailDiv(
                  'Total Price (Includes GST)',
                  numberToCurrency(quote?.price)
                )}

                {detailDiv(
                  'Quote Validity',
                  quote?.quote_validity
                    ? moment(quote?.quote_validity).format('DD-MM-YYYY')
                    : 'N/A'
                )}

                {detailDiv('Payment Terms', quote?.payment_terms || 'N/A')}

                <Col sm='12' className='mb-4'>
                  <div className='secondary-text-color mb-2'>Attachments</div>
                  {quote?.files.length ? (
                    <div className='thumbsContainer'>
                      {attachmentsView(quote?.files)}
                    </div>
                  ) : (
                    'N/A'
                  )}
                </Col>
              </Row>
            </div>
          </Row>
        </div>
        <div className='tender-footer'>
          <div className='px-3 d-flex align-items-center justify-content-between'>
            <div>
              <Button
                onClick={handleEdit}
                type='button'
                className='border-btn back-btn'
              >
                <div className='d-flex align-items-center'>
                  <span>
                    <img
                      className='me-2'
                      alt='add'
                      src='/assets/icons/supplier/Arrow-Back.svg'
                    />
                  </span>
                  <span>Back</span>
                </div>
              </Button>
            </div>
            <div>
              <Button onClick={saveQuote} type='button' className='primary-btn'>
                Submit Quote
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <CreatedModal
        show={showModal}
        handleClose={() => setModal(false)}
        loading={quoteState.loading}
        click={handleClose}
        type={SUBMIT_MODAL_TYPES.quote}
      />
    </>
  );
}

export default ReviewQuote;
