import React, { useState } from 'react';
import './FundsTransfer.css';
import { Container } from 'react-bootstrap';
import OneButtonModal from '../../modals/one-button-modal/OneButtonModal';
import {
  FUND_TRANSFERRED_TITLE,
  FUND_TRANSFERRED_MSg,
  TENDER_DETAIL_TABS,
} from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SpinnerLoader from '../../common/spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { quoteActions } from '../../../store/slices/quoteSlice';
import { getTenderID, numberToCurrency } from '../../../services/utilService';

function FundsTransfer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const quote = useSelector((state) => state.quote.quote);
  const tender = useSelector((state) => state.tender.tender);

  const handleClose = () => {
    setShowModal(!showModal);
    navigate(
      `/farmer/tender-detail/${tender._id}/${TENDER_DETAIL_TABS.tenderDetail}`
    );
  };

  const showTenderDetails = () => {
    setShowModal(true);
    setSpinner(true);
    dispatch(
      quoteActions.updateQuoteRequest({
        fundsTransferred: true,
        _id: quote?._id,
      })
    );
    setSpinner(false);
  };
  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      {showModal && (
        <OneButtonModal
          title={FUND_TRANSFERRED_TITLE}
          show={showModal}
          handleClose={() => setShowModal(!showModal)}
          message={FUND_TRANSFERRED_MSg}
          btn={'View Tender Details'}
          click={handleClose}
        />
      )}
      {!quote.fundsTransferred ? (
        <Container className="mt-4">
          <h5>
            <span className="heading-text"> Listing &gt;</span>{' '}
            <span> Details</span>
          </h5>
          <div className="funds-transfer-container">
            {/* top row  */}
            <div className="flex-view">
              <div className="title-and-id flex-view">
                <p className="heading-text normal-font mt-1">
                  #{getTenderID(tender?._id)} :
                </p>
                <p className="large-font ms-2">{tender?.title}</p>
              </div>

              <div>
                <p className="large-font">
                  Quotes {numberToCurrency(quote?.price)}
                </p>
              </div>
            </div>

            {/* second row */}
            <div className="flex-view">
              <div className="title-and-id flex-view">
                <p className="heading-text small-font">Date Of Supply:</p>
                <p className="content-text mx-2 small-font">
                  {moment(quote?.completion_date).format('ddd, MMM DD')}
                </p>
              </div>
              <div className="title-and-id flex-view">
                <p className="heading-text small-font">Inspection Period:</p>
                <p className="content-text mx-2 small-font">3 Days</p>
              </div>
            </div>

            {/* block container */}
            <div className="block">
              <div className="block-conatiner">
                <p className="heading-text large-font">
                  Please transfer{' '}
                  <span style={{ color: '#1d1d1b' }}>
                    {numberToCurrency(quote?.price)}
                  </span>
                  to the following account
                </p>
                <div className="flex-view normal-font">
                  <p className="heading-text ">Company Name:</p>
                  <p className="content-text  px-5">iinputs.com</p>
                </div>

                <div className="flex-view normal-font">
                  <p className="heading-text ">BSB Number:</p>
                  <p className="content-text px-5">015462</p>
                </div>

                <div className="flex-view normal-font">
                  <p className="heading-text ">Account NUmber:</p>
                  <p className="content-text px-5 ">331525813</p>
                </div>
              </div>

              <div className="flex-view fund-btn">
                <button
                  className="btn btn-lg transfer-btn my-4 normal-font"
                  onClick={showTenderDetails}
                >
                  Funds Transferred
                </button>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}

export default FundsTransfer;
