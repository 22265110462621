import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import RetailerInvoice from '../../farmer/billing-history/BillingHistoryFarmer';
import MyInvoice from '../../supplier/billing-history/BillingHistorySupplier';
import {
  BILLING_FILTERS,
  BILLING_HITORY_TABS,
  RETAILER_BILLING_HITORY_TABS,
  ROLES,
} from '../../../constants';
import { generateUniqueId } from '../../../services/utilService';
import BillingModal from '../../modals/billing-modal/BillingModal';

function BillingHistoryRetailer() {
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState(BILLING_FILTERS);
  const [active, setActive] = useState(BILLING_HITORY_TABS.RetailerInvoice);

  const handleFilterModal = () => {
    setShowModal(!showModal);
  };

  const handleFilters = (filters) => {
    setFilters(filters);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <BillingModal
          showModal={showModal}
          handleClose={handleFilterModal}
          setFilters={handleFilters}
          filters={filters}
        />
      )}
      {/* <SpinnerLoader showSpinner={showSpinner} /> */}
      <Container className='mt-4'>
        <h5>Billing History</h5>
        <div className='no-bg'>
          {user?.user?.role === ROLES.retailer &&
            RETAILER_BILLING_HITORY_TABS.map((tab) => (
              <div
                key={generateUniqueId()}
                className={`mb-2 py-2 pe-3 text-center secondary-text-color d-inline-block ${
                  active === tab ? 'active-tab primary-color' : 'passive-tab'
                } `}
                onClick={() => {
                  setActive(tab);
                }}
                role='button'
              >
                {tab}
              </div>
            ))}
        </div>
        {/* <RetailerBillingHistory /> */}
        {active === BILLING_HITORY_TABS.RetailerInvoice && <RetailerInvoice />}
        {active === BILLING_HITORY_TABS.RetailerMyInvoice && <MyInvoice />}
      </Container>
    </>
  );
}

export default BillingHistoryRetailer;
