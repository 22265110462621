import React from 'react';
import { Button } from 'react-bootstrap';
import { IMAGES_ASSESTS_PATH, ROLES } from '../../../constants';
import moment from 'moment';
import { createRoom } from '../../../services/messagesService';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Rating } from 'react-simple-star-rating';

function AboutUser({ user, tender }) {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user.user);

  const startChat = async () => {
    const room = await createRoom({
      ids: [user?._id, userState._id],
      tenderId: tender?._id,
    });
    navigate(`/${userState.role}/messages`, { state: { room: room._id } });
  };

  return (
    <div className='about-box h-100'>
      <div className='h-100 d-flex flex-column justify-content-between'>
        <div className='d-flex justify-content-between block-small'>
          <div>
            <h6 className='mb-5 bold-text text-capitalize'>
              About {user?.role}
            </h6>
            <div className='d-flex align-items-center'>
              <img
                src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                className='large-icon me-3'
                alt='profile'
              />
              <div>
                <h6 className='bold-text'>{user?.name}</h6>
                {user?.rating?.totalRating ? (
                  <>
                    <div className='me-2'>
                      <Rating
                        size={25}
                        initialValue={user?.rating?.totalRating}
                        fillColor='green'
                        readonly
                      />
                    </div>
                    <div>
                      {parseInt(user?.rating?.totalRating).toFixed(1)} of{' '}
                      {user?.rating?.count} review(s)
                    </div>
                  </>
                ) : (
                  <>No ratings</>
                )}
              </div>
            </div>
            <div className='mt-4 d-flex align-items-center'>
              <img
                src={`${IMAGES_ASSESTS_PATH}/location.svg`}
                alt='location'
                className='me-3'
              />
              <span className='text-capitalize'>Location</span>
            </div>
            <div className='ms-5'>{user?.address}</div>
          </div>
          <div>
            <Button onClick={startChat} className='mt-5 blue-btn text-nowrap'>
              Chat
            </Button>
          </div>
        </div>
        <div className='mt-2'>
          Member since {moment(user?.createdAt).format('DD-MM-YYYY')}
        </div>
      </div>
    </div>
  );
}

export default AboutUser;
