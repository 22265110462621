import {
  IMAGES_ASSESTS_PATH,
  NOTIFICATIONS_TYPES,
  QUOTES_STATUSES,
  TENDER_DETAIL_TABS,
  TENDER_STATUSES,
} from '../constants';
import store from '../store';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import JSZip from 'jszip';
import FileSaver, { saveAs } from 'file-saver';
import { userActions } from '../store/slices/userSlice';
import jwtDecode from 'jwt-decode';
import { settingActions } from '../store/slices/settingSlice';

export const getErrorMessageFromAxios = (axiosObject) => {
  return axiosObject?.response?.data?.error
    ? axiosObject?.response?.data?.error
    : 'Something went wrong';
};

export const getAccessToken = () => {
  const state = store.getState();
  return state?.user?.user?.access_token || '';
};

export const getRefreshToken = () => {
  const state = store.getState();
  return state?.user?.user?.refresh_token || '';
};

export const updateTokens = (tokens) => {
  store.dispatch(userActions.updateTokens(tokens));
};

export const clearUser = () => {
  store.dispatch(userActions.clearUserRequest());
};

export const clearSettings = () => {
  store.dispatch(settingActions.clearRequest());
};

export const isRememberUser = () => {
  const state = store.getState();
  return state?.user?.user?.remember_me || false;
};

export const isTokenExpired = () => {
  const currentDate = new Date();
  const token = getAccessToken();
  if (!token) return true;
  const decodedToken = jwtDecode(token);
  return decodedToken.exp * 1000 < currentDate.getTime();
};

export const getFilePreview = async (file, local = true) => {
  const url = file?.url || file?.name;
  if (!url) {
    return '';
  }
  const parts = url.split('.');
  const ext = parts[parts.length - 1].toLowerCase();
  switch (ext) {
    case 'pdf':
      return local
        ? {
            preview: `${IMAGES_ASSESTS_PATH}/PDF.svg`,
            size: file.size,
            type: file.type,
            name: file.name,
            ext,
            previewed: false,
            data: await getBase64(file),
          }
        : `${IMAGES_ASSESTS_PATH}/PDF.svg`;

    case 'jpg':
    case 'jpeg':
    case 'png':
      return local
        ? {
            preview: await getBase64(file),
            size: file.size,
            type: file.type,
            name: file.name,
            ext,
            previewed: true,
          }
        : file.url;

    case 'doc':
    case 'docx':
      return local
        ? {
            preview: `${IMAGES_ASSESTS_PATH}/DOCX.svg`,
            size: file.size,
            type: file.type,
            name: file.name,
            ext,
            previewed: false,
            data: await getBase64(file),
          }
        : `${IMAGES_ASSESTS_PATH}/DOCX.svg`;

    case 'xls':
    case 'xlsx':
      return local
        ? {
            preview: `${IMAGES_ASSESTS_PATH}/Excel.svg`,
            size: file.size,
            type: file.type,
            name: file.name,
            ext,
            previewed: false,
            data: await getBase64(file),
          }
        : `${IMAGES_ASSESTS_PATH}/Excel.svg`;

    default:
      return local
        ? {
            preview: await getBase64(file),
            size: file.size,
            type: file.type,
            name: file.name,
            ext,
            previewed: true,
          }
        : file.url;
  }
};

export const getUploadedFilesPreview = (files) => {
  return files.map((file) => {
    const url = file.url;
    const parts = url.split('.');
    const ext = parts[parts.length - 1].toLowerCase();
    switch (ext) {
      case 'pdf':
        return { preview: `${IMAGES_ASSESTS_PATH}/PDF.svg` };

      case 'jpg':
      case 'jpeg':
      case 'png':
        return { preview: file.url };

      case 'doc':
      case 'docx':
        return { preview: `${IMAGES_ASSESTS_PATH}/DOCX.svg` };

      case 'xls':
      case 'xlsx':
        return { preview: `${IMAGES_ASSESTS_PATH}/pdf.svg` };

      default:
        return { preview: file.url };
    }
  });
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(new Error('Error reading file.'));
    };

    reader.readAsDataURL(file);
  });
};

export const base64ToBlob = (base64Data) => {
  const contentType = base64Data.substring(
    base64Data.indexOf(':') + 1,
    base64Data.indexOf(';')
  );
  const byteCharacters = atob(
    base64Data.substring(base64Data.indexOf(',') + 1)
  );
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const getTimeDiff = (time) => {
  let unit = 'minute';
  let diff = moment().diff(moment(time), 'minutes');
  if (diff >= 60 && unit === 'minute') {
    unit = 'hour';
    diff = moment().diff(moment(time), 'hours');
  }
  if (diff >= 24 && unit === 'hour') {
    unit = 'day';
    diff = moment().diff(moment(time), 'days');
  }
  return diff + ' ' + unit + '(s)';
};

export const generateFileName = (path, name, ext) => {
  return path + name + uuidv4() + '.' + ext;
};

export const generateUniqueId = () => uuidv4();

export const getFileNameFromURL = (url) => {
  if (!url) {
    return uuidv4();
  }
  const paths = url.split('/');
  const name = paths[paths.length - 1].split('.');
  const file = name[name.length - 2] || '';
  if (file.length > 10) {
    return file.substr(0, 10) + '...';
  }
  return file;
};

export const bytesToSize = (bytes) => {
  const kbs = bytes / 8 / 1024;
  return kbs < 1024 ? Math.round(kbs) + ' KB' : Math.round(kbs / 1024) + ' MB';
};

export const downloadFiles = async (fileUrls, zip = false) => {
  if (zip) {
    const zip = new JSZip();
    const promises = fileUrls.map(async (fileUrl) => {
      const response = await fetch(fileUrl + '?testing');
      const fileData = await response.blob();
      const paths = fileUrl.split('/');
      const fileName = paths[paths.length - 1];
      zip.file(fileName, fileData);
    });

    await Promise.all(promises);
    const zipFile = await zip.generateAsync({ type: 'blob' });
    FileSaver.saveAs(zipFile, 'files.zip');
  } else {
    const fileUrl = fileUrls[0];
    const paths = fileUrl.split('/');
    const fileName = paths[paths.length - 1];
    const response = await fetch(fileUrl + '?testing');
    const fileData = await response.blob();
    saveAs(fileData, fileName);
  }
};

export const tenderInfoDiv = (icon, title, value, vertical = false) => {
  return (
    <div className={`d-flex align-items-center ${vertical ? 'my-2' : 'me-5'}`}>
      <img className='me-2 small-icon' src={icon} alt={icon} />
      <h6 className='me-2 secondary-text-color mb-0'>{title}</h6>
      <span>{value}</span>
    </div>
  );
};

export const attachmentsView = (files) => {
  return files?.map((file) => (
    <div key={generateUniqueId()}>
      <div className={'thumb'}>
        <div className={'thumbInner'}>
          <img src={file.preview} className='thumb-img' alt='thumb' />
        </div>
      </div>
      <div>{getSubString(file.name)}</div>
    </div>
  ));
};

export const getStatusClass = (status) => {
  switch (status) {
    case TENDER_STATUSES.active:
      return 'status-active';

    case TENDER_STATUSES.inProgress:
    case TENDER_STATUSES.pending:
    case 'Pending Verification':
      return 'status-pending';

    case QUOTES_STATUSES.rejected:
    case 'Payment Outstanding':
      return 'status-rejected';

    case TENDER_STATUSES.dispute:
      return 'status-dispute';

    case 'Resolved':
      return 'status-resolved';

    default:
      return 'status-active';
  }
};

export const getTenderID = (id) => {
  return id ? id.substr(id.length - 6, id.length - 1) : '';
};

export const getSubString = (str) => {
  if (!str) {
    return '';
  }
  if (str.length > 12) {
    return str.substr(0, 12) + '...';
  }
  return str;
};

export const getDayOrDate = (date) => {
  const TODAY = moment().startOf('day');
  const YESTERDAY = moment().subtract(1, 'days').startOf('day');

  if (moment(date).isSame(TODAY, 'd')) {
    return 'Today';
  } else if (moment(date).isSame(YESTERDAY, 'd')) {
    return 'Today';
  } else {
    return moment(date).format('ddd, MMM DD');
  }
};

export const isSameDay = (date1, date2) => {
  return moment(date1).isSame(moment(date2), 'day');
};

export const getDayDifference = (date1, date2) => {
  return moment(date1).diff(moment(date2), 'days') + 1;
};

export const getNotificationImg = (type, data) => {
  switch (type) {
    case NOTIFICATIONS_TYPES.farmer_new_quote:
    case NOTIFICATIONS_TYPES.supplier_invoice_approved:
    case NOTIFICATIONS_TYPES.mark_disputed:
    case NOTIFICATIONS_TYPES.dispute_resolved:
      return data?.profileUrl || `${IMAGES_ASSESTS_PATH}/User-Profile.svg`;

    case NOTIFICATIONS_TYPES.farmer_quote_updated:
    case NOTIFICATIONS_TYPES.supplier_quote_accepted:
      return `${IMAGES_ASSESTS_PATH}/quote-notif.svg`;

    case NOTIFICATIONS_TYPES.farmer_tax_invoice:
    case NOTIFICATIONS_TYPES.admin_mark_paid:
      return `${IMAGES_ASSESTS_PATH}/invoice-notif.svg`;

    case NOTIFICATIONS_TYPES.new_tender:
    case NOTIFICATIONS_TYPES.farmer_mark_delivered:
      return `${IMAGES_ASSESTS_PATH}/complete-tender.svg`;

    default:
      return `${IMAGES_ASSESTS_PATH}/User-Profile.svg`;
  }
};

export const getNotifiNavigate = (type, data, role) => {
  switch (type) {
    case NOTIFICATIONS_TYPES.farmer_new_quote:
    case NOTIFICATIONS_TYPES.farmer_quote_updated:
      return `/${role}/quote-detail/${data?.quoteId}`;

    case NOTIFICATIONS_TYPES.farmer_tax_invoice:
      return `/${role}/tender-detail/${data?.tenderId}/${TENDER_DETAIL_TABS.hired}`;

    case NOTIFICATIONS_TYPES.supplier_invoice_approved:
      return `/${role}/tender-detail/${data?.tenderId}/${TENDER_DETAIL_TABS.quoteDetail}`;

    case NOTIFICATIONS_TYPES.supplier_quote_accepted:
      return `/${role}/tender-detail/${data?.tenderId}/${TENDER_DETAIL_TABS.quoteDetail}`;

    case NOTIFICATIONS_TYPES.mark_disputed:
    case NOTIFICATIONS_TYPES.dispute_resolved:
    case NOTIFICATIONS_TYPES.admin_mark_paid:
    case NOTIFICATIONS_TYPES.farmer_mark_delivered:
      return `/${role}/tender-detail/${data?.tenderId}/${TENDER_DETAIL_TABS.tenderDetail}`;

    case NOTIFICATIONS_TYPES.new_tender:
      return `/${role}/tender-detail/${data?.tenderId}/${TENDER_DETAIL_TABS.tenderDetail}`;

    default:
      return '';
  }
};

export const numberToCurrency = (number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(number);
};
