import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './billingModal.css';
import { useForm } from 'react-hook-form';
import CustomDatePicker from '../../common/date-picker/DatePicker';
import { QUOTES_STATUSES } from '../../../constants';

const BillingModal = ({ showModal, handleClose, setFilters, filters }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: filters,
  });

  const onSubmit = (data) => {
    setFilters(data);
  };

  return (
    <Modal show={showModal} onHide={handleClose} className="modal-lg">
      <Modal.Header closeButton className="modal-container">
        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="modal-container">
          <h4 className="mb-2">Supply Date</h4>
          <div className="date-inputs d-flex d-flex border-bottom mx-2">
            <div className="from-date form-group ">
              <label htmlFor="fromDate">From</label>
              <CustomDatePicker
                className={`form-input w-100 ${
                  errors['completion_date'] && 'invalid-input'
                }`}
                name="fromDate"
                control={control}
                setValue={setValue}
              />
            </div>
            <div className="to-date form-group mb-4">
              <label htmlFor="toDate">To</label>
              <CustomDatePicker
                className={`form-input w-100 ${
                  errors['completion_date'] && 'invalid-input'
                }`}
                name="toDate"
                control={control}
                setValue={setValue}
              />
            </div>
          </div>

          <h4 className="mb-3 mt-4">Amount</h4>
          <div className="amount-range d-flex border-bottom mx-2">
            <div className="from-date min-amount w-50">
              <label htmlFor="zipCode">Min</label>
              <input
                type="number"
                name="minAmount"
                id="minAmount"
                className="form-control"
                placeholder="Enter min amount"
                {...register('minAmount')}
              />
            </div>

            <div className="max-amount w-50 mb-4">
              <label htmlFor="maxAmount">To</label>
              <input
                type="number"
                name="maxAmount"
                id="maxAmount"
                className="form-control"
                placeholder="Enter max amount"
                {...register('maxAmount')}
              />
            </div>
          </div>

          <h4 className="mb-3 mt-4">Status</h4>
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="Awaiting Approval"
              {...register('awaiting_approval')}
            />
            <label className="form-check-label">{QUOTES_STATUSES.sent}</label>
          </div>
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="Approved"
              {...register('approved')}
            />
            <label className="form-check-label">
              {QUOTES_STATUSES.accepted}
            </label>
          </div>
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="Cancelled"
              {...register('cancelled')}
            />
            <label className="form-check-label">
              {QUOTES_STATUSES.rejected}
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-container">
          <div className="modal-action-buttons d-flex justify-content-between mt-5 w-100">
            <Button className="btn btn-light text-dark" onClick={() => reset()}>
              Clear filters
            </Button>
            <Button className="btn btn-success" type="submit">
              Apply filters
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default BillingModal;
