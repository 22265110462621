import React, { useEffect, useState } from 'react';
import './TenderListing.css';
import TenderListingHeader from '../tender-listing-header/TenderListingHeader';
import { useDispatch, useSelector } from 'react-redux';
import { tenderActions } from '../../../store/slices/tenderSlice';
import SpinnerLoader from '../spinner/Spinner';
import { ROLES, defaultPageMeta, errorToast } from '../../../constants';
import { toast } from 'react-hot-toast';
import TenderListingItem from '../tender-listing-item/TenderListingItem';
import { Container } from 'react-bootstrap';
import SortBy from '../sort-by/SortBy';
import Pages from '../pagination/Pagination';
import { generateUniqueId } from '../../../services/utilService';

function TenderListingFarmer() {
  const [showSpinner, setSpinner] = useState(true);
  const [tenders, setTenders] = useState([]);
  const [meta, setMeta] = useState(defaultPageMeta);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState('');
  const dispatch = useDispatch();
  const tender = useSelector((state) => state.tender);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user?.user?.role === ROLES.supplier) {
      dispatch(
        tenderActions.getUserTenders({
          size: defaultPageMeta.per_page,
          pageNo: defaultPageMeta.current_page,
        })
      );
    } else {
      dispatch(
        tenderActions.getOtherTenders({
          size: defaultPageMeta.per_page,
          pageNo: defaultPageMeta.current_page,
        })
      );
    }
  }, [dispatch, user]);

  useEffect(() => {
    setSpinner(tender.loading);
    if (tender.error) {
      toast.error(tender.error, errorToast);
    }
    if (tender.response) {
      setTenders(tender.response.data);
      setMeta(tender.response.meta);
      setSpinner(false);
    }
    return () => {
      dispatch(tenderActions.clearErrors());
    };
  }, [dispatch, tender.error, tender.loading, tender.response]);

  const handleFilters = (filters) => {
    setFilters(filters);
    if (user?.user?.role === ROLES.supplier) {
      dispatch(
        tenderActions.getUserTenders({
          ...filters,
          sort,
          ...{ size: meta.per_page, pageNo: 1 },
        })
      );
    } else {
      dispatch(
        tenderActions.getOtherTenders({
          ...filters,
          sort,
          ...{ size: meta.per_page, pageNo: 1 },
        })
      );
    }
  };

  const handleSort = (value) => {
    setSort(value);
    if (user?.user?.role === ROLES.supplier) {
      dispatch(
        tenderActions.getUserTenders({
          ...filters,
          sort: value,
          ...{ size: meta.per_page, pageNo: meta.current_page },
        })
      );
    } else {
      dispatch(
        tenderActions.getOtherTenders({
          ...filters,
          sort: value,
          ...{ size: meta.per_page, pageNo: meta.current_page },
        })
      );
    }
  };

  const pageClick = (pageNo) => {
    setMeta((prev) => {
      return { ...prev, current_page: pageNo };
    });
    if (user?.user?.role === ROLES.supplier) {
      dispatch(
        tenderActions.getUserTenders({
          ...filters,
          sort,
          ...{ size: meta.per_page, pageNo },
        })
      );
    } else {
      dispatch(
        tenderActions.getOtherTenders({
          ...filters,
          sort,
          ...{ size: meta.per_page, pageNo },
        })
      );
    }
  };

  const pageSize = (size) => {
    setMeta((prev) => {
      return { ...prev, per_page: size };
    });
    if (user?.user?.role === ROLES.supplier) {
      dispatch(
        tenderActions.getUserTenders({
          ...filters,
          sort,
          ...{ size, pageNo: 1 },
        })
      );
    } else {
      dispatch(
        tenderActions.getOtherTenders({
          ...filters,
          sort,
          ...{ size, pageNo: 1 },
        })
      );
    }
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='mt-4'>
        <TenderListingHeader
          count={tenders?.length}
          getFilters={handleFilters}
        />
        <div className='my-4'>
          <SortBy sort={handleSort} />
        </div>
        <div className='mt-3'>
          {tenders && tenders.length ? (
            <>
              {tenders.map((tender) => (
                <TenderListingItem key={generateUniqueId()} tender={tender} />
              ))}
              <Pages meta={meta} pageClick={pageClick} pageSize={pageSize} />
            </>
          ) : (
            <Container className='p-5'>No Records Found</Container>
          )}
        </div>
      </div>
    </>
  );
}

export default TenderListingFarmer;
