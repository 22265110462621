import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TenderDetailHeader from '../tender-detail-header/TenderDetailHeader';
import {
  downloadFiles,
  generateUniqueId,
  tenderInfoDiv,
} from '../../../services/utilService';
import moment from 'moment';
import AttachmentCard from '../cards/AttachmentCard';
import { tenderActions } from '../../../store/slices/tenderSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QUOTES_STATUSES, ROLES, TENDER_STATUSES } from '../../../constants';
import { Rating } from 'react-simple-star-rating';

function TenderDetailTab({
  tender,
  tender1,
  showFeeInvoice,
  setSpinner,
  isPending,
  userId,
  role,
  quote,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDownload = async () => {
    setSpinner(true);
    const urls = tender.attachments.map((attachment) => attachment.url);
    await downloadFiles(urls, true);
    setSpinner(false);
  };

  const detailDiv = (title, content) => {
    return (
      <Col sm='12' className='mb-4'>
        <div className='secondary-text-color mb-2'>{title}</div>
        <div className='pre-wrap'>{content}</div>
      </Col>
    );
  };

  const handleEdit = () => {
    dispatch(tenderActions.persistTender(tender));
    navigate(`/${role}/create-tender`);
  };

  const showEdit = () =>
    role === ROLES.supplier || userId !== tender?.user_id._id
      ? false
      : tender?.status === TENDER_STATUSES.active;

  return (
    <Container fluid>
      <div className='auth-box tender-detail-box'>
        <TenderDetailHeader
          showFeeInvoice={showFeeInvoice}
          tender={tender}
          isPending={isPending}
        />
        <Row className='mb-4 review-inner'>
          <div className='inner-header p-3 d-flex align-items-center justify-content-between'>
            <h6 className='text-white mb-0'>Tender Details</h6>
            {showEdit() && (
              <img
                src='/assets/images/edit.svg'
                role='button'
                onClick={handleEdit}
                alt='edit'
              />
            )}
          </div>
          <div className='p-3'>
            <Row>
              <div className='mb-4 d-flex tender-info-div align-items-center'>
                {tenderInfoDiv(
                  '/assets/icons/supplier/Calendar.svg',
                  'Delivery Date:',
                  moment(tender?.completion_date).format('DD-MM-YYYY')
                )}

                {tenderInfoDiv(
                  '/assets/icons/supplier/duration.svg',
                  'Tender Duration:',
                  moment(tender?.duration[1]).diff(
                    tender?.duration[0],
                    'days'
                  ) +
                    1 +
                    ' days'
                )}

                {tenderInfoDiv(
                  '/assets/icons/Success-Rate.svg',
                  'Farmer Tender Success Rate:',
                  (
                    (tender1?.closed_user_tenders /
                      tender1?.total_user_tenders) *
                    100
                  ).toFixed(2) + '%'
                )}
                {quote && quote.status === QUOTES_STATUSES.accepted && (
                  <div className='ms-2 mt-3'>
                    <div className='farmer-name'>{tender?.user_id?.name}</div>
                    <div>
                      <div className='d-flex justify-content-between'>
                        <Rating
                          size={25}
                          initialValue={tender?.user_id?.rating?.totalRating}
                          fillColor='green'
                          readonly
                        />
                      </div>
                      <div>
                        {parseInt(tender?.user_id?.rating?.totalRating).toFixed(
                          1
                        )}{' '}
                        of {tender?.user_id?.rating?.count} review(s)
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {detailDiv('Order/Product Details', tender?.details || 'N/A')}

              {tender?.attachments?.length ? (
                <div className='mt-3'>
                  <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h5>Attachments</h5>
                    <div
                      onClick={handleDownload}
                      role='button'
                      className='primary-color'
                    >
                      <span>Download all</span>
                      <img
                        className='download-icon ms-2 mb-2'
                        src={'/assets/icons/supplier/Download.svg'}
                        alt='download'
                      />
                    </div>
                  </div>

                  <Row>
                    {tender?.attachments.map((attachment) => (
                      <AttachmentCard
                        key={generateUniqueId()}
                        attachment={attachment}
                        setSpinner={setSpinner}
                      />
                    ))}
                  </Row>
                </div>
              ) : (
                <>{detailDiv('Attachments', 'N/A')}</>
              )}
            </Row>
          </div>
        </Row>

        <Row className='mb-4 review-inner'>
          <div className='inner-header p-3 d-flex align-items-center justify-content-between'>
            <h6 className='text-white mb-0'>Shipping Details</h6>
            {showEdit() && (
              <img
                src='/assets/images/edit.svg'
                role='button'
                onClick={handleEdit}
                alt='edit'
              />
            )}
          </div>
          <div className='p-3'>
            <Row>
              {tender?.user_id?.role !== ROLES.retailer
                ? detailDiv('Town Closest to Farm', tender?.post_code)
                : ''}

              {tender?.user_id?.role !== ROLES.retailer
                ? detailDiv(
                    'Transport Option',
                    tender?.transport === 'Delivery'
                      ? 'Delivered to Farm'
                      : tender?.transport
                  )
                : ''}

              {tender?.transport === 'Delivery' &&
              tender?.user_id?.role !== ROLES.retailer
                ? detailDiv(
                    'Farm Distance from Town Center',
                    tender?.farm_distance || 'N/A'
                  )
                : ''}

              {tender?.transport === 'Pickup' &&
              tender?.user_id?.role !== ROLES.retailer
                ? detailDiv(
                    'Pickup Radius from Town Centre',
                    tender?.pickup_radius || 'N/A'
                  )
                : ''}

              {detailDiv(
                tender?.user_id?.role !== ROLES.retailer
                  ? 'Extra Shipping Instructions'
                  : 'Shipping Instructions',
                tender?.extra_info || 'N/A'
              )}
            </Row>
          </div>
        </Row>

        <Row className='mb-4 review-inner'>
          <div className='inner-header p-3 d-flex align-items-center justify-content-between'>
            <h6 className='text-white mb-0'>Payment Details</h6>
            {showEdit() && (
              <img
                src='/assets/images/edit.svg'
                role='button'
                onClick={handleEdit}
                alt='edit'
              />
            )}
          </div>
          <div className='p-3'>
            <Row>
              {detailDiv('Payment Option', tender?.payment_option)}

              {detailDiv('Method', tender?.payment_mode)}
            </Row>
          </div>
        </Row>
      </div>
    </Container>
  );
}

export default TenderDetailTab;
