import { createSlice } from '@reduxjs/toolkit';
import { quoteReducer } from '../reducers/quoteReducer';
import { quoteState } from '../states/quoteStates';

const quoteSlice = createSlice({
  name: 'quote',
  initialState: quoteState,
  reducers: quoteReducer,
});

export const quoteActions = quoteSlice.actions;
export default quoteSlice;
