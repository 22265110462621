import { takeLatest, call, put, all } from 'redux-saga/effects';
import { notificationActions } from '../slices/notificationSlice';
import { fetchNotifications } from '../../services/notificationService';

function* myNotificationsSaga(action) {
  try {
    const response = yield call(fetchNotifications, action.payload);
    yield put(notificationActions.requestSuccess(response));
  } catch (error) {
    yield put(notificationActions.requestFailure(error));
  }
}

function* notificationSaga() {
  yield all([
    takeLatest(
      notificationActions.myNotificationsRequest.type,
      myNotificationsSaga
    ),
  ]);
}

export default notificationSaga;
