import React from 'react';
import './SortBy.css';
import DropDownIcon from '../../../assets/icons/arrow-down-up.svg';
import Dropdown from 'react-bootstrap/Dropdown';

const SortBy = ({ sort }) => {
  return (
    <>
      <div className='container d-flex justify-content-between align-items-center'>
        <h4 className='tender-listing'>Tender Listing</h4>

        <div className='dropdown'>
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              id='dropdown-basic'
              className='btn btn-light sort-by-btn'
            >
              <img src={DropDownIcon} alt='sort' className='icon-small me-2' />
              <small>Sort by</small>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => sort('completion_date')}>
                Supply Date
              </Dropdown.Item>
              <Dropdown.Item onClick={() => sort('days')}>
                Tender Duration
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default SortBy;
