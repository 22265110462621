import React, { useEffect, useState } from 'react';
import './Notifications.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  IMAGES_ASSESTS_PATH,
  defaultPageMeta,
  errorToast,
} from '../../../constants';
import { notificationActions } from '../../../store/slices/notificationSlice';
import { toast } from 'react-hot-toast';
import { Container } from 'react-bootstrap';
import SpinnerLoader from '../spinner/Spinner';
import {
  generateUniqueId,
  getNotifiNavigate,
  getNotificationImg,
} from '../../../services/utilService';
import moment from 'moment';
import Pages from '../pagination/Pagination';
import {
  markRead,
  updateNotification,
} from '../../../services/notificationService';
import _ from 'lodash';

function Notifications() {
  const notification = useSelector((state) => state.notifications);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [meta, setMeta] = useState(defaultPageMeta);
  const [notifications, setNotifications] = useState([]);
  const [showSpinner, setSpinner] = useState(true);
  const [showRead, setShowRead] = useState(false);

  useEffect(() => {
    dispatch(
      notificationActions.myNotificationsRequest({
        size: defaultPageMeta.per_page,
        pageNo: defaultPageMeta.current_page,
        showRead,
      })
    );
  }, [dispatch, showRead]);

  useEffect(() => {
    if (notification.error) {
      toast.error(notification.error, errorToast);
      setSpinner(false);
    }
    if (notification.response) {
      setNotifications(notification.response.data || []);
      setMeta(notification.response.meta);
      setSpinner(false);
    }
    return () => {
      dispatch(notificationActions.clearErrors());
    };
  }, [
    dispatch,
    notification.error,
    notification.loading,
    notification.response,
    setSpinner,
  ]);

  const pageClick = (pageNo) => {
    setMeta((prev) => {
      return { ...prev, current_page: pageNo };
    });
    dispatch(
      notificationActions.myNotificationsRequest({
        size: meta.per_page,
        pageNo,
        showRead,
      })
    );
  };

  const pageSize = (size) => {
    setMeta((prev) => {
      return { ...prev, per_page: size };
    });
    dispatch(
      notificationActions.myNotificationsRequest({
        size,
        pageNo: 1,
        showRead,
      })
    );
  };

  const navigateNotification = async (notif) => {
    setSpinner(true);
    await updateNotification({
      _id: notif._id,
      isRead: true,
    });
    const url = getNotifiNavigate(notif?.type, notif?.data, user?.user?.role);
    url && navigate(url);
    setSpinner(false);
  };

  const clickToggle = () => {
    setShowRead((prev) => !prev);
  };

  const markAllRead = async () => {
    setSpinner(true);
    await markRead();
    setNotifications((prev) => {
      return prev.map((notif) => {
        let newNotif = _.cloneDeep(notif);
        newNotif.isRead = true;
        return newNotif;
      });
    });
    setSpinner(false);
  };

  return (
    <div className="mt-5">
      <Container fluid>
        <SpinnerLoader showSpinner={showSpinner} />
        <div className="auth-box tender-detail-box p-0">
          <div className="separate-small notif-header border-bottom d-flex align-items-center justify-content-between">
            <h5 className="mb-0 bold-text">
              Notifications{' '}
              {notifications.length > 0 && (
                <span>{`(${notifications.length})`}</span>
              )}
            </h5>
            <div className="d-flex">
              <small
                role="button"
                onClick={clickToggle}
                className="d-flex align-items-center"
              >
                <span className="form-check form-switch d-inline-block mb-0">
                  <input
                    className="form-check-input custom-color"
                    type="checkbox"
                    id="messageUpdates"
                    role="button"
                    checked={showRead}
                  />
                </span>
                Only show unread
              </small>
              <small onClick={markAllRead} role="button" className="ms-4">
                Mark all as read
                <span>
                  <img
                    src={`${IMAGES_ASSESTS_PATH}/read-notif.svg`}
                    alt="read"
                  />
                </span>
              </small>
            </div>
          </div>
          <div>
            {notifications.length ? (
              notifications.map((notif) => (
                <div
                  onClick={() => navigateNotification(notif)}
                  role="button"
                  key={generateUniqueId()}
                  className={`py-3 px-5 border-bottom ${
                    !notif?.isRead && 'unread-notif'
                  }`}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={getNotificationImg(notif?.type, notif?.data)}
                      alt="profile"
                      className="small-icon me-2 border-50"
                    />
                    <div>{notif?.title}</div>
                  </div>
                  <div className="notif-date mt-2 d-flex align-items-center justify-content-between">
                    <small className="secondary-text-color">
                      {moment(notif?.createdAt).format('DD-MM-YYYY')}
                    </small>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-5 text-center">No Notifications</div>
            )}
          </div>
        </div>
        {notifications && notifications.length ? (
          <Pages
            meta={meta}
            pageClick={pageClick}
            pageSize={pageSize}
            defaultPage={30}
          />
        ) : (
          <></>
        )}
      </Container>
    </div>
  );
}

export default Notifications;
