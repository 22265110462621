import React, { useEffect, useState, useRef } from 'react';
import './EditProfile.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IMAGES_ASSESTS_PATH, ROLES } from '../../../constants';
import { Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { userActions } from '../../../store/slices/userSlice';
import { getFilePreview } from '../../../services/utilService';
import { uploadFiles } from '../../../services/s3Service';
import ReactGoogleAutocomplete from 'react-google-autocomplete';

const EditProfile = () => {
  const inputRef = useRef(null);
  const { id } = useParams();
  const [profile, setProfile] = useState({});
  const user = useSelector((state) => state.user);
  const [profileUrl, setProfileUrl] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState();
  const [profileUrlChange, setProfileUrlChange] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues: { ...user.user } });

  useEffect(() => {
    setProfile(user?.user);
  }, [user?.user]);

  const handleCancel = () => {
    navigate(`/${profile?.role}/profile`);
  };

  const onSubmit = async (data) => {
    let url;
    let formData;
    if (profileUrlChange) {
      url = await uploadFiles([profileUrl], 'profile_images/');

      formData = {
        ...data,
        profileUrl: url[0]?.url || user?.user?.profileUrl,
      };
    } else {
      formData = { ...data };
    }

    dispatch(userActions.updateProfile(formData));
    dispatch(userActions.updateUser(formData));
    navigate(`/${profile?.role}/profile`);
  };

  const changePhoto = (e) => {
    inputRef.current.click();
  };

  const removePhoto = (e) => {
    dispatch(userActions.updateProfile({ profileUrl: null }));
    setProfile((prevProfile) => ({
      ...prevProfile,
      profileUrl: null,
    }));
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const file = await getFilePreview(fileObj);
    setProfileUrl(file);
    setFileName(fileObj?.name);
    setProfileUrlChange(true);
    setProfile((prevProfile) => ({
      ...prevProfile,
      profileUrl: file.preview,
    }));
  };

  return (
    <div className='container mt-3 edit-container p-3 border rounded'>
      {/* supplier portion */}
      <form action='' onSubmit={handleSubmit(onSubmit)}>
        {(profile?.role === ROLES.supplier ||
          profile?.role === ROLES.retailer) && (
          <>
            {id === '1' && (
              <div className='content-section'>
                <div className='edit-heading common-margin'>
                  <div className='edit-info'>Edit Information</div>
                  <div className='edit-title'>Update your Information here</div>
                </div>
                <hr />
                <div className='border edit-flex-start image-section common-margin p-2'>
                  <div className='mx-4 my-1'>
                    {profile?.profileUrl ? (
                      <img
                        src={profile?.profileUrl}
                        alt=''
                        className='user-image'
                      />
                    ) : (
                      <img
                        src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                        alt=''
                        className='user-image'
                      />
                    )}
                  </div>
                  <div>
                    <div className='edit-flex-start'>
                      <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type='file'
                        onChange={handleFileChange}
                      />
                      <button
                        className='change-btn btn mx-2 my-1'
                        onClick={changePhoto}
                        type='button'
                      >
                        {profile?.profileUrl ? 'Change Photo' : 'Upload Photo'}
                      </button>
                      {profile?.profileUrl && (
                        <button
                          className='remove-btn btn mx-2  my-1'
                          onClick={removePhoto}
                          type='button'
                        >
                          Remove Photo
                        </button>
                      )}
                      {fileName && <small>{fileName}</small>}
                    </div>

                    <div className='edit-title mx-2 mt-1'>
                      Maximum size of 1MB, JPG, GIF, or PNG.
                    </div>
                  </div>
                </div>

                <Row className='mb-4 mt-2 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Contact Name</label>
                    <input
                      placeholder='Enter your name'
                      className={`form-input w-100 '
                  }`}
                      type='text'
                      defaultValue={profile?.name}
                      {...register('name')}
                    />
                  </Col>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Email</label>
                    <input
                      placeholder='Enter your email'
                      className={`form-input w-100 '
                  }`}
                      type='text'
                      defaultValue={profile?.email}
                      {...register('email', { required: true })}
                    />
                  </Col>
                </Row>

                <Row className='mb-4 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Phone</label>
                    <Controller
                      name='phone'
                      control={control}
                      rules={{
                        validate: (value) =>
                          value ? isValidPhoneNumber(value) : true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          className={`form-input w-100 ${
                            errors['phone'] && 'invalid-input'
                          }`}
                          placeholder='(123) 123-1234'
                          value={value}
                          onChange={onChange}
                          defaultCountry='US'
                          id='phone'
                        />
                      )}
                    />
                  </Col>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Delivery Address</label>
                    <Controller
                      control={control}
                      name='address'
                      render={({ field: { onChange } }) => (
                        <ReactGoogleAutocomplete
                          placeholder='Enter Address'
                          className={`form-input w-100 ${
                            errors['post_code'] && 'invalid-input'
                          }`}
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          onPlaceSelected={(place) => {
                            onChange(place.formatted_address);
                          }}
                          defaultValue={profile.address}
                          options={{
                            types: ['geocode', 'establishment'],
                            componentRestrictions: { country: 'aus' },
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row className='mb-4 mt-2 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Business Name</label>
                    <input
                      placeholder='Enter your business name'
                      className={`form-input w-100 '
                  }`}
                      type='text'
                      defaultValue={profile?.business_name}
                      {...register('business_name')}
                    />
                  </Col>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>ABN</label>
                    <input
                      placeholder='Enter ABN '
                      className={`form-input w-100 '
                  }`}
                      type='number'
                      defaultValue={profile?.ABN}
                      {...register('ABN')}
                    />
                  </Col>
                </Row>

                <Row className='mb-4 mt-2 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Billing Address</label>
                    <input
                      placeholder='Enter your Billing Address'
                      className={`form-input w-100 '
                  }`}
                      type='text'
                      defaultValue={profile?.billing_address}
                      {...register('billing_address')}
                    />
                  </Col>
                </Row>
              </div>
            )}
            {id === '2' && (
              <div className='content-section'>
                <div className='edit-heading common-margin'>
                  <div className='edit-info'>Edit Bank Details</div>
                  <div className='edit-title'>Update your bank Information</div>
                </div>
                <hr />

                <Row className='mb-4 mt-2 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Account Name</label>
                    <input
                      placeholder='Enter your account name'
                      className={`form-input w-100 '
                  }`}
                      defaultValue={profile?.bank_account?.account_name}
                      type='text'
                      {...register('bank_account.account_name', {
                        required: true,
                      })}
                    />
                  </Col>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>BSB Number</label>
                    <input
                      placeholder='Enter your BSB number'
                      className={`form-input w-100 '
                  }`}
                      defaultValue={profile?.bank_account?.bsb_number}
                      type='text'
                      {...register('bank_account.bsb_number', {
                        required: true,
                      })}
                    />
                  </Col>
                </Row>

                <Row className='mb-4 mt-2 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Account Number</label>
                    <input
                      placeholder='Enter your account number'
                      className={`form-input w-100 '
                  }`}
                      defaultValue={profile?.bank_account?.account_number}
                      type='text'
                      {...register('bank_account.account_number', {
                        required: true,
                      })}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}

        {/* farmer portion */}
        {profile?.role === ROLES.farmer && (
          <>
            {id === '1' && (
              <div className='content-section'>
                <div className='edit-heading common-margin'>
                  <div className='edit-info'>Edit Profile</div>
                  <div className='edit-title'>Update your profile here</div>
                </div>
                <hr />
                <div className='border edit-flex-start image-section common-margin p-2'>
                  <div className='mx-4 my-1'>
                    {profile?.profileUrl ? (
                      <img
                        src={profile?.profileUrl}
                        alt=''
                        className='user-image'
                      />
                    ) : (
                      <img
                        src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                        alt=''
                        className='user-image'
                      />
                    )}
                  </div>
                  <div>
                    <div className='edit-flex-start'>
                      <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type='file'
                        onChange={handleFileChange}
                      />
                      <button
                        className='change-btn btn mx-2 my-1'
                        onClick={changePhoto}
                        type='button'
                      >
                        {profile?.profileUrl ? 'Change Photo' : 'Upload Photo'}
                      </button>
                      {profile?.profileUrl && (
                        <button
                          className='remove-btn btn mx-2  my-1'
                          onClick={removePhoto}
                          type='button'
                        >
                          Remove Photo
                        </button>
                      )}
                      {fileName && <small>{fileName}</small>}
                    </div>
                    <div className='edit-title mx-2 mt-1'>
                      Maximum size of 1MB, JPG, GIF, or PNG.
                    </div>
                  </div>{' '}
                </div>

                <Row className='mb-4 mt-2 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Contact Name</label>
                    <input
                      placeholder='Enter your name'
                      className={`form-input w-100 '
                  }`}
                      defaultValue={profile?.name}
                      type='text'
                      {...register('name')}
                    />
                  </Col>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Email</label>
                    <input
                      placeholder='Enter your email'
                      className={`form-input w-100 '
                  }`}
                      defaultValue={profile?.email}
                      type='text'
                      {...register('email', { required: true })}
                    />
                  </Col>
                </Row>

                <Row className='mb-4 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Phone</label>
                    <Controller
                      name='phone'
                      control={control}
                      rules={{
                        validate: (value) =>
                          value ? isValidPhoneNumber(value) : true,
                        required: true,
                      }}
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                          className={`form-input w-10t0 ${
                            errors['phone'] && 'invalid-input'
                          }`}
                          placeholder='(123) 123-1234'
                          value={profile?.phone}
                          onChange={onChange}
                          defaultCountry='US'
                          id='phone'
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
            )}
            {id === '2' && (
              <div className='content-section'>
                <div className='edit-heading common-margin'>
                  <div className='edit-info'>Edit Business Details</div>
                  <div className='edit-title'>Update your business details</div>
                </div>
                <hr />

                <Row className='mb-4 mt-2 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>ABN</label>
                    <input
                      placeholder='Enter ABN'
                      className={`form-input w-100 '
                  }`}
                      defaultValue={profile?.ABN}
                      type='number'
                      {...register('ABN')}
                    />
                  </Col>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Delivery Address</label>
                    <Controller
                      control={control}
                      name='address'
                      render={({ field: { onChange } }) => (
                        <ReactGoogleAutocomplete
                          placeholder='Enter Address'
                          className={`form-input w-100 ${
                            errors['post_code'] && 'invalid-input'
                          }`}
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          onPlaceSelected={(place) => {
                            onChange(place.formatted_address);
                          }}
                          defaultValue={profile.address}
                          options={{
                            types: ['geocode', 'establishment'],
                            componentRestrictions: { country: 'aus' },
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row className='mb-4 mt-2 common-margin'>
                  <Col sm='12' md='6'>
                    <label className='mb-2 d-block'>Business Name</label>
                    <input
                      placeholder='Enter your business name'
                      className={`form-input w-100 '
                  }`}
                      defaultValue={profile?.business_name}
                      type='text'
                      {...register('business_name')}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
        <div className='common-margin action-btns'>
          <button className='btn btn-cancel mx-3' onClick={handleCancel}>
            Cancel
          </button>
          <button className='btn btn-update mx-3' type='submit'>
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
