import React, { useState } from 'react';
import './TenderListingHeader.css';
import FilterModal from '../../modals/filter-modal/FilterModal';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IMAGES_ASSESTS_PATH, ROLES } from '../../../constants';

function TenderListingHeader({ count, getFilters }) {
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(false);

  const user = useSelector((state) => state.user);

  const handleFilters = (filter = filters) => {
    setShowModal(false);
    setFilters(filter);
    getFilters({ ...filter, search });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleFilters();
    }
  };

  return (
    <>
      <div className="container container-header">
        <Row>
          <Col md="12" lg="8">
            <div className="px-3 mt-4">
              <div className="d-flex justify-content-between align-items-center">
                {user?.user?.role === ROLES.farmer ? (
                  <h4>
                    Congratulations!
                    <h4 className="my-1">You are now part of iinputs.com</h4>
                  </h4>
                ) : (
                  <h4>{count} listing(s)</h4>
                )}
                <div className="md-show">
                  <img
                    src={`${IMAGES_ASSESTS_PATH}/LogoBg.png`}
                    alt="header"
                    className="header-image-small"
                  />
                </div>
              </div>
              <div className="pt-3 d-flex justify-content-between">
                <div className="input-group mb-3 search-actions">
                  <span className="input-group-text search-icon">
                    <img
                      className="xsmall-icon"
                      src={'/assets/icons/supplier/Search.svg'}
                      alt="search"
                    />
                  </span>
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleKeyDown}
                    type="text"
                    className="form-control search-input"
                    placeholder="Search title, postcode or ID"
                  />
                  <small
                    className="input-group-text px-4 py-2 search-btn d-none d-lg-flex d-md-flex d-sm-flex"
                    role="button"
                    onClick={() => handleFilters()}
                  >
                    Search
                  </small>
                </div>
                <div className="filter-actions">
                  <button
                    className="btn ms-3"
                    onClick={() => setShowModal(true)}
                  >
                    <img
                      className="xsmall-icon"
                      src={'/assets/icons/supplier/Filters.svg'}
                      alt="filters"
                    />
                    <small className="ms-2 d-none d-lg-inline-block d-md-inline-block">
                      Filters
                    </small>
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col md="12" lg="4">
            <div className="md-hide">
              <div className="d-flex justify-content-center px-3">
                <img
                  src={`${IMAGES_ASSESTS_PATH}/LogoBg.png`}
                  alt="header"
                  className={
                    user?.user?.role === ROLES.farmer
                      ? 'header-image'
                      : 'header-image-supplier'
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <FilterModal
        showModal={showModal}
        handleClose={() => setShowModal((prev) => !prev)}
        setFilters={handleFilters}
      />
    </>
  );
}

export default TenderListingHeader;
