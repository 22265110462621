import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import {
  IMAGES_ASSESTS_PATH,
  QUOTES_STATUSES,
  ROLES,
} from '../../../constants';
import { useNavigate } from 'react-router-dom';
import ViewAttachment from '../../common/view-attachment/ViewAttachment';
import { generateUniqueId } from '../../../services/utilService';
import AttachmentCard from '../../common/cards/AttachmentCard';

function TaxInvoice({ quote, user, onApprove }) {
  const navigate = useNavigate();
  const [attachmentModal, setAttachmentModal] = useState(false);

  return (
    <>
      <div
        className={`${
          quote?.status === QUOTES_STATUSES.accepted ||
          user.role === ROLES.farmer
            ? 'about-box h-100'
            : 'my-4 auth-box tender-detail-box'
        }`}
      >
        <h6 className='mb-0 bold-text'>Tax Invoice</h6>
        {quote?.invoice?.url ? (
          <>
            <div className='mt-3 d-flex justify-content-end block-small'>
              <div className='mt-3'>
                {user.role === ROLES.supplier && !quote.invoice.isApproved && (
                  <Button
                    className='warning-btn text-nowrap'
                    onClick={() =>
                      navigate(`/supplier/upload-invoice/${quote._id}`)
                    }
                  >
                    Re-submit Invoice
                  </Button>
                )}

                {(user.role === ROLES.farmer || user.role === ROLES.retailer) &&
                user?._id !== quote?.user_id._id &&
                !quote.invoice.isApproved ? (
                  <Button onClick={onApprove} className='primary-btn'>
                    Approve
                  </Button>
                ) : (
                  quote.invoice.isApproved && (
                    <Button
                      role='none'
                      className='ms-2 mb-2 border-green-btn no-hover-btn px-3 text-nowrap'
                    >
                      <img
                        src={`${IMAGES_ASSESTS_PATH}/Tick.svg`}
                        alt='done'
                        className='small-icon me-3'
                      />
                      <span className='primary-color'>Approved</span>
                    </Button>
                  )
                )}
              </div>
            </div>
            <Row>
              {quote?.invoice?.list?.map((item) => (
                <AttachmentCard
                  key={generateUniqueId()}
                  attachment={{ url: item }}
                  setSpinner={() => {}}
                />
              ))}
            </Row>
          </>
        ) : (
          <>
            <div className='d-flex justify-content-center'>
              <img
                src='/assets/images/Invoice-Pending.svg'
                alt='Invoice-Pending'
                className='invoice-pending mt-3'
              />
            </div>
            <div className='text-center'>
              <div className='my-4'>No invoice uploaded yet.</div>
              {user?._id !== quote?.user_id._id ? (
                <>
                  <div className='mb-3'>
                    Invoice will be received shortly. You will receive a
                    notification once it arrives.
                  </div>
                  <div className='mb-3'>Thank you for the patience</div>
                </>
              ) : (
                <>
                  <div className='mb-3'>
                    You must provide a tax invoice to the farmer if your quote
                    is successful.
                  </div>
                  <div className='mb-3'>
                    Farmer will not release funds until tax invoice is received.
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <ViewAttachment
        show={attachmentModal}
        handleClose={() => setAttachmentModal(false)}
        file={quote?.invoice}
      />
    </>
  );
}

export default TaxInvoice;
