import { createSlice } from "@reduxjs/toolkit";
import { userState } from "../states/userStates";
import { userReducer } from "../reducers/userReducer";

const userSlice = createSlice({
  name: "user",
  initialState: userState,
  reducers: userReducer,
});

export const userActions = userSlice.actions;
export default userSlice;
