import React, { useEffect, useState } from 'react';
import './MyQuotes.css';
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../../store/slices/quoteSlice';
import SpinnerLoader from '../spinner/Spinner';
import { toast } from 'react-hot-toast';
import {
  IMAGES_ASSESTS_PATH,
  MY_QUOTES_TABLE_COLS,
  TABLE_COL_CLASSES,
  TENDER_DETAIL_TABS,
  TENDER_STATUSES,
  defaultPageMeta,
  errorToast,
  generateTableHeader,
} from '../../../constants';
import Pages from '../pagination/Pagination';
import {
  generateUniqueId,
  getStatusClass,
  getTenderID,
  numberToCurrency,
} from '../../../services/utilService';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { tenderActions } from '../../../store/slices/tenderSlice';
import DropDownIcon from '../../../assets/icons/arrow-down-up.svg';

function MyQuotes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quote = useSelector((state) => state.quote);
  const user = useSelector((state) => state.user);
  const [showSpinner, setSpinner] = useState(true);
  const [quotes, setQuotes] = useState([]);
  const [sort, setSort] = useState('');
  const [meta, setMeta] = useState(defaultPageMeta);

  useEffect(() => {
    dispatch(
      quoteActions.myQuotesRequest({
        size: defaultPageMeta.per_page,
        pageNo: defaultPageMeta.current_page,
        sort,
      })
    );
  }, [dispatch, sort]);

  useEffect(() => {
    setSpinner(quote.loading);
    if (quote.error) {
      toast.error(quote.error, errorToast);
    }
    if (quote.response) {
      setQuotes(quote.response.data || []);
      setMeta(quote.response.meta);
      setSpinner(false);
    }
    return () => {
      dispatch(quoteActions.clearErrors());
    };
  }, [dispatch, quote.error, quote.loading, quote.response]);

  const pageClick = (pageNo) => {
    setMeta((prev) => {
      return { ...prev, current_page: pageNo };
    });
    dispatch(quoteActions.myQuotesRequest({ size: meta.per_page, pageNo }));
  };

  const pageSize = (size) => {
    setMeta((prev) => {
      return { ...prev, per_page: size };
    });
    dispatch(quoteActions.myQuotesRequest({ size, pageNo: 1 }));
  };

  const handleClick = (quote) => {
    dispatch(tenderActions.persistTender(quote.tender_id));
    navigate(
      `/${user?.user?.role}/tender-detail/${quote.tender_id._id}/${TENDER_DETAIL_TABS.quoteDetail}`
    );
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container className='mt-5'>
        <div className='container d-flex justify-content-between align-items-center'>
          <h5>My Quotes</h5>
          <div className='dropdown'>
            <Dropdown>
              <Dropdown.Toggle
                variant='success'
                id='dropdown-basic'
                className='btn btn-light sort-by-btn'
              >
                <img
                  src={DropDownIcon}
                  alt='sort'
                  className='icon-small me-2'
                />
                <small>Sort by</small>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSort('completion_date')}>
                  Supply Date
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSort('price')}>
                  Amount
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className='md-hide table-header secondary-text-color mt-5'>
          {generateTableHeader(MY_QUOTES_TABLE_COLS)}
        </div>
        {quotes && quotes?.length ? (
          <>
            {quotes.map((quote) => (
              <div className='table-row my-3' key={generateUniqueId()}>
                <div className='md-hide'>
                  <Row>
                    <Col lg='1'>
                      <div className={TABLE_COL_CLASSES}>
                        {'#' + getTenderID(quote?.tender_id?._id)}
                      </div>
                    </Col>
                    <Col lg='3'>
                      <div className={TABLE_COL_CLASSES}>
                        {quote?.tender_id?.title}
                      </div>
                    </Col>
                    <Col lg='2'>
                      <div className={TABLE_COL_CLASSES}>
                        <img
                          className='small-icon'
                          src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                          alt='img'
                        />
                      </div>
                    </Col>
                    <Col lg='2'>
                      <div className={TABLE_COL_CLASSES}>
                        {moment(quote?.tender_id?.completion_date).format(
                          'ddd, MMM DD'
                        )}
                      </div>
                    </Col>
                    <Col lg='1'>
                      <div className={TABLE_COL_CLASSES}>
                        {numberToCurrency(quote.price)}
                      </div>
                    </Col>
                    <Col lg='1'>
                      <div className={TABLE_COL_CLASSES}>
                        <button
                          className={`px-3 status-medium bold-text ${getStatusClass(
                            quote.tender_id?.status === TENDER_STATUSES.active
                              ? TENDER_STATUSES.pending
                              : quote?.status
                          )}`}
                        >
                          {quote.tender_id?.status === TENDER_STATUSES.active
                            ? TENDER_STATUSES.pending
                            : quote?.status}
                        </button>
                      </div>
                    </Col>
                    <Col lg='2'>
                      <div className='w-100 d-flex justify-content-end'>
                        <Button
                          className='primary-btn px-2 py-1'
                          onClick={() => handleClick(quote)}
                          type='button'
                        >
                          <div className='d-flex align-items-center'>
                            View Details
                            <img
                              className='ms-1'
                              src={'/assets/icons/supplier/Forward.svg'}
                              alt='arrow'
                            />
                          </div>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='md-show'>
                  <Row>
                    <Col sm='12'>
                      {'#' + getTenderID(quote?.tender_id?._id) + ' - '}
                      <strong>{quote?.tender_id?.title}</strong>
                    </Col>
                    <Col lg='12'>
                      <div className='mt-3 w-100 d-flex justify-content-between'>
                        <img
                          className='small-icon'
                          src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                          alt='img'
                        />
                        <button
                          className={`px-3 status-small ${getStatusClass(
                            quote.tender_id?.status === TENDER_STATUSES.active
                              ? TENDER_STATUSES.pending
                              : quote?.status
                          )}`}
                        >
                          {quote.tender_id?.status === TENDER_STATUSES.active
                            ? TENDER_STATUSES.pending
                            : quote?.status}
                        </button>
                      </div>
                    </Col>
                    <Col lg='12'>
                      <div className='mt-4 w-100 d-flex justify-content-between'>
                        <div>
                          {moment(quote?.tender_id?.completion_date).format(
                            'ddd, MMM DD'
                          )}
                          <br />
                          <small className='secondary-text-color'>
                            Date of Supply
                          </small>
                        </div>
                        <div>
                          {numberToCurrency(quote.price)}
                          <br />
                          <small className='secondary-text-color'>Paid</small>
                        </div>
                        <div>
                          {numberToCurrency(
                            parseFloat(quote.price) -
                              parseFloat(quote.price) * 0.3
                          )}
                          <br />
                          <small className='secondary-text-color'>
                            Received
                          </small>
                        </div>
                      </div>
                    </Col>
                    <Col lg='12'>
                      <div className='mt-4 w-100 d-flex'>
                        <Button
                          className='w-100 primary-btn px-2 py-1'
                          onClick={() => handleClick(quote)}
                          type='button'
                        >
                          <div className='d-flex align-items-center justify-content-center'>
                            View Details
                            <img
                              className='ms-1'
                              src={'/assets/icons/supplier/Forward.svg'}
                              alt='arrow'
                            />
                          </div>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
            <Pages
              meta={meta}
              pageClick={pageClick}
              pageSize={pageSize}
              defaultPage={30}
            />
          </>
        ) : (
          <div className='p-5'>No Records Found</div>
        )}
      </Container>
    </>
  );
}

export default MyQuotes;
