import { getErrorMessageFromAxios } from '../../services/utilService';

export const notificationReducer = {
  myNotificationsRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  requestSuccess: (state, action) => {
    state.loading = false;
    state.error = null;
    state.response = action.payload;
  },
  requestFailure: (state, action) => {
    state.loading = false;
    state.error = getErrorMessageFromAxios(action.payload);
  },
  clearErrors: (state, action) => {
    state.error = null;
    state.response = null;
    state.loading = false;
  },
};
