import React from 'react';
import './CreateQuote.css';
import { Container } from 'react-bootstrap';
import TenderDetail from '../../common/tender-detail/TenderDetail';
import QuoteForm from './QuoteForm';
import { useParams } from 'react-router-dom';

const CreateQuote = ({ isUploaded }) => {
  const { id } = useParams();

  return (
    <>
      <TenderDetail showFeeInvoice={true} hideTabs={true} />
      <Container fluid>
        <div className='auth-box tender-detail-box mt-5'>
          <QuoteForm id={id} isUploaded={isUploaded} />
        </div>
      </Container>
    </>
  );
};

export default CreateQuote;
