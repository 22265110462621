import { current } from '@reduxjs/toolkit';
import { getErrorMessageFromAxios } from '../../services/utilService';

export const settingReducer = {
  updateSetting: (state, action) => {
    state.loading = false;
    state.error = null;
    const previousState = current(state);
    state.setting = { ...previousState?.setting, ...action.payload };
  },
  getSettings: (state, action) => {
    state.loading = false;
    state.error = null;
    const previousState = current(state);
    state.setting = { ...previousState?.setting, ...action.payload };
  },
  requestSuccess: (state, action) => {
    state.loading = false;
    state.error = null;
    const previousState = current(state);
    state.setting = { ...previousState?.setting, ...action.payload };
  },
  requestFailure: (state, action) => {
    state.loading = false;
    state.error = getErrorMessageFromAxios(action.payload);
  },
  clearRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  clearSettings: (state, action) => {
    state.loading = false;
    state.setting = null;
    state.error = false;
  },
};
