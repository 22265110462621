import { createSlice } from '@reduxjs/toolkit';
import { tenderReducer } from '../reducers/tenderReducer';
import { tenderState } from '../states/tenderStates';

const tenderSlice = createSlice({
  name: 'tender',
  initialState: tenderState,
  reducers: tenderReducer,
});

export const tenderActions = tenderSlice.actions;
export default tenderSlice;
