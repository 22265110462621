import React, { useEffect, useState } from 'react';
import './QuotesListing.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  generateUniqueId,
  getTimeDiff,
  numberToCurrency,
} from '../../../services/utilService';
import {
  FARMER_QUOTES_TABLE_COLS,
  IMAGES_ASSESTS_PATH,
  TABLE_COL_CLASSES,
  defaultPageMeta,
  errorToast,
  generateTableHeader,
} from '../../../constants';
import Pages from '../../common/pagination/Pagination';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../../store/slices/quoteSlice';
import { toast } from 'react-hot-toast';
import { tenderActions } from '../../../store/slices/tenderSlice';
import SpinnerLoader from '../../common/spinner/Spinner';
import { Rating } from 'react-simple-star-rating';

function QuotesListing({ tender_id }) {
  const quote = useSelector((state) => state.quote);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [meta, setMeta] = useState(defaultPageMeta);
  const [quotes, setQuotes] = useState([]);
  const [showSpinner, setSpinner] = useState(true);

  useEffect(() => {
    dispatch(
      quoteActions.myQuotesRequest({
        tender_id,
        size: defaultPageMeta.per_page,
        pageNo: defaultPageMeta.current_page,
      })
    );
  }, [dispatch, tender_id]);

  useEffect(() => {
    if (quote.error) {
      toast.error(quote.error, errorToast);
      setSpinner(false);
    }
    if (quote.response) {
      setQuotes(quote.response.data || []);
      setMeta(quote.response.meta);
      setSpinner(false);
    }
    return () => {
      dispatch(quoteActions.clearErrors());
    };
  }, [dispatch, quote.error, quote.loading, quote.response, setSpinner]);

  const pageClick = (pageNo) => {
    setMeta((prev) => {
      return { ...prev, current_page: pageNo };
    });
    dispatch(
      quoteActions.myQuotesRequest({
        tender_id,
        size: meta.per_page,
        pageNo,
      })
    );
  };

  const pageSize = (size) => {
    setMeta((prev) => {
      return { ...prev, per_page: size };
    });
    dispatch(
      quoteActions.myQuotesRequest({
        tender_id,
        size,
        pageNo: 1,
      })
    );
  };

  const handleClick = (quote) => {
    dispatch(quoteActions.persistQuote(quote));
    dispatch(tenderActions.persistTender(quote.tender_id));
    navigate(`/${user?.user?.role}/quote-detail`);
  };

  return (
    <Container fluid>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='auth-box tender-detail-box p-0'>
        <div className='p-4 border-bottom'>
          <h6 className='mb-0 bold-text'>Quotes by Suppliers</h6>
        </div>
        <div className='p-3'>
          <div className='md-hide secondary-text-color mt-3'>
            {generateTableHeader(FARMER_QUOTES_TABLE_COLS)}
          </div>
          {quotes && quotes.length && !showSpinner ? (
            <>
              {quotes.map((quote, index) => (
                <div
                  className={`table-row my-3 ${
                    index % 2 === 0 ? 'row-color-1' : 'row-color-2'
                  }`}
                  key={generateUniqueId()}
                >
                  <div className='md-hide'>
                    <Row>
                      <Col lg='2'>
                        <div className='h-100 d-flex align-items-center'>
                          <img
                            className='small-icon me-2'
                            src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                            alt='img'
                          />
                          <div className='cell-text'>
                            {quote?.user_id?.name}
                          </div>
                        </div>
                      </Col>
                      <Col lg='2'>
                        <div className='h-100 d-flex align-items-center'>
                          <Rating
                            size={25}
                            initialValue={quote?.user_id?.rating?.totalRating}
                            fillColor='green'
                            readonly
                          />
                        </div>
                      </Col>
                      <Col lg='3'>
                        <div className={TABLE_COL_CLASSES}>
                          <div className='cell-text'>
                            {quote?.completion_date
                              ? moment(quote?.completion_date).format(
                                  'ddd, MMM DD'
                                )
                              : 'N/A'}
                          </div>
                        </div>
                      </Col>
                      <Col lg='3'>
                        <div className={TABLE_COL_CLASSES}>
                          <div className='cell-text'>
                            {numberToCurrency(quote?.price)}
                          </div>
                        </div>
                      </Col>
                      <Col lg='2'>
                        <div className='w-100 d-flex justify-content-end'>
                          <Button
                            className='primary-btn px-2 py-1'
                            onClick={() => handleClick(quote)}
                            type='button'
                          >
                            <div className='d-flex align-items-center'>
                              Quote Details
                              <img
                                className='ms-1'
                                src={'/assets/icons/supplier/Forward.svg'}
                                alt='arrow'
                              />
                            </div>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className='md-show'>
                    <Row>
                      <Col sm='12'>
                        Applied {getTimeDiff(quote?.createdAt)} ago
                      </Col>
                      <Col lg='12'>
                        <div className='mt-3 h-100 d-flex align-items-center'>
                          <img
                            className='small-icon me-2'
                            src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                            alt='img'
                          />
                          <div>{quote?.user_id?.name}</div>
                        </div>
                      </Col>
                      <Col lg='12'>
                        <div className='mt-4 w-100 d-flex justify-content-between'>
                          <div>
                            {moment(quote?.completion_date).format(
                              'ddd, MMM DD'
                            )}
                            <br />
                            <small className='secondary-text-color'>
                              Date of Supply
                            </small>
                          </div>
                          <div>
                            {numberToCurrency(quote?.price)}
                            <br />
                            <small className='secondary-text-color'>
                              Price
                            </small>
                          </div>
                        </div>
                      </Col>
                      <Col lg='12'>
                        <div className='mt-4 w-100 d-flex'>
                          <Button
                            className='w-100 primary-btn px-2 py-1'
                            onClick={() => handleClick(quote)}
                            type='button'
                          >
                            <div className='d-flex align-items-center justify-content-center'>
                              View Details
                              <img
                                className='ms-1'
                                src={'/assets/icons/supplier/Forward.svg'}
                                alt='arrow'
                              />
                            </div>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className='p-5'>No Records Found</div>
          )}
        </div>
      </div>
      {quotes && quotes.length ? (
        <Pages
          meta={meta}
          pageClick={pageClick}
          pageSize={pageSize}
          defaultPage={30}
        />
      ) : (
        <></>
      )}
    </Container>
  );
}

export default QuotesListing;
