import { current } from '@reduxjs/toolkit';
import { getErrorMessageFromAxios } from '../../services/utilService';

export const userReducer = {
  loginRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  signupRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  forgotPasswordRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  logout: (state, action) => {
    state.loading = false;
    state.error = null;
    state.user = null;
  },
  validateResetLink: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  resetPasswordRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  getUserProfile: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  updateProfile: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  requestSuccess: (state, action) => {
    state.loading = false;
    state.error = null;
    const previousState = current(state);
    state.user = { ...previousState?.user, ...action.payload };
    if (state.user?.error) {
      state.user.error = null;
    }
  },
  requestFailure: (state, action) => {
    state.loading = false;
    state.error = getErrorMessageFromAxios(action.payload);
  },
  clearErrors: (state, action) => {
    state.error = null;
    state.response = null;
    state.loading = false;
    if (state.user?.message) {
      state.user.message = null;
    }
  },
  clearUser: (state, action) => {
    state.error = null;
    state.response = null;
    state.loading = false;
    state.user = null;
  },
  updateUser: (state, action) => {
    const previousState = current(state);
    state.user = { ...action.payload, ...previousState?.user };
  },
  updatePassword: (state, action) => {
    state.error = null;
    state.loading = false;
    const previousState = current(state);
    state.user = { ...previousState?.user, ...action.payload };
    if (state.user?.error) {
      state.user.error = null;
    }
  },
  contactUs: (state, action) => {
    state.error = null;
    state.loading = false;
  },
  updateTokens: (state, action) => {
    state.loading = true;
    state.error = null;
  },
  clearUserRequest: (state, action) => {
    state.loading = true;
    state.error = null;
  },
};
