import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import userSaga from './sagas/userSaga';
import awsSaga from './sagas/awsSage';
import tenderSaga from './sagas/tenderSaga';
import { reducers } from './slices';
import quoteSaga from './sagas/quoteSaga';
import messagesSaga from './sagas/messagesSage';
import notificationSaga from './sagas/notificationSaga';
import settingSaga from './sagas/settingSagas';

const persistConfig = {
  key: 'root',
  storage,
};

const reducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, reducer);

const saga = createSagaMiddleware();
const store = configureStore({
  reducer: persistedReducer,
  middleware: [saga],
});

saga.run(userSaga);
saga.run(awsSaga);
saga.run(tenderSaga);
saga.run(quoteSaga);
saga.run(messagesSaga);
saga.run(notificationSaga);
saga.run(settingSaga);

export default store;
