import React, { useEffect, useState } from 'react';
import './MyDisputes.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  IMAGES_ASSESTS_PATH,
  ROLES,
  TENDER_DETAIL_TABS,
  TENDER_STATUSES,
  defaultPageMeta,
  errorToast,
} from '../../../constants';
import {
  generateUniqueId,
  getStatusClass,
  getTenderID,
  getTimeDiff,
  numberToCurrency,
} from '../../../services/utilService';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { tenderActions } from '../../../store/slices/tenderSlice';
import SpinnerLoader from '../spinner/Spinner';
import { toast } from 'react-hot-toast';
import Pages from '../pagination/Pagination';
import moment from 'moment';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

function MyDisputes() {
  const { width } = useWindowDimensions();
  const MOBILE_BREAKPOINT = 767;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const tender = useSelector((state) => state.tender);
  const [showSpinner, setSpinner] = useState(true);
  const [disputes, setDisputes] = useState([]);
  const [meta, setMeta] = useState(defaultPageMeta);

  useEffect(() => {
    dispatch(
      tenderActions.getDisputesRequest({
        size: defaultPageMeta.per_page,
        pageNo: defaultPageMeta.current_page,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setSpinner(tender.loading);
    if (tender.error) {
      toast.error(tender.error, errorToast);
    }
    if (tender.response) {
      setDisputes(tender.response.data || []);
      setMeta(tender.response.meta);
      setSpinner(false);
    }
    return () => {
      dispatch(tenderActions.clearErrors());
    };
  }, [dispatch, tender.error, tender.loading, tender.response]);

  const pageClick = (pageNo) => {
    setMeta((prev) => {
      return { ...prev, current_page: pageNo };
    });
    dispatch(tenderActions.getDisputesRequest({ size: meta.per_page, pageNo }));
  };

  const pageSize = (size) => {
    setMeta((prev) => {
      return { ...prev, per_page: size };
    });
    dispatch(tenderActions.getDisputesRequest({ size, pageNo: 1 }));
  };

  const handleClick = (tender) => {
    dispatch(tenderActions.persistTender(tender));
    navigate(
      `/${user.role}/tender-detail/${tender?._id}/${TENDER_DETAIL_TABS.tenderDetail}`
    );
  };

  const getOneDispute = (tender) => {
    return (
      <div className="dispute-box p-4">
        <Row>
          <Col lg="6" md="12">
            <div>{getTimeDiff(tender?.disputes?.startDate) + ' ago'}</div>
            <h4 className="mt-4">
              <span className="secondary-text-color">
                #{getTenderID(tender?._id)}
              </span>
              <span className="px-2">-</span>
              <span>{tender?.title}</span>
            </h4>
            <div className="mt-4 mb-3 d-flex align-items-center">
              <img
                src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                className={`${
                  width <= MOBILE_BREAKPOINT ? 'small-icon' : 'medium-icon'
                } me-3`}
                alt="profile"
              />
              <div>
                <h6 className="bold-text mb-0">
                  {user?.role === ROLES.farmer
                    ? tender?.supplierName
                    : tender?.farmerName}
                </h6>
              </div>
            </div>
          </Col>
          <Col lg="6" md="12">
            <div className="w-100 h-100 d-flex align-items-center justify-content-between">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="mb-3">
                  <span className="secondary-text-color me-2">
                    {tender?.transport} Location:
                  </span>
                  <span className="bold-text">{tender?.post_code}</span>
                </div>
                <div className="mb-3">
                  <span className="secondary-text-color me-2">
                    Date of Supply:
                  </span>
                  <span className="bold-text">
                    {moment(tender?.completion_date).format('DD-MM-YYYY')}
                  </span>
                </div>
                <div className="mb-3">
                  <span className="secondary-text-color me-2">
                    Dispute Duration:
                  </span>
                  <span className="bold-text">
                    {moment(tender?.disputes.startDate).diff(
                      tender?.disputes.endDate,
                      'days'
                    ) +
                      1 +
                      ' days'}
                  </span>
                </div>
                <div>
                  <span className="secondary-text-color me-2">Price:</span>
                  <span className="bold-text">
                    {numberToCurrency(tender?.price)}
                  </span>
                </div>
              </div>
              <div className="h-100 d-flex flex-column justify-content-between">
                <button
                  className={`p-2 status-medium bold-text ${getStatusClass(
                    !tender?.disputes?.isClosed
                      ? TENDER_STATUSES.dispute
                      : 'Resolved'
                  )}`}
                >
                  {!tender?.disputes?.isClosed
                    ? TENDER_STATUSES.dispute
                    : 'Resolved'}
                </button>
                <Button
                  onClick={() => handleClick(tender)}
                  className="primary-btn px-2 py-1"
                  type="button"
                >
                  <div className="d-flex align-items-center">
                    View Details
                    <img
                      className="ms-1"
                      src={'/assets/icons/supplier/Forward.svg'}
                      alt="arrow"
                    />
                  </div>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="mt-5">
      <SpinnerLoader showSpinner={showSpinner} />
      <Container>
        <h3>My Disputes</h3>
        <div className="mt-5">
          {disputes && disputes.length ? (
            <>
              {disputes.map((dispute) => (
                <div key={generateUniqueId()} className="mb-3">
                  {getOneDispute(dispute)}
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
        <Pages
          meta={meta}
          pageClick={pageClick}
          pageSize={pageSize}
          defaultPage={30}
        />
      </Container>
    </div>
  );
}

export default MyDisputes;
