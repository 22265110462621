import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROLES, errorToast } from '../../constants';
import { toast } from 'react-hot-toast';
import { isTokenExpired } from '../../services/utilService';

const RetailerGuard = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);

  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (
      user?.user?.access_token &&
      user?.user?.role === ROLES.retailer &&
      !isTokenExpired()
    ) {
      setAuthenticated(true);
    } else if (
      user?.user?.role === ROLES.supplier ||
      user?.user?.role === ROLES.farmer
    ) {
      toast.error('You can not access retailer dashboard!', errorToast);
      user?.user?.role === ROLES.supplier
        ? navigate(`/${user?.user?.role}/tender-listing`)
        : navigate(`/${user?.user?.role}/tender-listing/Active`);
    } else {
      toast.error('You need to Login first', errorToast);
      navigate('/login');
    }
  }, [user.user?.access_token, user.user?.role, navigate]);

  return isAuthenticated && <Outlet />;
};

export default RetailerGuard;
