import React from 'react';
import {
  generateUniqueId,
  numberToCurrency,
  tenderInfoDiv,
} from '../../../services/utilService';
import moment from 'moment';
import AttachmentCard from '../cards/AttachmentCard';
import { Row } from 'react-bootstrap';
import './SupplierOffer.css';
import { ROLES, TRANSPORT } from '../../../constants';
import expiryIcon from '../../../assets/icons/Quote Expiry.svg';
import priceIcon from '../../../assets/icons/Price Tag.svg';
import deliveryInfo from '../../../assets/icons/delivery info.svg';
import { useSelector } from 'react-redux';

function SupplierOffer({ quote, checkDisabled, setModal, setSpinner }) {
  const user = useSelector((state) => state.user.user);
  return (
    <>
      <div className='mt-4 auth-box tender-detail-box left-green-border'>
        <div className='d-flex align-items-center justify-content-between'>
          <h5 className='bold-text'>Supplier Offer</h5>
          {checkDisabled && setModal && (
            <div className='actions'>
              <button
                onClick={() => !checkDisabled() && setModal(true)}
                className={`primary-btn ${checkDisabled() && 'disabled-btn'}`}
                disabled={checkDisabled()}
              >
                Accept Quote
              </button>
            </div>
          )}
        </div>
        <div className='mt-4'>
          {user?.role !== ROLES.retailer &&
            tenderInfoDiv(
              '/assets/icons/supplier/Delivery.svg',
              quote?.transport === TRANSPORT.delivery
                ? 'Transport Option'
                : `${quote?.transport} Location:`,
              quote?.transport === TRANSPORT.delivery
                ? 'Deliver to Farm'
                : quote?.post_code
            )}

          {user?.role !== ROLES.retailer &&
            tenderInfoDiv(
              deliveryInfo,
              `${quote?.transport} Date:`,
              moment(quote?.completion_date).format('DD-MM-YYYY')
            )}
          {tenderInfoDiv(
            expiryIcon,
            'Quote Expiration Date:',
            quote?.quote_validity
              ? moment(quote?.quote_validity).format('DD-MM-YYYY')
              : 'N/A'
          )}
          {tenderInfoDiv(
            priceIcon,
            'Total Price (Includes GST):',
            numberToCurrency(quote?.price)
          )}
        </div>

        {user?.role === ROLES.retailer && (
          <div className='mt-5'>
            <h5 className='secondary-text-color pre-wrap'>Transport</h5>
            <p>{quote?.quote_shipping || 'N/A'}</p>
          </div>
        )}

        <div className='mt-5'>
          <h5 className='secondary-text-color pre-wrap'>Payment Terms</h5>
          <p>{quote?.payment_terms || 'N/A'}</p>
        </div>

        <div className='mt-5'>
          <h5 className='secondary-text-color pre-wrap'>
            Order/Product Details
          </h5>
          <p>{quote?.details}</p>
        </div>

        {quote?.attachments?.length ? (
          <div className='mt-5'>
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <h5 className='secondary-text-color'>Attachments</h5>
            </div>

            <Row>
              {quote?.attachments.map((attachment) => (
                <AttachmentCard
                  key={generateUniqueId()}
                  attachment={attachment}
                  setSpinner={setSpinner}
                />
              ))}
            </Row>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default SupplierOffer;
